<template>
  <div id="fonctionnement">
    <div class="header">
      <h1><span>Notre </span> fonctionnement</h1>
    </div>
    <div class="intro grid-noGutter mb100">
      <div class="col-5_md-12">
        <div class="txt1">
          <h2 class="orange">L'équipe de la Fondation</h2>
          <h3>La Fondation d’entreprise Up est pilotée au quotidien par une équipe opérationnelle</h3>
        </div>
      </div>
      <div class="col-1_md-12"></div>
      <div class="col-6_md-12">
        <p>L'équipe de la fondation est en charge : <br/>de l’administration, de la coordination des actions, de
          l’animation d’une communauté mécénat internationale, du suivi budgétaire, de l’évaluation des projets, de la
          mise en œuvre des partenariats et de la communication de la Fondation. <br/>Il s’agit de salariés d’Up mis à
          disposition gratuitement auprès de la Fondation.</p>
      </div>
    </div>

    <div id="team" style="max-width:1200px;margin:0 auto;">
      <div class="members grid-center-noBottom-noGutter">
        <div class="col-4__lg-6_sm-12 item" v-for="(item, index) in equipe" :key="index"
             :class="{ on : current_member === index, even : index%2 == 0, odd : index%2 != 0 }">
          <div class="card">
            <div class="more" v-on:click.prevent="setMember(index)">
              <svg>
                <use xlink:href="#plus"></use>
              </svg>
            </div>
            <div class="photo cover" :style="{ backgroundImage: 'url(' + $root.publicPath + item.photo + ')' }"
                 v-on:click.prevent="setMember(false)"></div>
            <p><strong>{{ item.nom }}</strong>{{ item.fonction }}</p>
          </div>

          <div class="desc">
            <a href="#" class="close" v-on:click.prevent="setMember(false)">
              <svg>
                <use xlink:href="#close"></use>
              </svg>
            </a>
            <div class="title">
              <h3><strong class="orange">{{ item.nom }}</strong><br/><span>{{ item.fonction }}</span></h3>
            </div>
            <div class="txt">
              <p v-html="item.txt"></p>
              <a :href="item.linkedin" class="linkedin" v-on:click.prevent="openBlank(item.linkedin)">
                <img :src="$root.publicPath + 'img/picto/linkedin.png'"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="communaute" class="mb100">
      <h2 class="orange">La communauté <br/>mécénat Up</h2>
      <div class="grid-noBottom-noGutter">
        <div class="col-6_md-12">
          <div class="txt1">
            <h3>Au-delà de l’équipe de la Fondation, il existe au sein du groupe Up une communauté de référents mécénat,
              qui sont les acteurs locaux du mécénat dans les filiales et pays où le groupe Up est implanté.</h3>
            <p>Véritables ambassadeurs de la politique de mécénat du groupe, ils exercent cette mission sur leur temps
              de travail, en complément de leur emploi qualifié, et en relation avec la Fondation.</p>
          </div>
        </div>
        <div class="col-6_md-12">
          <div class="wrapsquares1">
            <div class="squares grid-noBottom-noGutter">
              <div class="col-4" v-for="(item, index) in communaute" :key="item" v-if="index < 9">
                <div class="square">
                  <div class="photo cover one" :class="{ on : communaute_step == 1 }"
                       :style="{ backgroundImage: 'url(upload/member/' + communaute[index] + '.jpg)', 'transitionDelay' : (index%4==0) ? '.5s' : ( (index%2==0) ? '0s' : '.3s' ) }">
                    <p style="display:inline-block;font-size:12px;background:#fff;display:none;">ONE =>
                      {{ communaute[index] }} <br/>TWO => {{ communaute_new[index] }}</p></div>
                  <div class="photo cover two" :class="{ on : communaute_step == 2 }"
                       :style="{ backgroundImage: 'url(upload/member/' + communaute_new[index] + '.jpg)', 'transitionDelay' : (index%4==0) ? '.5s' : ( (index%2==0) ? '0s' : '.3s' ) }">
                    <p style="display:inline-block;font-size:12px;font-weight:800;background:#fff;display:none;">ONE =>
                      {{ communaute[index] }} <br/>TWO => {{ communaute_new[index] }}</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-noBottom-noGutter-reverse">
        <div class="col-6_md-12">
          <div class="txt2">
            <p>Ils sélectionnent des projets, coordonnent la relation avec les structures d’intérêt général, animent le
              mécénat et travaillent étroitement avec la Fondation Up concernant le suivi et l’évaluation des
              projets.</p>
          </div>
        </div>
        <div class="col-6_md-12">
          <div class="wrapsquares2">
            <div class="squares grid-noBottom-noGutter">
              <div class="col-4" v-for="(item, index) in communaute" :key="item" v-if="index > 8 && index < 12">
                <div class="square">
                  <div class="photo cover one" :class="{ on : communaute_step == 1 }"
                       :style="{ backgroundImage: 'url(upload/member/' + communaute[index] + '.jpg)', 'transitionDelay' : (index%3==0) ? '.5s' : ( (index%2==0) ? '0s' : '.3s' ) }">
                    <p style="display:inline-block;font-size:12px;background:#fff;display:none;">ONE =>
                      {{ communaute[index] }} <br/>TWO => {{ communaute_new[index] }}</p></div>
                  <div class="photo cover two" :class="{ on : communaute_step == 2 }"
                       :style="{ backgroundImage: 'url(upload/member/' + communaute_new[index] + '.jpg)', 'transitionDelay' : (index%3==0) ? '.5s' : ( (index%2==0) ? '0s' : '.3s' ) }">
                    <p style="display:inline-block;font-size:12px;font-weight:800;background:#fff;display:none;">ONE =>
                      {{ communaute[index] }} <br/>TWO => {{ communaute_new[index] }}</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="comite" class="mb100">
      <h2 class="orange">Le Comité de sélection</h2>
      <div class="grid-noGutter-noBottom-spaceBetween">
        <div class="col-4_md-12">
          <h3>Le Comité de sélection est l'instance qui se réunit, dans chaque filiale et la Fondation, pour
            sélectionner les projets mécénat.</h3>
        </div>
        <div class="col-7_md-12">
          <p>Dans chaque pays et au sein de la Fondation, un comité de sélection s’organise pour instruire les dossiers
            et se réunir au moins 2 fois / an. Il décide des projets qui sont éligibles à un soutien financier sur la
            durée, après une sélection rigoureuse, menée sur la base d’une grille de sélection composée de critères de
            mécénat propres au groupe Up.<br/>
            Présidé par un Directeur Général de filiale ou le Délégué Général pour la Fondation, il est composé du
            référent mécénat, de collaborateurs spécialistes ou sensibles aux domaines de mécénat investis. <br/>
            Il peut également introduire d’autres parties prenantes économiques, sociales et sociétales présentes sur le
            territoire.</p>
        </div>
      </div>
    </div>

    <help-depot></help-depot>
  </div>
</template>


<script>
// import EquipeList from '../json/equipe.json'

export default {
  name: 'fonctionnement',

  data() {
    return {
      equipe: {},
      current_member: false,
      communaute_interval: false,
      communaute_step: 1,
      communaute_nb: 0,
      communaute: [],
      communaute_new: []
    }
  },

  mounted() {
    // this.members = EquipeList;
    this.fetch();
  },

  methods: {
    setMember(id) {
      this.current_member = id;
    },

    openBlank(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },

    async fetch() {

      let vm = this;

      await axios.get(this.$root.publicURI + 'api/getMembers/member/1', {})
          .then(res => {
            this.equipe = res.data;
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getMembers/communaute', {})
          .then(res => {

            for (let i = 0; i < res.data.length; i++) {
              let id = res.data[i].id;
              vm.communaute.push(id);
              vm.communaute_new.push(id);
            }
            vm.communaute_new = _.shuffle(vm.communaute_new);

            vm.communaute_interval = setInterval(function () {
              vm.communaute_step = (vm.communaute_step == 1) ? 2 : 1;
              //console.log(vm.communaute_step);

              setTimeout(function () {
                if (vm.communaute_step == 2) {
                  vm.communaute = _.shuffle(vm.communaute);
                  //console.log(vm.communaute_step);
                } else {
                  vm.communaute_new = _.shuffle(vm.communaute_new);
                  //console.log(vm.communaute_step);
                }
              }, 2000);
            }, 4000);


          })
          .catch(function (error) {

          });

      /*
      for(let i=1; i<this.communaute_nb; i++){
        vm.communaute.push(i);
        vm.communaute_new.push(i);
      }
      vm.communaute_new = _.shuffle(vm.communaute_new);

      this.communaute_interval = setInterval(function(){
        vm.communaute_step = (vm.communaute_step == 1) ? 2 : 1;
        console.log(vm.communaute_step);

        setTimeout(function(){
          if( vm.communaute_step == 2 ){
            vm.communaute = _.shuffle(vm.communaute);
          }
          else{
            vm.communaute_new = _.shuffle(vm.communaute_new);
          }
        }, 2000);
      }, 4000);
      */

    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  font-size: calc(36px + (90 - 36) * ((100vw - 300px) / (1024 - 300))) !important;
  line-height: calc(32px + (90 - 32) * ((100vw - 300px) / (1024 - 300))) !important;
}

#fonctionnement div.header {
  margin: 100px 0 30px;
}

#fonctionnement div.intro {
  max-width: 1200px;
  margin: 0 auto;
}

#fonctionnement div.intro div.txt1 {
  position: relative;
  max-width: 420px;
}

#fonctionnement div.intro h2 {
  position: relative;
  top: -60px;
  margin: 0;
  z-index: 1;
}

#fonctionnement div.intro p {
  margin-top: 45px;
  max-width: 540px;
}


#team {
  margin-bottom: 100px;
}

#team div.members div.card {
  height: 520px;
}

#team div.members div.photo {
  height: 400px;
  background-color: #2f2f2f;
}

#team div.members div.desc div.title {
  padding: 15px 0 10px;
}

#team div.members div.desc {
  height: 400px;
}

#communaute {
  margin-left: 100px;
}

#communaute div.txt1 {
  margin: 40px 140px 40px 0;
}

#communaute div.txt2 {
  margin: 60px;
}

#communaute div.wrapsquares2 {
  display: flex;
  justify-content: flex-end;
}

#communaute div.wrapsquares2 > div {
  width: 100%;
}

#communaute div.squares {
  max-width: 500px;
}

#communaute div.squares > div:nth-child(even) div.square {
  background-color: #ebebeb;
}

#communaute div.squares > div:nth-child(odd) div.square {
  background-color: #dedede;
}

#communaute div.square {
  position: relative;
  overflow: hidden;
}

#communaute div.square div.photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform .5s ease;
}

#communaute div.square div.photo.one {
  transform: translateX(-100%);
}

#communaute div.square div.photo.two {
  transform: translateX(100%);
}

#communaute div.square div.photo.on {
  transform: translateX(0);
}

#comite {
  margin-left: 100px;
}


@media (max-width: 80em) {
  #fonctionnement div.intro * {
    max-width: 100% !important;
  }
  #fonctionnement div.intro h2 {
    top: auto;
    margin: 45px 0 20px;
  }
  #team {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  #communaute {
    margin-left: 0;
  }
  #communaute div.txt1 {
    margin: 40px 40px 40px 0;
  }
  #communaute div.txt2 {
    margin: 20px 40px;
  }
  #comite {
    margin-left: 0;
  }
}

@media (max-width: 64em) {
  #fonctionnement div.header {
    margin-top: 50px;
  }
  #fonctionnement div.intro h2 {
    margin-top: 0;
  }
  #communaute div.txt1 {
    margin: 20px 0;
  }
  #communaute div.txt2 {
    margin: 60px 0 20px;
  }
  #communaute div.squares {
    margin: 0 auto;
  }
}


@media (max-width: 48em) {
  #team {
    max-width: 300px;
  }
  #team div.members div.card {
    height: 300px;
  }
  #team div.members div.card p {
    display: none;
  }
  #team div.members div.photo {
    height: 300px;
  }
  #team div.members div.desc {
    display: block;
    position: relative;
    left: auto;
    height: auto;
    margin-bottom: 40px;
  }
  #team div.members a.close {
    display: none;
  }
}
</style>