<template>
  <div id="domaines">
    <div class="header">
      <h1><span>Rapports annuels</span></h1>
    </div>

    <div id="actions" class="mb100">
      <div class="no-result grid-noGutter-middle-center">
        <h5>Aucun résultat</h5>
      </div>

      <div id="actions_list" class="list grid-center-equalHeight">
        <router-link :to="'/rapport/'+item.slug" class="col-4_md-6_sm-12 item" v-for="(item, index) in rapports"
                     :key="item.id">
          <div class="inner">
            <div class="txt">
              <!--<h4>{{ item.titre }}</h4>-->
              <p v-html="item.intro"></p>
            </div>

            <div class="pict cover" :style="'background-image:url(' + item.hero + ')'" v-if="item.hero">
              <div class="more">
                <svg>
                  <use xlink:href="#plus"></use>
                </svg>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="loading">loading</div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'rapports',

  data() {
    return {
      rapports: {}
    }
  },

  mounted() {
    this.fetch();

    if (window.location.hash.replace('#', '') != '') {
      this.scrollToList(0);
    }
  },

  methods: {
    async fetch() {
      await axios.get(this.$root.publicURI + 'api/getRapports', {})
          .then(res => {
            this.rapports = res.data;
          })
          .catch(function (error) {

          });
    }
  }
}
</script>


<style scoped lang="scss">
h1 {
  font-size: calc(50px + (120 - 50) * ((100vw - 300px) / (1024 - 300))) !important;
  line-height: calc(48px + (120 - 48) * ((100vw - 300px) / (1024 - 300))) !important;
}

div.header {
  margin: 100px 0 100px;
}

div.intro {
  max-width: 1280px;
  margin: 80px auto 0;
}

div.intro h2 {
  position: relative;
  top: -60px;
  margin: 0;
}

div.intro p {
  margin-bottom: 40px;
}

div.intro a {
  margin-right: 25px;
}

#actions {
  position: relative;
  min-height: 570px;
}

#actions div.no-result {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  height: 540px;
  text-align: center;
  border: 2px solid #f8f8f8;
}

#actions_list {
  position: relative;
  z-index: 1;
  background: #fff;
}

#actions a {
  display_: block;
}

#actions a.item div.inner {
  position: relative;
}

#actions a.item div.txt {
  text-align: center;
  padding: 30px 60px 260px;
  background: #f8f8f8;
  border: 2px solid #f8f8f8;
}

#actions a.item div.txt svg {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 20px;
}

#actions a.item div.txt h4 {
  color: #2f2f2f;
}

#actions a.item div.txt h6 {
  color: #f59100;
}

#actions a.item div.txt p {
  color: #2f2f2f;
}

#actions a.item div.pict {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #2f2f2f;
}

#actions a.item div.pict div.more {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 15px;
}

#actions a.item div.pict svg {
  width: 20px;
  height: 20px;
}

#actions a.item:hover div.txt {
  background: #fff;
}

#actions a.item:hover div.pict div.more {
  background: #f59100;
}

#actions a.item:hover div.pict svg {
  fill: #fff;
}

#actions div.loading {
  display: none;
}


@media (max-width: 80em) {
  #actions a.item div.txt {
    padding: 20px 30px 260px;
  }
}

@media (max-width: 64em) {
  #actions_list {
    margin: 0;
  }
  #actions a.item div.txt {
    padding: 20px 25px 230px;
  }
  #actions a.item div.pict {
    height: 180px;
  }
}

@media (max-width: 48em) {
  #filters {
    margin-bottom: 0;
  }
  #filters label.select br {
    display: none;
  }
  #actions_list {
    margin: 0;
  }
  #actions a.item {
    padding: 0;
    margin-bottom: 50px;
  }
  #actions a.item div.txt {
    padding: 20px 15px 200px;
  }
  #actions a.item div.pict {
    height: 150px;
  }
}
</style>