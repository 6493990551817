<template>
  <div id="gouvernance">
    <div class="header">
      <h1><span>Notre </span> gouvernance</h1>
    </div>
    <div class="intro grid-noGutter">
      <div class="col-6_lg-12">
        <div class="txt">
          <h2 class="orange">Le Conseil d’Administration</h2>
          <h3>Les administrateurs exercent leurs fonctions à titre gratuit, et se réunissent à l'occasion de 2 séances
            plénières du CA chaque année.</h3>
          <p>Le Conseil d’Administration délibère sur les questions mises à l'ordre du jour par son Président et par ses
            membres. Il valide les choix budgétaires, approuve les comptes, définit les grandes orientations du
            programme d’action pluriannuel et analyse l’impact des projets soutenus.</p>
        </div>
      </div>
      <div class="col-6_lg-12">
        <div id="schema">
          <div class="inner">
            <div class="svg">
              <svg version="1.1" id="administrateur" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 69.1 58.2" style="enable-background:new 0 0 69.1 58.2;" xml:space="preserve">
								<path d="M56.5,22.1c0.5,0,1-0.1,1.6-0.2l0.2,0c2-0.4,3.8-1.7,5-3.6c1.1-1.8,1.5-4,1.1-6.2c-0.5-2.1-1.7-3.9-3.6-5.1
									c-1.3-0.8-2.8-1.3-4.3-1.3c-0.6,0-1.2,0.1-1.8,0.2c-2.1,0.4-3.9,1.7-5.1,3.6c-1.1,1.8-1.5,4-1.1,6.2C49.4,19.5,52.7,22.1,56.5,22.1
									 M51.6,10.8c0.9-1.3,2.2-2.3,3.7-2.6C55.8,8,56.2,8,56.6,8c2.7,0,5.1,1.9,5.8,4.5c0.4,1.6,0.1,3.2-0.7,4.5
									c-0.9,1.3-2.2,2.3-3.7,2.6c-0.4,0.1-0.9,0.1-1.3,0.1c-2.6,0-5-1.8-5.7-4.5C50.4,13.8,50.6,12.2,51.6,10.8"/>
                <path d="M63,24.6H51c-1,0-1.9,0.2-2.9,0.7L46.8,26l-0.1-1.5c-0.3-3.2-2.9-5.6-6.2-5.6h-12c-3,0-5.6,2.2-6.1,5.2
									l-0.2,1.3L21,24.8c-0.9-0.5-1.9-0.7-2.8-0.7h-12c-3.4,0-6.2,2.8-6.2,6.2v12.6c0,2.7,1.8,5.2,4.4,5.9l0.7,0.2v7.4
									c0,0.7,0.6,1.1,1.1,1.1c0.5,0,1.1-0.6,1.1-1.1v-9.7H6.2c-2.2,0-3.9-1.8-3.9-3.9V30.3c0-2.2,1.7-3.9,3.9-3.9h12
									c2.1,0,3.9,1.7,3.9,3.9v12.6c0,2.2-1.8,3.9-3.9,3.9h-1.1v9.7c0,0.7,0.6,1.1,1.1,1.1c0.7,0,1.1-0.6,1.1-1.1v-7.4l0.7-0.2
									c0.8-0.2,1.7-0.8,2.4-1.4l0.7-0.7l0.7,0.7c0.9,1,1.8,1.6,2.9,1.9l0.7,0.2v7.4c0,0.7,0.6,1.1,1.1,1.1c0.7,0,1.1-0.6,1.1-1.1v-9.7
									h-1.1c-2.2,0-3.9-1.7-3.9-3.9V25.1c0-2.1,1.7-3.9,3.9-3.9h12c2.1,0,3.9,1.8,3.9,3.9v18.3c0,2.2-1.7,3.9-3.9,3.9h-1.1V57
									c0,0.7,0.6,1.1,1.1,1.1c0.7,0,1.1-0.6,1.1-1.1v-7.4l0.7-0.2c1.1-0.4,1.9-0.9,2.7-1.7l0.7-0.8l0.7,0.8c0.7,0.8,1.6,1.4,2.6,1.6
									l0.8,0.2V57c0,0.7,0.6,1.1,1.1,1.1c0.5,0,1.1-0.6,1.1-1.1v-9.6h-1.1c-2.2,0-3.9-1.7-3.9-3.9V30.8c0-2.2,1.7-3.9,3.9-3.9h12
									c2.2,0,3.9,1.7,3.9,3.9v12.6c0,2.2-1.8,3.9-3.9,3.9h-1.1V57c0,0.7,0.6,1.1,1.1,1.1c0.5,0,1.1-0.6,1.1-1.1v-7.4l0.7-0.2
									c2.5-0.8,4.4-3.3,4.4-5.9V30.8c0-1.6-0.6-3.2-1.7-4.3C66.2,25.3,64.7,24.6,63,24.6"/>
                <path d="M7.8,20.3c1.3,0.8,2.9,1.2,4.4,1.2c0.6,0,1.3-0.1,1.8-0.2c4.3-1,7.1-5.3,6.1-9.7c-0.4-2.1-1.7-3.9-3.6-5.1
									c-1.3-0.8-2.8-1.3-4.4-1.3c-0.6,0-1.2,0.1-1.8,0.2c-2,0.4-3.8,1.7-5.1,3.6c-1.1,1.8-1.5,4-1.1,6.2C4.7,17.3,6,19.1,7.8,20.3
									 M7.2,10.3C8,8.9,9.3,8,10.9,7.6c0.5-0.1,0.9-0.1,1.3-0.1c1.1,0,2.1,0.3,3.1,1c1.4,0.9,2.3,2.2,2.6,3.7c0.6,3.1-1.4,6.3-4.4,7
									c-0.5,0.1-0.9,0.2-1.4,0.2c-1.1,0-2.2-0.3-3.1-0.9c-1.3-0.9-2.3-2.2-2.6-3.7C6.1,13.2,6.3,11.6,7.2,10.3"/>
                <path d="M34.5,16.2c0.5,0,1-0.1,1.6-0.2l0.2,0c2-0.5,3.8-1.7,5-3.6c1.2-1.8,1.5-4,1.1-6.1c-0.4-2.1-1.7-3.9-3.6-5.1
									c-1.7-1.1-4-1.5-6.1-1.1c-4.4,1-7.1,5.3-6.1,9.7C27.5,13.6,30.7,16.2,34.5,16.2 M33.4,2.4c0.5-0.1,0.9-0.1,1.3-0.1
									c0.8,0,1.9,0.1,3,0.9l0,0C39.1,4,40,5.3,40.4,6.9c0.3,1.5,0.1,3.1-0.8,4.4c-0.8,1.3-2.1,2.2-3.7,2.6C35.5,14,35.1,14,34.6,14
									c-1.1,0-2.2-0.3-3.1-0.9c-1.3-0.8-2.2-2.1-2.6-3.7C28.2,6.3,30.2,3.2,33.4,2.4"/>
							</svg>
            </div>
            <a href="#" class="nb1" :class="{ on : current_list === 1 }"
               v-on:click.prevent="loadMembersList(1)"><span>8</span>
              <p>Représentants <br/>du fondateur</p></a>
            <a href="#" class="nb2" :class="{ on : current_list === 2 }"
               v-on:click.prevent="loadMembersList(2)"><span>2</span>
              <p>Représentants <br/>du personnel <br/>issus du fondateur</p></a>
            <a href="#" class="nb3" :class="{ on : current_list === 3 }"
               v-on:click.prevent="loadMembersList(3)"><span>5</span>
              <p>Personnalités qualifiées <br/>dans les domaines <br/>d’expertise de la Fondation</p></a>
          </div>
        </div>
      </div>
    </div>


    <div id="ca" class="mb100">
      <div class="grid-middle-noBottom-noGutter-reverse" id="members4">
        <div class="col right">
          <h2 class="orange">Le Conseil d’Administration est composé de 15 membres</h2>
        </div>
        <div class="col left">
          <div class="grid-middle-noBottom-noGutter">
            <a href="#" class="arrowbig prev" v-on:click.prevent="prevMember()">
              <svg>
                <use xlink:href="#arrow2"></use>
              </svg>
            </a>
            <a href="#" class="arrowbig next" v-on:click.prevent="nextMember()">
              <svg>
                <use xlink:href="#arrow2"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="grid-noBottom-noGutter-reverse" style="">
        <div class="col right">
          <h4><strong>8 représentants du fondateurs</strong> auprès des directions de filiales Up</h4>
          <div id="wrap-members1" class="wrap-members" :class="{ disabled : this.current_list != 1 }"
               v-touch:swipe="swipeHandler">
            <div id="members1" class="members grid-center-noBottom-noGutter" style="width:calc( 8 * 400px );"
                 data-nb="8">
              <div class="item" v-for="(item, index) in members1" :key="'members1_'+index"
                   :class="{ on : current_desc === index, even : index%2 == 0, odd : index%2 != 0 }">
                <div class="card">
                  <div class="more" v-on:click.prevent="setDesc(index)" :class="{ disabled : item.txt == '' }">
                    <svg>
                      <use xlink:href="#plus"></use>
                    </svg>
                  </div>
                  <div class="photo cover" :style="{ backgroundImage: 'url(' + $root.publicPath + item.photo + ')' }"
                       v-on:click.prevent="setDesc(false)"></div>
                  <p><strong>{{ item.nom }}</strong>{{ item.fonction }}</p>
                </div>
                <div class="desc grid-noBottom-noGutter" :class="{ disabled : item.txt == '' }">
                  <a href="#" class="close" v-on:click.prevent="setDesc(false)">
                    <svg>
                      <use xlink:href="#close"></use>
                    </svg>
                  </a>
                  <div class="title grid-noGutter-spaceBetween">
                    <h3><strong class="orange">{{ item.nom }}</strong><br/><span>{{ item.fonction }}</span></h3>
                  </div>
                  <div class="txt">
                    <p v-html="item.txt"></p>
                    <a :href="item.linkedin" class="linkedin" v-on:click.prevent="openBlank(item.linkedin)">
                      <img :src="$root.publicPath + 'img/picto/linkedin.png'"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h4><strong>2 représentants du personnel</strong> liées au Fondateur</h4>
          <div id="wrap-members2" class="wrap-members nodrag" :class="{ disabled : this.current_list != 2 }">
            <div id="members2" class="members grid-center-noBottom-noGutter" style="width:calc( 2 * 400px );"
                 data-nb="2">
              <div class="item" v-for="(item, index) in members2" :key="'members2_'+index"
                   :class="{ on : current_desc === index, even : index%2 == 0, odd : index%2 != 0 }">
                <div class="card">
                  <div class="more" v-on:click.prevent="setDesc(index)" :class="{ disabled : item.txt == '' }">
                    <svg>
                      <use xlink:href="#plus"></use>
                    </svg>
                  </div>
                  <div class="photo cover" :style="{ backgroundImage: 'url(' + $root.publicPath + item.photo + ')' }"
                       v-on:click.prevent="setDesc(false)"></div>
                  <p><strong>{{ item.nom }}</strong>{{ item.fonction }}</p>
                </div>
                <div class="desc grid-noBottom-noGutter" :class="{ disabled : item.txt == '' }">
                  <a href="#" class="close" v-on:click.prevent="setDesc(false)">
                    <svg>
                      <use xlink:href="#close"></use>
                    </svg>
                  </a>
                  <div class="title grid-noGutter-spaceBetween">
                    <h3><strong class="orange">{{ item.nom }}</strong><br/><span>{{ item.fonction }}</span></h3>
                  </div>
                  <div class="txt">
                    <p v-html="item.txt"></p>
                    <a :href="item.linkedin" class="linkedin" v-on:click.prevent="openBlank(item.linkedin)">
                      <img :src="$root.publicPath + 'img/picto/linkedin.png'"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h4><strong>5 personnalités</strong> dans les domaines d’intervention de la fondation.</h4>
          <div id="wrap-members3" class="wrap-members" :class="{ disabled : this.current_list != 3 }"
               v-touch:swipe="swipeHandler">
            <div id="members3" class="members grid-center-noBottom-noGutter" style="width:calc( 5 * 400px );"
                 data-nb="5">
              <div class="item" v-for="(item, index) in members3" :key="'members3_'+index"
                   :class="{ on : current_desc === index, even : index%2 == 0, odd : index%2 != 0 }">
                <div class="card">
                  <div class="more" v-on:click.prevent="setDesc(index)" :class="{ disabled : item.txt == '' }">
                    <svg>
                      <use xlink:href="#plus"></use>
                    </svg>
                  </div>
                  <div class="photo cover" :style="{ backgroundImage: 'url(' + $root.publicPath + item.photo + ')' }"
                       v-on:click.prevent="setDesc(false)"></div>
                  <p><strong>{{ item.nom }}</strong>{{ item.fonction }}</p>
                </div>
                <div class="desc grid-noBottom-noGutter" :class="{ disabled : item.txt == '' }">
                  <a href="#" class="close" v-on:click.prevent="setDesc(false)">
                    <svg>
                      <use xlink:href="#close"></use>
                    </svg>
                  </a>
                  <div class="title grid-noGutter-spaceBetween">
                    <h3><strong class="orange">{{ item.nom }}</strong><br/><span>{{ item.fonction }}</span></h3>
                  </div>
                  <div class="txt" :class="{ long : item.photo == '15.jpg' }">
                    <p v-html="item.txt"></p>
                    <a :href="item.linkedin" class="linkedin" v-on:click.prevent="openBlank(item.linkedin)">
                      <img :src="$root.publicPath + 'img/picto/linkedin.png'"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col left">
          <div class="nbs">
            <div class="row" :class="{ on : current_list === 1 }" v-on:click.prevent="loadMembersList(1)">
              <div class="nb">
                <span>8</span>
                <p><strong>Représentants</strong> <br/>du fondateur</p>
              </div>
            </div>
            <div class="row" :class="{ on : current_list === 2 }" v-on:click.prevent="loadMembersList(2)">
              <div class="nb">
                <span>2</span>
                <p><strong>Représentants <br/>du personnel</strong> <br/>issus du Fondateur</p>
              </div>
            </div>
            <div class="row" :class="{ on : current_list === 3 }" v-on:click.prevent="loadMembersList(3)">
              <div class="nb">
                <span>5</span>
                <p><strong>Personnalités qualifiées</strong> <br/>dans les domaines <br/>d’expertise de la Fondation</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-middle-noBottom-noGutter-bottom div-drag">
        <div class="col left">
          <img src="img/picto/drag.png" class="drag"/>
        </div>
        <div class="col right">
          <div id="dragwrap" v-if="current_list != 2">
            <range-slider min="0" :max="max_x" step="1" v-model="current_x" v-on:input="draggerRange($event)"
                          v-if="max_x > 0"></range-slider>
          </div>
        </div>
      </div>
    </div>

    <help-depot></help-depot>
  </div>
</template>


<script>
import RangeSlider from 'vue-range-slider'
/*
import MembersList1 from '../json/membres1.json'
import MembersList2 from '../json/membres2.json'
import MembersList3 from '../json/membres3.json'
*/
export default {
  name: 'gouvernance',

  components: {
    RangeSlider
  },

  data() {
    return {
      members1: {},
      members2: {},
      members3: {},
      current_list: false,
      current_member: false,
      current_desc: false,

      wrap_member: false,
      list_member: false,
      total_member: 0,
      wrap_width: 0,
      list_width: 0,

      current_x: 0,
      max_x: 0
    }
  },

  watch: {
    current_x() {
      this.current_member = Math.floor(this.current_x / 400) + 1;
      this.list_member.style.transform = "translateX(" + -this.current_x + "px)";
    }
  },

  beforeDestroy: function () {
    // $(window).off('resize');
    window.removeEventListener('resize', _.debounce(this.handleResize, 200));
  },

  mounted() {
    /*
    this.members1 = MembersList1;
    this.members2 = MembersList2;
    this.members3 = MembersList3;
    */
    this.fetch();

    this.loadMembersList(1, false);
    window.addEventListener('resize', _.debounce(this.handleResize, 200));
  },

  methods: {

    async fetch() {

      let vm = this;

      await axios.get(this.$root.publicURI + 'api/getMembers/member/2', {})
          .then(res => {
            this.members1 = res.data;
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getMembers/member/3', {})
          .then(res => {
            this.members2 = res.data;
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getMembers/member/4', {})
          .then(res => {
            this.members3 = res.data;
          })
          .catch(function (error) {

          });
    },

    openBlank(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },

    handleResize() {
      this.loadMembersList(this.current_list, false);
    },

    loadMembersList(num, scroll = true) {

      this.current_list = num;
      this.current_member = 1;
      this.current_x = 0;

      this.wrap_member = document.getElementById("wrap-members" + num);
      this.list_member = document.getElementById("members" + num);
      this.total_member = this.list_member.dataset.nb;

      this.wrap_width = this.wrap_member.offsetWidth;
      this.list_width = this.list_member.offsetWidth;
      this.max_x = parseInt(this.list_width - this.wrap_width);

      this.current_desc = false;

      if (scroll) {
        $('html, body').animate({'scrollTop': $('#ca').offset().top - 30}, 500);
      }
    },

    setDesc(id) {
      this.current_desc = id;
    },

    draggerRange(e) {
      this.current_x = e;
    },

    prevMember() {
      this.current_member = (this.current_member > 1) ? this.current_member - 1 : 1;

      if (this.current_member > 1) {
        this.current_x = ((this.current_member - 1) * 400);
      } else {
        this.current_x = 0;
      }
    },

    nextMember() {
      this.current_member = (this.current_member < this.total_member) ? this.current_member + 1 : this.total_member;
      if ((((this.current_member - 1) * 400) <= this.max_x)) {
        this.current_x = ((this.current_member - 1) * 400);
      } else {
        this.current_x = this.max_x;
      }
    },

    swipeHandler(direction) {
      if (direction == 'left') {
        this.nextMember();
      }
      if (direction == 'right') {
        this.prevMember();
      }
    }
  }
}
</script>


<style scoped lang="scss">
#gouvernance div.header {
  margin: 100px 0 30px;
}

#gouvernance div.intro {
  margin-left: 100px;
  margin-bottom: 200px;
  max-width: 1400px;
}

#gouvernance div.intro div.txt {
  max-width: 600px;
}

#gouvernance div.intro h2 {
  position: relative;
  top: -60px;
  z-index: 1;
}

#schema {
  position: relative;
  margin: 70px auto 0;
}

#schema div.inner {
  position: relative;
  width: 200px;
  margin: 0 auto;
}

#schema div.svg {
  position: relative;
  width: 100%;
  height: 200px;
  background: #f1f1f1;
  border-radius: 50%;
}

#schema svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  fill: #000;
}

#schema a {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 42px;
  background: #fff;
  border: 1px solid #f59100;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #f59100;
}

#schema a.nb1 {
  top: 0;
  left: 5%;
  line-height: 48px;
}

#schema a.nb2 {
  top: 20%;
  right: -10%;
}

#schema a.nb3 {
  bottom: -10%;
  left: 20%;
}

#schema a p {
  position: absolute;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #9d9d9d;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
}

#schema a.nb1 p {
  top: -100%;
  right: 100%;
}

#schema a.nb2 p {
  top: 100%;
  left: 100%;
}

#schema a.nb3 p {
  top: 140%;
  left: 10%;
}

#schema a:hover * {
  color: #f59100 !important;
}

#ca div.left {
  position: relative;
  flex-basis: 320px;
  max-width: 320px;
}

#ca div.right {
  position: relative;
}

#ca a.arrowbig {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  background: #ebebeb;
  border: 1px solid #ebebeb;
}

#ca a.arrowbig svg {
  width: 40px;
  height: 40px;
  fill: #000;
}

#ca a.arrowbig.prev {
  border-right: 1px solid #fff;
}

#ca a.arrowbig.prev svg {
  transform: rotate(180deg);
}

#ca a.arrowbig:hover {
  background: #fff;
}

#ca h2 {
  padding: 0 50px;
  margin: 0;
}

#ca h4 {
  text-align: center;
  margin: 40px 0 20px;
  background: #f59100;
  color: #fff;
  padding: 20px;
  display: none;
}

#ca div.nbs {
  display: flex;
  flex-direction: column;
  height: 600px;
}

#ca div.nbs div.row {
  flex-basis: 25%;
  padding: 0 20px;
  border: 1px solid #d6d6d6;
  border-right: 0;
  color: #d6d6d6;
  cursor: pointer;
}

#ca div.nbs div.row:hover {
  color: #666;
}

#ca div.nbs div.on {
  flex-basis: 50%;
  border: 0;
  color: #f59100 !important;
}

#ca div.nb {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  text-align: right;
  float: right;
}

#ca div.nb span {
  font-size: 60px;
  font-weight: 800;
  padding-left: 30px;
}

#ca div.nb p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin: 0;
}

#ca div.nb strong {
  font-weight: 600;
}

#ca div.on div.nb {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#ca div.on div.nb p {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

#ca div.on div.nb strong {
  font-weight: 800;
}

#ca div.wrap-members {
  position: absolute;
  width: calc(100% + 30px);
  max-width: 1430px;
  height: 742px;
  overflow: hidden; /*cursor:move;cursor:grab;*/
  cursor: pointer;
}

#ca div.wrap-members:active { /*cursor:grabbing;*/
  cursor: ew-resize;
}

#ca div.wrap-members:before {
  position: absolute;
  content: '';
  top: 0;
  width: 100%;
  height: 600px;
  background: #2f2f2f;
}

#ca div.wrap-members.disabled {
  top: -999999px;
  left: -99999px;
}

#ca div.wrap-members.nodrag {
  cursor: default !important;
}

@media (min-width: 1660px) {
  #ca div.wrap-members {
    width: calc(100% + 30px + ((100vw - 1660px) / 2));
  }
}

#ca div.members div.item {
  flex-basis: 400px;
  max-width: 400px;
}

#ca div.members {
  position: absolute;
  width: calc(8 * 400px);
}

#ca img.drag {
  display: block;
  width: 80px;
  margin: 100px auto 0;
  visibility: hidden;
}


@media (max-width: 80em) {
  #schema {
    left: -50px;
  }
  #ca div.wrap-members:before {
    display: none;
  }
}

@media (max-width: 64em) {
  #gouvernance div.header {
    margin-top: 50px;
  }
  #gouvernance div.intro {
    margin-left: 0;
    margin-bottom: 60px;
  }
  #gouvernance div.intro h2 {
    top: auto;
  }
  #schema {
    left: auto;
  }
  #schema div.svg {
    margin-bottom: 40px;
  }
  #schema a {
    display: block;
    width: 100%;
    height: auto;
    text-align: left;
    line-height: 20px !important;
    border: 0;
    border-radius: 0;
    margin: 0 0 20px;
    position: relative;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
  }
  #schema a span {
    position: absolute;
    top: 0;
    left: 0;
  }
  #schema a p {
    font-size: 13px;
    padding: 0 0 0 30px;
    position: relative;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    white-space: normal;
  }
  #schema a br {
    display: none;
  }

  #ca h2 {
    text-align: center;
    padding: 0 25px;
  }
  #ca h4 {
    display: block;
  }
  #ca > div {
    display: block;
  }
  #ca div.col {
    display: block;
    max-width: 100%;
  }
  #ca div.left > div {
    width: 300px;
    margin: 0 auto;
  }
  #ca a.arrowbig {
    width: 130px;
    height: 130px;
    margin: 20px 0;
    display: none;
  }
  #ca a.arrowbig svg {
    width: 40px;
    height: 40px;
    fill: #000;
  }
  #ca div.nbs, #ca .div-drag {
    display: none;
  }
  #ca div.wrap-members {
    position: relative;
    width: auto !important;
    height: auto;
    top: auto !important;
    left: auto !important;
  }
  #ca div.members {
    position: relative;
    width: auto !important;
  }
  #ca div.members div.item {
    flex-basis: 400px;
    max-width: 400px;
  }
  #ca div.members div.card {
    height: 520px;
  }
  #ca div.members div.photo {
    height: 400px;
  }
  #ca div.members div.desc {
    height: 400px;
  }
}


@media (max-width: 48em) {
  #ca div.members div.card {
    height: 300px;
  }
  #ca div.members div.card p {
    display: none;
  }
  #ca div.members div.photo {
    height: 300px;
  }
  #ca div.members div.desc {
    display: block;
    position: relative;
    left: auto;
    height: auto;
    margin-bottom: 40px;
  }
  #ca div.members a.close {
    display: none;
  }
}
</style>


<style>
/*
div.dragger input					{-webkit-appearance:none;width:100%;height:1px;background:#e3e3e3;outline:none;opacity:0.7;transition:opacity .2s;}
div.dragger input:hover				{opacity:1;}
div.dragger input::-webkit-slider-thumb,
div.dragger input::-moz-range-thumb {-webkit-appearance:none;appearance:none;width:50px;height:5px;border:0;background:#f59100;cursor:pointer;border-radius:0;}
*/

#dragwrap {
  position: relative;
  width: 100%;
  height: 6px;
  border: 0px solid green;
  background: #e3e3e3;
}

.range-slider {
  position: absolute;
  top: 0;
  left: 25px;
  right: 25px;
  display: block;
  height: 5px;
}

.range-slider-inner {
  position: absolute;
  display: block;
  width: 100%;
  height: 5px;
}

.range-slider-rail,
.range-slider-fill {
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  height: 5px;
  transform: translateY(-50%);
}

.range-slider-rail {
  width: 100%;
  background-color: #e3e3e3;
}

.range-slider-fill {
  background-color: #e3e3e3;
}

/*.range-slider-knob					{position:absolute;display:block;top:50%;left:0;width:50px;height:5px;border:0;background-color:#f59100;transform:translate(-50%, -50%);cursor:pointer;}*/
.range-slider-knob {
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  width: 50px;
  height: 50px;
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url('../img/picto/drag.png');
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.range-slider.disabled {
  opacity: 0.5;
}

.range-slider-hidden {
  display: none;
}
</style>
