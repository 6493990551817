<template>
  <div id="donnees" class="annexe">
    <h1><span>Données personnelles</span></h1>
    <div class="inner">
      Au sein de la Fondation d’entreprise Up, la protection des données à caractère personnel est un enjeu majeur.
      C’est la raison pour laquelle, vous trouverez ci-dessous la politique de protection des données à caractère
      personnel de la Fondation d’entreprise Up. <br/>Cette politique vous informe des conditions dans lesquelles vos
      données à caractère personnel sont collectées, traitées et protégées. <br/>Elle s’applique au site internet fourni
      par la Fondation d’entreprise Up. <br/><br/>
      <a :href="$root.publicPath + 'pdf/politique-de protection-des-donnees_Fondation_Up_2019.pdf'" class="arrow"
         target="_blank">
        <div>
          <svg>
            <use xlink:href="#arrow"></use>
          </svg>
        </div>
        Télécharger le document de politique de protection des données personnelles de la Fondation d’entreprise Up
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'donnees'
}
</script>