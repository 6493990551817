<template>
  <div id="sitemap" class="annexe">
    <h1><span>Plan du site</span></h1>
    <div class="inner">
      <div class="nav">
        <div>
          <span class="rub"
                :class="{ 'active' : $route.name === 'mission' || $route.name === 'gouvernance' || $route.name === 'fonctionnement' }">La fondation</span>
          <div>
            <div>
              <div>
                <router-link to="/mission">Mission</router-link>
              </div>
              <div>
                <router-link to="/gouvernance">Gouvernance</router-link>
              </div>
              <div>
                <router-link to="/fonctionnement">Fonctionnement</router-link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <router-link to="/engagements" :class="{ 'active' : $route.name === 'engagements' }">Engagements</router-link>
        </div>
        <div>
          <router-link to="/actions" :class="{ 'active' : $route.name === 'actions' }">Actions</router-link>
        </div>
        <div>
          <router-link to="/publications" :class="{ 'active' : $route.name === 'publications' }">Publications
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sitemap'
}
</script>

<style scoped lang="scss">
div.inner {
  display: none;
}

div.nav a,
div.nav span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 10px 20px 10px 0;
}

div.nav a:hover,
div.nav span:hover,
div.nav a.on,
div.nav span.on {
  color: #905500 !important;
}

div.nav span {
  font-weight: 700;
  color: #905500 !important;
}

div.nav > div > div {
  margin: 5px 0 30px 20px;
}
</style>


