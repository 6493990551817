<template>
  <div id="action">
    <div class="slider">
      <div class="arrows grid-top-center-noGutter-noBottom">
        <router-link :to="'/action/'+prev.slug" class="navigate prev cover"
                     :style="{ backgroundImage : 'url('+$root.publicPath+prev.visuel+')' }" v-if="prev.id">
          <div>
            <svg>
              <use xlink:href="#arrow2"></use>
            </svg>
          </div>
        </router-link>
        <router-link :to="'/action/'+next.slug" class="navigate next cover"
                     :style="{ backgroundImage : 'url('+$root.publicPath+next.visuel+')' }" v-if="next.id">
          <div>
            <svg>
              <use xlink:href="#arrow2"></use>
            </svg>
          </div>
        </router-link>
      </div>
      <div class="hero grid-top-center-noGutter">
        <div class="col-8_sm-12 picture cover" :style="{ backgroundImage : 'url('+$root.publicPath+item.visuel+')' }"
             v-if="item.visuel">
          <img :src="$root.publicPath + item.visuel" class="visuel"/>
        </div>

        <div class="col-8_sm-12 picture cover" :style="{ backgroundColor : '#000000' }" v-if="!item.visuel"></div>

        <div class="col-4_sm-12 inner" :style="{ borderColor : item.color }">
          <div class="rub" :style="{ backgroundColor : item.color }">
            <svg>
              <use :xlink:href="'#'+item.rubrique_code"></use>
            </svg>
          </div>
          <img :src="$root.publicPath + item.logo" v-if="item.logo"/>
          <dl>
            <template v-if="item.titre">
              <dd>{{ item.titre }}</dd>
            </template>
            <template v-if="item.structure">
              <dt>Nom de la structure</dt>
              <dd>{{ item.structure }}</dd>
            </template>
            <template v-if="item.pays_nom">
              <dt>Localisation</dt>
              <dd>{{ item.pays_nom }}</dd>
            </template>
            <template v-if="item.periode_soutien">
              <dt>Période de soutien</dt>
              <dd>{{ item.periode_soutien }}</dd>
            </template>
            <template v-if="item.domiciliation">
              <dt>Domiciliation de la structure</dt>
              <dd>{{ item.domiciliation }}</dd>
            </template>
            <template v-if="item.zone_geo">
              <dt>Zone géographique du projet</dt>
              <dd v-html="item.zone_geo"></dd>
            </template>
            <template v-if="item.impacts">
              <dt>Impact social - Enjeu</dt>
              <dd v-html="item.impacts"></dd>
            </template>
          </dl>
        </div>
      </div>
    </div>


    <div class="fiche grid-center-noGutter-reverse">
      <div class="main col-8_md-12">
        <div v-if="item.txt_structure != ''">
          <h4>La structure</h4>
          <div class="editor" v-html="item.txt_structure"></div>
          <br/>
          <a :href="item.web_structure" target="_blank" class="arrow" v-if="item.web_structure">
            <div>
              <svg>
                <use xlink:href="#arrow"></use>
              </svg>
            </div>
            voir le site de la structure</a><br/>
          <a :href="item.linkedin_structure" target="_blank" class="arrow" v-if="item.linkedin_structure">
            <div>
              <svg>
                <use xlink:href="#arrow"></use>
              </svg>
            </div>
            retrouver la structure sur les réseaux sociaux</a>
          <br/><br/><br/>
        </div>

        <div v-if="item.txt_projet != ''">
          <h4>Le projet</h4>
          <template v-if="item.visuel_projet">
            <div class="item grid-top-center">
              <div class="col-4_md-12">
                <img :src="$root.publicPath + item.visuel_projet" v-if="item.visuel_projet"/>
              </div>
              <div class="col-8_md-12">
                <div class="editor" v-html="item.txt_projet"></div>
              </div>
            </div>

          </template>
          <template v-if="!item.visuel_projet">
            <div class="editor" v-html="item.txt_projet"></div>
            <br/>
          </template>
          <template v-if="item.video_projet">
            <div>
              <script type="didomi/html" data-vendor="c:youtube" v-html="item.video_projet" v-if="isProd"></script>
            </div>
            <div v-html="item.video_projet" v-if="!isProd"></div>
            <br/>
          </template>
          <template v-if="item.txt2_projet">
            <div v-html="item.txt2_projet"></div>
            <br/>
          </template>
          <a :href="item.link_projet" target="_blank" class="arrow" v-if="item.link_projet">
            <div>
              <svg>
                <use xlink:href="#arrow"></use>
              </svg>
            </div>
            voir le site du projet</a>
          <br/><br/><br/>
        </div>

        <div v-if="item.photos.length > 0">
          <h4>Photos du projet</h4>
          <div class="slider">
            <flickity class="flickity" ref="flickity" :options="flickityOptions">
              <div class="carousel-cell" v-for="(photo, index) in item.photos" :key="index">
                <div class="item">
                  <!--<a href="#" target="_blank">-->
                  <img :src="$root.publicPath + photo.src" v-if="photo.src"/>
                  <!--</a>-->
                </div>
              </div>
            </flickity>
          </div>
        </div>

      </div>

      <div class="col-1_md-12"></div>

      <div class="aside col-3_md-12">
        <h6>Facteurs clés</h6>
        <div class="box" :style="{ backgroundColor : item.color }">
          <div v-if="item.montant_aide != ''">
            <h5>Montant de l'aide <br/>accordée sur {{ item.duree_financement }}<!--3 ans--></h5>
            <strong>{{ item.montant_aide }}€</strong>
          </div>
          <div v-if="item.option_financement != ''">
            <h5>{{ item.option_financement }}</h5>
          </div>
          <div v-if="item.beneficiaires != ''">
            <h5>Nombre de <br/>bénéficiaires</h5>
            <strong>{{ item.beneficiaires }}</strong>
          </div>
          <div v-if="item.soutiens && item.soutiens.length > 0">
            <h5 style="margin-bottom:15px;">Projet egalement <br/>soutenu par</h5>
            <p v-for="(soutien, index) in item.soutiens">
              <a :href="soutien.url" target="_blank" v-if="soutien.url != ''">{{ soutien.nom }}</a>
              <span v-if="soutien.url == ''">{{ soutien.nom }}</span>
            </p>
          </div>
        </div>

        <div class="shares top" v-if="twitter_url || facebook_url || linkedin_url">
          <h6>Partager ce projet</h6>
          <a :href="twitter_url" target="_blank" class="share" v-if="twitter_url">
            <svg>
              <use xlink:href="#twitter"></use>
            </svg>
          </a>
          <!--<a :href="facebook_url" target="_blank" class="share" v-if="facebook_url"><svg><use xlink:href="#facebook"></use></svg></a>-->
          <a :href="linkedin_url" target="_blank" class="share" v-if="linkedin_url">
            <svg>
              <use xlink:href="#linkedin"></use>
            </svg>
          </a>
        </div>
      </div>

    </div>


    <div class="quotes" v-if="item.quotes && item.quotes.length > 0">
      <a href="#" class="navigate prev" v-if="item.quotes.length > 1"
         v-on:click.prevent="selected_quote = (selected_quote===1) ? nb_quotes : selected_quote-1">
        <svg>
          <use xlink:href="#arrow2"></use>
        </svg>
      </a>
      <a href="#" class="navigate next" v-if="item.quotes.length > 1"
         v-on:click.prevent="selected_quote = (selected_quote<nb_quotes) ? selected_quote+1 : 1">
        <svg>
          <use xlink:href="#arrow2"></use>
        </svg>
      </a>
      <div class="list">
        <template v-for="(quote, index) in item.quotes">
          <transition name="quote-in" enter-active-class="animated fadeInLeft"
                      leave-active-class="animated hidden fadeOutRight" mode="out-in" appear>
            <div class="item grid-middle-center-noGutter" v-if="selected_quote===(index+1)">

              <template v-if="quote.type == 1 && quote.photo">
                <div class="col-3 photo">
                  <img :src="$root.publicPath + quote.photo" class="rounded"/>
                </div>
                <div class="col-1"></div>
                <div class="col-8">
                  <blockquote>
                    <p>{{ quote.txt }}</p>
                    <cite>
                      <strong>{{ quote.auteur }}</strong>
                      <span v-if="quote.fonction != ''">, </span>{{ quote.fonction }}
                    </cite>
                  </blockquote>
                </div>
              </template>

              <template v-if="quote.type == 1 && !quote.photo">
                <div class="col-12">
                  <blockquote>
                    <p>{{ quote.txt }}</p>
                    <cite>
                      <strong>{{ quote.auteur }}</strong>
                      <span v-if="quote.fonction != ''">, </span>{{ quote.fonction }}
                    </cite>
                  </blockquote>
                </div>
              </template>

              <template v-if="quote.type == 2">
                <div class="col-12">
                  <img :src="$root.publicPath + quote.visuel" v-if="quote.visuel"/>
                  <p v-html="quote.txt" v-if="quote.txt"></p>
                  <div class="videoWrapper" v-if="quote.video" v-html="quote.video"></div>
                </div>
              </template>
            </div>
          </transition>
        </template>
      </div>
    </div>


    <div class="shares bottom" v-if="twitter_url || facebook_url || pinterest_url">
      <h6>Partager ce projet</h6>
      <a :href="twitter_url" target="_blank" class="share" v-if="twitter_url">
        <svg>
          <use xlink:href="#twitter"></use>
        </svg>
      </a>
      <!--<a :href="facebook_url" target="_blank" class="share" v-if="facebook_url"><svg><use xlink:href="#facebook"></use></svg></a>-->
      <a :href="linkedin_url" target="_blank" class="share" v-if="linkedin_url">
        <svg>
          <use xlink:href="#linkedin"></use>
        </svg>
      </a>
    </div>

  </div>
</template>


<script>
export default {
  name: 'actions',

  data() {
    return {
      item: {},
      prev: {},
      next: {},
      nb_quotes: 0,
      selected_quote: 1,

      twitter_url: false,
      facebook_url: false,
      pinterest_url: false,
      linkedin_url: false,

      photos: {},
      flickityOptions: {
        prevNextButtons: true,
        pageDots: true,
        //wrapAround: true,
        autoPlay: true,
        autoPlay: 2000,
        pauseAutoPlayOnHover: true//,
        //cellAlign: 'left',
        //percentPosition: true
      }
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      await axios.get(this.$root.publicURI + 'api/getAction/' + this.$route.params.slug, {})
          .then(res => {
            this.item = res.data.item;
            // this.item.txt_structure = this.item.txt_structure.replace(' href=', ' class="arrow" href=');
            this.prev = res.data.prev;
            this.next = res.data.next;
            this.nb_quotes = this.item.quotes.length;

            this.twitter_url = 'https://twitter.com/intent/tweet?text=' + this.item.share_title + '&url=' + 'https://fondation-up.org/action/' + this.item.slug + '&wrap_links=true'; // &via&hashtags=
            this.facebook_url = 'https://www.facebook.com/sharer/sharer.php?u=' + 'https://fondation-up.org/action/' + this.item.slug; // &via&hashtags=
            this.pinterest_url = 'https://pinterest.com/pin/create/link/?url=' + 'https://fondation-up.org/action/' + this.item.slug + '&description=' + this.item.share_desc;
            this.linkedin_url = 'https://www.linkedin.com/sharing/share-offsite?mini=true&url=' + 'https://fondation-up.org/action/' + this.item.slug + '&title=' + this.item.share_title + '&summary=' + this.item.share_desc + '&source=' + process.env.VUE_APP_URL;

            this.photos = this.item.photos;
            //console.log(this.photos);

          })
          .catch(function (error) {

          });
    }//,

    /*startRotation: function() {
      this.timer = setInterval(this.nextPhoto, 3000);
    },

    nextPhoto: function() {
      this.currentPhoto += 1
    }*/
  }
}
</script>


<style lang="scss">
iframe, embed, video {
  display: block;
  margin: 0 auto;
  max-width: 100% !important;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<style scoped lang="scss">
#action div.slider {
  position: relative;
}

#action div.slider img.visuel {
  display: none;
}

#action div.slider div.arrows {
  position: absolute;
  width: 100%;
  height: 600px;
}

#action div.slider a.navigate {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100px;
}

#action div.slider a.navigate div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  transition: background 1s ease;
}

#action div.slider a.navigate.prev {
  left: -100px;
}

#action div.slider a.navigate.next {
  right: -100px;
}

#action div.slider a.navigate svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  fill: #fff;
}

#action div.slider a.navigate.prev svg {
  transform: rotate(-180deg);
  transform-origin: 25% 25%;
}

#action div.slider a.navigate:hover div {
  background: rgba(0, 0, 0, .1);
}

#action div.slider div.hero {
  position: relative;
  height: 100%;
}

#action div.slider div.hero > div {
  position: relative;
  height: 600px;
}

#action div.slider div.picture {
  height: 600px;
  background-color: #2f2f2f;
}

#action div.slider div.inner {
  position: relative;
  background: #f8f8f8;
  padding: 120px 40px 40px;
  min-height: 600px;
  height: auto !important;
}

#action div.slider div.rub {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
}

#action div.slider div.rub svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  height: 35%;
  fill: #fff;
}

#action div.slider div.inner img {
  position: absolute;
  top: 20px;
  right: 30px;
  max-height: 60px;
  max-width: 54%;
}

#action div.slider div.inner dl {
  clear: both;
}

#action div.slider div.inner dt {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase;
}

#action div.slider div.inner dd {
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}

#action div.fiche {
  margin: 100px 100px 30px;
}

#action div.fiche h6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 0 40px;
}

#action div.main {
  margin-bottom: 80px;
}

#action div.aside {
  max-width: 350px;
  margin: 0 auto;
}

#action div.box {
  padding: 40px;
  margin-bottom: 50px;
}

#action div.box h5 {
  position: relative;
  font-size: 13px;
  line-height: 20px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 0 5px;
  padding-top: 10px;
}

#action div.box h5:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 20px;
  height: 2px;
  background: #333;
}

#action div.box > div {
  margin-top: 50px;
}

#action div.box > div:first-child {
  margin-top: 0;
}

#action div.box strong {
  display: block;
  font-size: 40px;
  line-height: 46px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
}

#action div.box p {
  font-size: 13px;
  line-height: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 5px 0;
}

#action div.box a {
  font-size: 13px;
  line-height: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
}

#action div.shares {
  margin-bottom: 80px;
}

#action div.shares.bottom {
  display: none;
}

#action a.share {
  display: inline-block;
  margin-right: 20px;
}

#action a.share svg {
  width: 28px;
  height: 28px;
}

#action div.main {
  font-size: 18px;
  line-height: 28px;
}

#action h4 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 800;
}

#action div.photos img {
  display: block;
  margin: 0 auto 20px;
}

#action div.quotes {
  position: relative;
  background: #f8f8f8;
  padding: 50px 150px;
  margin: 30px 100px 100px;
  overflow: hidden;
}

#action div.quotes a.navigate {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#action div.quotes a.navigate.prev {
  left: 50px;
}

#action div.quotes a.navigate.next {
  right: 50px;
}

#action div.quotes a.navigate svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  fill: #2f2f2f;
}

#action div.quotes a.navigate.prev svg {
  transform: rotate(-180deg);
  transform-origin: 25% 25%;
}

.quote-in-enter-active {
  animation: quote-anim .5s;
}

.quote-in-leave-active {
  display: none;
}

.carousel-cell {
  width: 100%;
  margin-right_: 10px;
  background_: #eee;
  height: 400px;
}

.carousel-cell img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  margin: 0 auto;
}

@keyframes quote-anim {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

#action div.quotes p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

#action div.quotes cite {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-style: normal;
}


@media (max-width: 1820px) {
  #action div.slider div.arrows {
    position: relative;
    width: 100%;
    height: auto;
  }
  #action div.slider a.navigate {
    position: relative;
    top: auto;
    height: 60px;
    width: 50%;
    left: auto !important;
    right: auto !important;
    border: solid #fff;
    border-width: 0 0 1px 0;
  }
  #action div.slider a.navigate.prev {
    border-width: 0 1px 1px 0;
  }
  #action div.slider a.navigate svg {
    width: 24px;
    height: 24px;
  }
  #action div.fiche {
    margin-top: 130px;
  }
}


@media (max-width: 80em) {
  #action div.quotes {
    padding: 50px 90px;
    margin: 30px 100px 40px;
  }
  #action div.quotes a.navigate.prev {
    left: 40px;
  }
  #action div.quotes a.navigate.next {
    right: 40px;
  }
}


@media (max-width: 64em) {
  #action div.fiche {
    margin: 100px 40px 0;
  }
  #action div.slider div.inner {
    padding: 120px 25px 40px;
    min-height: auto;
  }
  #action div.slider div.hero > div {
    position: relative;
    height: auto;
  }
  #action div.aside {
    text-align: center;
  }
  #action div.box h5:before {
    left: 50%;
    margin-left: -10px;
  }
  #action div.shares {
    text-align: center;
  }
  #action a.share {
    margin: 0 20px;
  }
  #action div.quotes {
    padding: 50px 90px;
    margin: 30px 0 70px 0;
  }
  #action div.quotes p {
    font-size: 16px;
    line-height: 24px;
  }
  #action div.quotes cite {
    font-size: 13px;
    line-height: 18px;
  }
  #action div.shares.top {
    display: none;
  }
  #action div.shares.bottom {
    display: block;
  }
}

@media (max-width: 48em) {
  #action div.slider {
    height: auto;
  }
  #action div.slider img.visuel {
    display: block;
  }
  #action div.slider div.inner {
    padding: 70px 20px 0px;
  }
  #action div.slider div.rub {
    width: 50px;
    height: 50px;
    tosp: -50px;
  }
  #action div.slider div.inner img {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  #action div.slider div.inner dl {
    padding-top: 15px;
  }
  #action div.fiche {
    margin: 40px 40px 0;
  }
  #action div.fiche h6 {
    margin-bottom: 20px;
  }
  #action div.quotes {
    padding: 30px 40px;
  }
  #action div.quotes a.navigate.prev {
    left: 20px;
    transform: scale(.6);
  }
  #action div.quotes a.navigate.next {
    right: 20px;
    transform: scale(.6);
  }
  #action div.quotes p {
    font-size: 14px;
    line-height: 20px;
  }
  #action div.quotes cite {
    font-size: 12px;
    line-height: 17px;
  }
}

@media (max-width: 36em) {
  #action div.fiche {
    margin: 40px 0 0;
  }
}

</style>
