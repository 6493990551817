<template>
  <div id="viewport" v-if="active">
    <p>{{ viewport }}</p>
    <div id="is-xxl"></div>
    <div id="is-xl"></div>
    <div id="is-lg"></div>
    <div id="is-md"></div>
    <div id="is-sm"></div>
    <div id="is-xs"></div>
  </div>
</template>
<script>
export default {
  name: "ViewportCheck",
  props: ['active'],
  data() {
    return {
      debug: true,
      viewport: 'xs',
      viewport_xxl: false,
      viewport_xl: false,
      viewport_lg: false,
      viewport_md: false,
      viewport_sm: false,
      viewport_xs: false
    }
  },

  mounted() {
    if (this.active) {
      window.addEventListener('resize', () => {
        this.checkViewport();
      });
      this.checkViewport();
    }
  },

  methods: {
    checkViewport() {
      this.viewport_xxl = document.getElementById('is-xxl').offsetWidth > 0;
      this.viewport_xl = document.getElementById('is-xl').offsetWidth > 0;
      this.viewport_lg = document.getElementById('is-lg').offsetWidth > 0;
      this.viewport_md = document.getElementById('is-md').offsetWidth > 0;
      this.viewport_sm = document.getElementById('is-sm').offsetWidth > 0;
      this.viewport_xs = document.getElementById('is-xs').offsetWidth > 0;

      this.viewport = this.viewport_xxl ? 'xxl' : (
          this.viewport_xl ? 'xl' :
              this.viewport_lg ? 'lg' :
                  this.viewport_md ? 'md' :
                      this.viewport_sm ? 'sm' :
                          'xs'
      );
    }
  }
}
</script>

<style lang="scss">
#viewport {
  position: fixed;
  top: -9999em;
  right: -9999em;
  z-index: -1;
}

/*#viewport.debug						{top:0;right:10px;z-index:99999;}*/
#viewport {
  top: 0;
  right: 10px;
  z-index: 99999;
}

#viewport p {
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
  padding: 0 5px;
  background: #000;
  color: #fff;
}

#viewport div {
  display: none;
}

#viewport #is-xxl {
  display: block;
}

@media (max-width: 100em) {
  #viewport #is-xxl {
    display: none;
  }
  #viewport #is-xl {
    display: block;
  }
}

@media (max-width: 80em) {
  #viewport #is-xl {
    display: none;
  }
  #viewport #is-lg {
    display: block;
  }
}

@media (max-width: 64em) {
  #viewport #is-lg {
    display: none;
  }
  #viewport #is-md {
    display: block;
  }
}

@media (max-width: 48em) {
  #viewport #is-md {
    display: none;
  }
  #viewport #is-sm {
    display: block;
  }
}

@media (max-width: 36em) {
  #viewport #is-sm {
    display: none;
  }
  #viewport #is-xs {
    display: block;
  }
}
</style>



