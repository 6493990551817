<template>
  <svg class="hidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <symbol id="arrow" viewBox="0 0 13.5 26.1">
        <path
            d="M0,25.6c0-0.1,0-0.3,0.1-0.4L12.3,13L0.1,0.9C0,0.7,0,0.3,0.1,0.1s0.5-0.2,0.7,0l12.5,12.5 c0.1,0.1,0.1,0.2,0.1,0.4s-0.1,0.3-0.1,0.4L0.9,25.9c-0.2,0.2-0.5,0.2-0.7,0C0,25.8,0,25.7,0,25.6"/>
      </symbol>

      <symbol id="arrow2" viewBox="0 0 471 777">
        <polygon points="99,777 471,389 99,0 0,95 282,389 0,682 "/>
      </symbol>

      <symbol id="plus" viewBox="0 0 286.4 286.4">
        <path d="M268.5,125.3H161.1V17.9c0-9.9-8-17.9-17.9-17.9c-9.9,0-17.9,8-17.9,17.9v107.4H17.9c-9.9,0-17.9,8-17.9,17.9
			c0,9.9,8,17.9,17.9,17.9h107.4v107.4c0,9.9,8,17.9,17.9,17.9c9.9,0,17.9-8,17.9-17.9V161.1h107.4c9.9,0,17.9-8,17.9-17.9
			C286.4,133.3,278.4,125.3,268.5,125.3"/>
      </symbol>

      <symbol id="contact" viewBox="0 0 93.5 83.5">
        <path d="M70.8,38c-1.2,0-2.5,0.1-3.8,0.3l-1.5,0.2V21.8c0-0.6-0.3-1.2-0.8-1.5l-31-20C33.4,0.1,33.1,0,32.8,0
			c0,0,0,0-0.1,0l0,0c-0.3,0-0.6,0.1-0.9,0.3l-31,20C0.3,20.6,0,21.1,0,21.7v40c0,1,0.8,1.8,1.8,1.8h46.4l0.2,1
			c1.9,11,11.3,19,22.4,19c4.8,0,9.5-1.5,13.4-4.3c0.8-0.6,1-1.7,0.4-2.5c-0.6-0.8-1.7-1-2.5-0.4c-3.3,2.4-7.2,3.7-11.3,3.7
			c-10.6,0-19.2-8.6-19.2-19.3s8.6-19.3,19.2-19.3c10.6,0,19.3,8.6,19.3,19.3c0,2.2-0.7,3.5-1.4,4.2c-0.9,1-2,1.5-3.7,1.5
			c-2.8,0-5.5-2.4-5.5-5v-8.7c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5h0c-0.6,0-1.1,0.3-1.4,0.8l-0.7,1l-1-0.6
			c-1.2-0.7-2.6-1.1-4-1.1c-4.8,0-8.7,4.4-8.7,9.8s3.9,9.8,8.7,9.8c2.4,0,4.6-1,6.2-2.9l0.8-1l1,0.9c1.7,1.6,4,2.5,6.3,2.5
			c2.5,0,4.8-1,6.3-2.7c1.5-1.7,2.2-3.9,2.2-6.5C93.5,48.2,83.3,38,70.8,38 M32.8,3.9l28,18.1l-28,17.8l-28-17.8L32.8,3.9z M62,39.8
			l-0.7,0.3c-7.5,3.4-12.5,10.6-13.2,18.8L48,60H3.5V25.3l28.3,17.9c0.6,0.4,1.3,0.4,1.9,0L62,25.3V39.8z M70.7,67
			c-2.5,0-5.2-2.4-5.2-6.3s2.7-6.3,5.2-6.3c2.5,0,5.2,2.4,5.2,6.3S73.1,67,70.7,67"/>
      </symbol>

      <symbol id="reload" viewBox="0 0 89.6 90">
        <path d="M80.7,82c0,1.7-1.3,3-3,3h-12c-1.7,0-3-1.3-3-3V70.1c0-1.7,1.3-3,3-3s3,1.3,3,3v5.3
			c9.3-7.3,14.9-18.6,14.9-30.6C83.6,23.4,66.2,6,44.8,6c-1.7,0-3-1.3-3-3s1.3-3,3-3c24.7,0,44.8,20.1,44.8,44.8
			c0,13.3-6,25.8-15.9,34.2h4C79.3,79,80.7,80.4,80.7,82 M44.8,84C23.4,84,6,66.6,6,45.2c0-12,5.6-23.3,14.9-30.6v5.3
			c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3V8c0-1.7-1.3-3-3-3h-12c-1.7,0-3,1.3-3,3s1.3,3,3,3h4C5.9,19.4,0,31.9,0,45.2
			C0,69.9,20.1,90,44.8,90c1.7,0,3-1.3,3-3S46.5,84,44.8,84"/>
      </symbol>

      <symbol id="alimentation" viewBox="0 0 431.2 430.1">
        <path d="M180.5,210.2c-7.8-9.3-15.9-14.3-25.9-16.3c-8.1-1.7-16.1-3.9-24.2-5.6c-15.2-3.2-27.1-11-37.7-22.6
			C64.6,135,35.6,105,7,74.7c-0.9-0.9-1.8-1.8-2.6-2.7c-5.3-5.8-5.8-14-1.3-19.2c4.8-5.5,13.9-6.4,20.2-1.9c1.5,1,2.7,2.4,4,3.6
			c26.9,26.1,53.9,52.3,80.8,78.4c1.3,1.2,2.6,2.4,4.9,2.7c-1.2-1.4-2.2-2.8-3.5-4c-26-26.8-52-53.6-78-80.4
			c-1.5-1.6-3.1-3.1-4.4-4.8C22.5,40.3,23,32,28,27.1c5.2-5,14-5.2,19.7,0c5.5,5,10.7,10.3,16,15.4c22.8,22.1,45.6,44.2,68.4,66.3
			c1.2,1.1,2.5,2.1,4.6,2.2c-1-1.1-2-2.3-3-3.4c-27-27.9-54-55.7-81-83.5c-4.7-4.9-5.5-14.1-1.6-18.9c4.5-5.6,13.5-7,19.6-2.9
			c1.2,0.8,2.2,1.8,3.3,2.8c33.1,31.2,66.2,62.3,99.3,93.5c7.2,6.8,11.9,15.3,14.2,25.1c2.4,10.3,5.1,20.6,7.3,31
			c2.1,9.8,7.2,17.5,16.3,25.3c1.1-1.8,1.9-3.9,3.4-5.4c52.4-52.5,104.9-104.9,157.2-157.4c7.1-7.1,15.4-9.9,25.1-7.8
			c14.6,3.2,22.8,17.4,18.5,33.8c-2.4,9.3-6.4,18.4-11.1,26.8c-11,19.8-25.9,36.9-41.8,52.9c-25,25-50.3,49.8-75,75
			c-9.7,9.9-19.6,18.8-34.8,18.4c-0.6,0.9-1.2,1.7-1.7,2.6c1.7,0.7,3.7,1,4.9,2.1c54.4,49.9,108.6,99.9,163,149.8
			c9.7,8.9,14.4,19.3,11.8,32.3c-2.6,12.9-10.5,21.8-23.2,25.4c-13.4,3.8-25.5,0.8-35.1-9.6c-25.5-27.5-50.8-55.2-76.3-82.8
			c-25.7-27.9-51.3-55.8-76.9-83.7c-0.8-0.9-1.4-2.1-2.4-3.4c-1.9,2.1-3.1,3.3-4.3,4.7c-46,52-92,104.1-138,156.1
			c-13.4,15.2-34.9,16.7-49,3.4c-13.9-13-14.1-35.4-0.1-49.4c49.8-50,99.7-99.8,149.7-149.6C176.4,212.6,178.5,211.6,180.5,210.2
			 M48.1,409.7c7,0,12.3-2.9,16.9-8.1c55.5-62.4,111.1-124.7,166.7-187c5.7-6.4,12.8-9.9,21.2-10.7c7.8-0.7,13.9-3.8,19.4-9.3
			c24.4-24.6,49-49,73.4-73.6c8.8-8.8,17.4-17.8,25.5-27.2c11.3-13.1,22-26.8,28.6-43.1c2.2-5.5,3.4-11.8,3.8-17.7
			c0.4-5.3-2.5-9.6-8.1-11.5c-5.2-1.8-9.5-0.4-13.3,3.3c-0.8,0.8-1.5,1.5-2.3,2.3c-114.7,114.7-229.3,229.3-344,344
			c-1.3,1.3-2.6,2.5-3.8,3.9c-5.8,6.8-7.2,14.5-3.5,22.7C32.4,405.8,39.2,409.7,48.1,409.7 M224.8,241.5c1.1,1.3,1.8,2.2,2.5,2.9
			c51.3,55.8,102.6,111.5,153.9,167.2c8.8,9.5,22.2,9.7,31.2,0.6c9.1-9.2,8.6-21.1-1.5-30.4c-56-51.6-112-103.1-168-154.7
			c-0.9-0.8-1.9-1.4-3-2.1C234.9,230.6,230,235.9,224.8,241.5 M13.8,61c-0.4,0.5-0.9,0.9-1.3,1.4c1.3,1.4,2.5,2.8,3.8,4.2
			c28.3,29.9,57,59.3,84.6,89.8c10.7,11.8,22.6,18.6,37.9,21.7c18,3.5,36.5,6.5,49.1,22.7c4.4-4.8,8.3-9.2,12.5-13.8
			c-9.7-7.8-15.4-18-18.1-30c-1.5-6.7-3.5-13.3-4.5-20c-2.2-15.3-9.5-27.1-21.2-37.6C125.9,71.7,96.2,43,66.1,14.7
			c-1.2-1.1-2.8-1.9-4.3-2.8c-0.4,0.5-0.8,0.9-1.2,1.4c1.2,1.4,2.2,2.9,3.5,4.2c15,15.6,29.9,31.3,45,46.8
			c12.6,12.9,25.4,25.5,38.1,38.3c1,1,1.8,2.3,2.3,3.6c2.2,5.9-0.1,13-5,16.3c-5.8,3.9-12.8,3.2-18.2-2c-28.1-27-56.2-54-84.2-81
			c-1.4-1.3-3-2.4-4.5-3.6c-0.4,0.4-0.8,0.9-1.2,1.3c1.2,1.4,2.3,2.9,3.5,4.2c26.7,27.6,53.5,55.2,80.3,82.7c6.6,6.8,7.1,15.8,1,21.7
			c-5.4,5.2-14.4,4.5-20.9-1.8c-27.4-26.4-54.8-52.8-82.3-79.2C16.7,63.5,15.2,62.3,13.8,61"/>
      </symbol>

      <symbol id="logement" viewBox="0 0 468.4 389.6">
        <path d="M361.8,376.1h40V269.6h13.6V376h39.2V269.7h13.7v5.8c0,34.4,0,68.8,0,103.2c0,9.5-1.4,10.9-11,10.9
					c-134.2,0-268.3,0-402.5,0c-9.5,0-10.8-1.4-10.8-11c0-54,0-108.1,0-162.1v-5.7c-3.1,0.6-6.3,2.3-9,1.6c-2.6-0.7-4.9-3.5-7-5.6
					c-8.1-8-16.1-16-24.1-24c-5.2-5.2-5.3-8.1-0.2-13.2C59,114.4,114.3,59.1,169.5,3.9c5.1-5.1,8.1-5.2,13.2-0.1
					c13.3,13.2,26.5,26.5,39.7,39.7c1.3,1.3,2.6,2.4,4.5,4.3c0.2-2,0.5-3.3,0.5-4.6c0-10.4,0-20.9,0-31.3c0-5.9,2.3-8.3,8.1-8.3
					c21.6-0.1,43.2-0.1,64.8,0c5.9,0,8.3,2.6,8.3,8.8c0,37.8,0,75.6,0.1,113.5c0,2.2,1.1,4.9,2.6,6.4c11.9,12.3,24.1,24.3,36.2,36.4
					c6.3,6.3,6.3,8.5,0.2,14.7c-8.4,8.4-16.8,16.8-25.2,25.2c-5.5,5.5-6.9,5.7-13.8,1.8v165.5h39.7V269.7h13.3V376.1z M57.4,375.8
					h237.2c0.1-1.5,0.2-2.5,0.2-3.6c0-57.6,0.1-115.3-0.1-172.9c0-2.5-1.6-5.5-3.4-7.3c-37.1-37.3-74.3-74.5-111.5-111.6
					c-1.1-1.1-2.3-2.2-3.6-3.4c-0.8,0.7-1.5,1.2-2.2,1.9c-38,37.9-76,75.9-113.8,114c-1.8,1.8-2.8,5.4-2.8,8.1
					c-0.2,56.2-0.1,112.4-0.1,168.6V375.8z M38.1,196.8c1-1.3,2.1-3,3.4-4.4C83.7,150.2,125.9,108,168,65.9c7.3-7.3,9-7.4,16.2-0.2
					c42.2,42.2,84.3,84.3,126.5,126.5c1.5,1.5,3,2.9,3.9,3.7c7.1-7.2,13.7-13.9,19.7-19.9c-52.6-52.6-105.6-105.6-158-158.1
					C123.6,70.6,70.5,123.6,17.7,176.4C23.9,182.6,30.5,189.2,38.1,196.8 M294.8,115.3V17.5h-53.9c0,13.5-0.2,26.6,0.1,39.6
					c0.1,2.7,1.4,6,3.2,7.9c15.3,15.7,30.9,31.1,46.5,46.7C291.8,112.8,293,113.7,294.8,115.3"/>
        <path d="M176.5,199.6c18.2,0,36.4,0,54.6,0c8.1,0,9.8,1.8,9.8,9.9c0,35.3,0,70.6,0,105.9c0,7.9-2,9.8-9.9,9.8
					c-36.6,0-73.1,0-109.7,0c-7.8,0-9.9-2.1-9.9-9.9c0-35.3,0-70.6,0-105.9c0-7.8,2-9.8,10-9.8C139.8,199.6,158.1,199.6,176.5,199.6
					 M227.3,213.3h-43.9v41.7h43.9V213.3z M227.3,269.6h-43.9v41.5h43.9V269.6z M125.2,255.1h43.9v-41.6h-43.9V255.1z M169.1,269.7
					h-43.6v41.7h43.6V269.7z"/>
      </symbol>

      <symbol id="education" viewBox="0 0 533.1 430.1">
        <path d="M33.6,145.9c-6.5-2.8-12.4-5.2-18.2-7.7c-10.4-4.5-12.2-14.5-4.1-22.4c1.2-1.2,2.1-3.2,2.1-4.9
					c0.1-11.1,0-22.2-0.3-33.3c0-1.6-0.7-3.5-1.7-4.6c-7.9-9.3-5-19.2,7-22.4c33.1-8.9,66.2-17.8,99.4-26.6
					c28.5-7.6,57.1-15.2,85.6-23.1c5-1.4,9.5-1.2,14.4,0.5c67.2,22.5,134.4,45,201.6,67.4c20.3,6.8,40.8,13.3,61.2,19.9
					c4.9,1.6,8,4.9,9.2,10c3.7,15.9,4.3,32.1,3.6,48.3c-0.3,5.8-1.2,11.6-1.7,17.3c-0.7,6.6-4.2,10.9-10.2,13.6
					c-1.7,0.7-3.7,1.7-4.5,3.2c-10.9,19.7-21.6,39.5-32.6,59.8c1.7,0.6,3.5,1.3,5.2,1.9c22.2,7.4,44.3,14.9,66.6,22.1
					c6.7,2.2,10.8,6.3,12.6,12.9c6.2,22.1,5.6,44.2-0.4,66.2c-1.5,5.4-5.6,7.8-10.3,9.8C493.7,363.9,469.4,374,445,384
					c-36.2,15-72.5,29.9-108.7,45c-3.3,1.4-6,1.4-9.4,0c-90.7-37.9-181.4-75.7-272.2-113.5c-11.9-4.9-13.6-14.1-4.5-23.3
					c0.7-0.7,1.5-1.7,1.5-2.6c0.1-12.3,0.1-24.6,0.1-37.1c-13.2-2-25.5-3.9-37.9-5.6c-5.8-0.8-10.5-3-12.6-8.8
					c-2-5.7,0.2-10.2,4.2-14.2c1.1-1.1,1.7-3,1.7-4.6c0.2-4.6,0-9.2,0.1-13.7c0.2-4.8-0.1-8.9-4.2-12.7c-4.8-4.4-3.8-11.7,1.4-17
					c8.3-8.6,16.8-17.1,25.2-25.7C30.9,149,32,147.7,33.6,145.9 M405.6,117.1c-2.2-1-3.4-1.6-4.7-2c-76.4-25.5-152.7-51-229.1-76.4
					c-2.3-0.8-5.2-0.9-7.6-0.3C132,46.7,99.9,55.2,67.7,63.6c-2.4,0.6-4.8,1.4-8.4,2.4c2.6,1.2,4,1.9,5.4,2.5
					c74.8,31.2,149.6,62.4,224.5,93.4c2.2,0.9,5.5,0.7,7.8-0.3c33.6-13.6,67-27.4,100.5-41.2C399.9,119.5,402.3,118.5,405.6,117.1
					 M280.5,221.3c0-10.7-0.2-20.6,0.1-30.4c0.1-4.1-1.4-5.8-5-7.2c-73.7-30.6-147.4-61.3-221.1-92c-5-2.1-10-4.1-15.5-6.3
					c0,10.4,0.3,20-0.1,29.6c-0.2,4.4,1.5,6.4,5.4,8c60.4,25,120.8,50.2,181.2,75.4C243.5,205.9,261.5,213.4,280.5,221.3 M318.5,398.1
					c0-10.7-0.3-20.4,0.1-30.1c0.2-4.4-1.5-6.1-5.2-7.6c-76.8-31.9-153.6-63.9-230.4-95.9c-1.7-0.7-3.5-1.3-5.8-2.2
					c0,10.4,0.2,20.1-0.1,29.7c-0.1,4.2,1.2,6.1,5.1,7.7c70.5,29.2,140.9,58.6,211.4,87.9C301.6,391.1,309.7,394.4,318.5,398.1
					 M32.7,201.1c0,6.7,0.2,12.5-0.1,18.2c-0.2,3.8,1.1,5.1,4.9,5.6c15.5,2,31,4.4,46.5,6.7c44.6,6.5,89.1,13.1,133.7,19.6
					c44.9,6.6,89.8,13.1,134.7,19.7c9.3,1.4,18.7,2.6,28.6,4.1c0-6.9-0.2-13,0.1-19.1c0.1-3.5-1.3-4.4-4.5-4.8c-8.4-1-16.8-2.1-25-3.8
					c-18.7-3.8-37-5.8-54.4,5c-1.9,1.2-5.5,0.9-7.8,0c-16-6.4-31.9-13.1-47.8-19.7c-2.3-0.9-4.6-1.8-7-2.2c-10.4-1.6-20.9-3-31.3-4.6
					c-42.5-6.2-85-12.4-127.4-18.5C61.8,205.2,47.7,203.2,32.7,201.1 M419.4,284.7c-3,5.2-6.2,9.9-8.6,15c-2,4.2-4.6,4.9-8.8,4
					c-6-1.2-12.2-1.9-18.3-2.8c-42.5-6.2-85-12.3-127.5-18.5c-32-4.7-64-9.3-96.1-14c1.5,1.4,3.2,2.2,5,3
					c53.7,22.4,107.5,44.8,161.3,67c2.5,1.1,6.3,1.1,8.8,0c34.4-13.9,68.7-28.1,103-42.3c1.7-0.7,3.3-1.6,6.1-2.8
					C435.5,290.2,427.7,287.6,419.4,284.7 M305.9,221.9c7.5-3.1,14.5-5.9,21.4-8.7c44.9-18.3,89.9-36.5,134.8-54.9
					c1.9-0.8,4.4-2.4,4.8-4c2.7-11.5,3.1-23.2,1.3-35.3c-2,0.8-3.6,1.3-5.2,2c-14.8,6.1-29.6,12.1-44.4,18.2
					c-36.1,14.8-72.2,29.6-108.3,44.6c-1.8,0.7-4.2,2.7-4.2,4.2C305.7,199,305.9,210.1,305.9,221.9 M507.8,295.3
					c-3.6,1.3-5.6,2-7.5,2.8c-20.3,8.3-40.7,16.7-61,25c-30.3,12.4-60.5,24.8-90.7,37.3c-1.7,0.7-4.4,2.3-4.5,3.5
					c-0.3,11.3-0.2,22.6-0.2,33.9c1.2,0,1.5,0.1,1.8,0c52.3-21.6,104.6-43.2,156.9-65c1.4-0.6,3-2.6,3.1-4.1
					C506.6,317.9,507.1,307.1,507.8,295.3 M139.4,191.1l0.1-1.1c-25.9-10.8-51.9-21.7-77.9-32.4c-1.2-0.5-3.4-0.4-4.2,0.4
					c-5.9,5.7-11.6,11.7-18.2,18.5C73.4,181.5,106.4,186.3,139.4,191.1 M440.7,194.6c-6.5,2.5-12,3.3-15,9.8
					c-4.9,10.7-10.5,21.1-16.4,31.2c-3.3,5.8-2.8,11.5-1.6,17.8C418.5,234.2,429.3,215,440.7,194.6 M386.8,216.2
					c-6.3,2.6-12.1,5-17.8,7.4C382.2,226.5,382.2,226.5,386.8,216.2"/>
      </symbol>

      <symbol id="sante" viewBox="0 0 460.8 420.2">
        <path d="M229.4,36.4c11.1-7.3,21.2-15.3,32.3-21c81.4-41.6,181.8,5.3,197,99.2c7.4,45.8-5.7,86-38.6,119
					c-60.8,61.1-121.8,121.9-182.7,182.8c-5,5-9.1,5-14,0.1C162.6,355.7,101.7,294.9,40.9,234C10.6,203.6-2.8,166.7,0.5,123.9
					C5.1,63.8,53.3,11.7,112.4,2.1c44.1-7.2,82.7,4.2,116.1,33.7C229,36.2,229.6,36.6,229.4,36.4 M287,255c0.8-2.2,1.3-3.7,1.8-5.2
					c14.6-46.1,29.1-92.3,43.7-138.4c1.1-3.6,2.9-6.4,6.7-7.2c3.9-0.7,6.1,1.7,8.3,4.5c14,17.9,28,35.7,42.2,53.5c1.2,1.5,3.6,3,5.4,3
					c14.8,0.2,29.6,0.1,44.5,0.2c2.6,0,3.7-0.8,4.2-3.4c3.2-15.5,3.1-31,0.7-46.6C436,62,388.3,18.4,334.2,15.1
					c-37.7-2.3-70.1,9.6-97,36.2c-5.1,5.1-8.1,5-13.2,0c-30.1-29.6-66.2-41.3-107.7-34.6C34.6,29.8-8.5,117.4,26.8,190.7
					c1.1,2.2,2.3,3.1,4.8,3c12.8-0.1,25.6-0.2,38.5,0c3.4,0.1,6.9,0.8,10,1.9c8.2,2.9,16.3,6.3,24.7,9.5c2.9-5.5,5.7-10.7,8.4-15.9
					c15.3-29,30.6-58,45.9-87.1c2.1-4,5.4-6,8.6-4.3c2.2,1.2,4.2,3.3,5.4,5.5c15.3,27.8,30.4,55.7,45.5,83.7c1.5,2.8,3.1,3.7,6.2,3.6
					c8.8-0.2,17.6,0,26.4-0.1c4.4,0,7.3,1.7,9,5.8c3,7,6.2,13.9,9.4,20.8C275.4,229.7,281.1,242,287,255 M37.5,207.7
					c3.9,6.5,186.3,190.7,193.1,195.2c0.6-0.6,1.3-1.2,2-1.9c59.5-59.5,119-119,178.5-178.5c10.9-10.9,19.3-23.4,25.7-37.5
					c0.7-1.5,1.3-3.1,2.2-5.3h-4.9c-14.3,0-28.6-0.1-42.9,0.1c-4.2,0-7.3-1.3-9.9-4.7c-8.3-10.8-16.8-21.4-25.2-32.1
					c-4.1-5.2-8.2-10.3-12.7-16c-0.7,2.2-1.2,3.7-1.7,5.2c-15.1,47.8-30.2,95.6-45.2,143.4c-1.2,3.7-2.6,6.9-7,7c-4.2,0.2-6.4-2.4-8-6
					c-10.4-22.7-20.9-45.3-31.2-68c-1.1-2.5-2.4-3.6-5.3-3.5c-8.4,0.2-16.8-0.1-25.2,0.2c-5.1,0.2-8.1-1.9-10.4-6.3
					c-13.4-24.9-27-49.7-40.5-74.5c-0.7-1.2-1.4-2.4-2.4-4.1c-2.3,4.3-4.3,8-6.3,11.7c-14.8,27.8-29.6,55.6-44.3,83.4
					c-3.5,6.6-6,7.4-12.9,4.6c-6.3-2.6-12.6-5.2-18.9-7.8c-3.8-1.6-7.6-4.2-11.5-4.4C61,207.3,49.5,207.7,37.5,207.7"/>
      </symbol>

      <symbol id="world" viewBox="0 0 512 512">
        <path d="M387.7,464c-2.8-4.8-8.9-6.4-13.7-3.6l-0.4,0.2c-4.8,2.8-6.4,8.9-3.6,13.7c1.9,3.2,5.2,5,8.7,5
					c1.7,0,3.4-0.4,5-1.4l0.4-0.2C388.8,474.9,390.4,468.8,387.7,464"/>
        <path d="M477.7,128.1C443.5,68.9,388.3,26.5,322.3,8.8c-66-17.7-135-8.6-194.2,25.6C68.9,68.6,26.5,123.8,8.8,189.8
					S0.2,324.8,34.4,384c30.7,53.1,79.4,93.5,137.2,113.6c27.4,9.6,56,14.3,84.6,14.3c31.1,0,62.1-5.6,91.4-16.9
					c5.2-2,7.7-7.8,5.7-12.9c-2-5.2-7.8-7.7-12.9-5.7c-78,30-162.5,16.4-225.6-31l9-46.8l4.3-1.4c16.3-5.3,25.3-22.9,20-39.2l-3.2-9.7
					c-0.7-2.3-0.7-4.6,0-6.9c2.8-8.5,1.8-17.7-2.7-25.4c-4.5-7.7-12.1-13.1-20.9-14.8l-22.6-4.4l-43.5-33.1c-2.6-2-6-2.6-9.1-1.6
					l-25.5,8.3c-0.5-9.1-0.5-18.2,0-27.3c1,0.9,1.7,1.6,2.1,2c0.5,0.8,1.1,1.6,1.8,2.3c2.1,1.9,4,2.8,7.9,2.8c3.6,0,9-0.7,17.9-2
					c8.4-1.2,16.8-2.7,16.9-2.7c3.4-0.6,6.2-2.8,7.5-5.9c1.3-3.1,1-6.7-0.9-9.6l-12.3-18l17.6-12.6c1.2-0.8,2.1-1.9,2.9-3.2l27.2-47.5
					l11-11.8c11.2-12,14.4-29.6,8.2-44.8c-3.7-9-10.1-16.4-18.2-21.5c8.7-6.9,18.1-13.2,28-18.9c36.3-21,76-31.1,115.2-31.5l-17.9,22.4
					l-66.2,23.2c-2.7,0.9-4.8,3-5.9,5.6l-18.5,44.8c-1.3,3.2-0.9,6.8,1.1,9.6l27,37.8c-3.9,3.5-7.6,8.7-12.1,15.1
					c-2,2.8-3.8,5.4-5.1,6.9c-1.1,1.3-2.2,2.5-3.3,3.8c-5.8,6.5-11.8,13.3-16.1,22.3c-9.4,19.9-7.5,42.9,4.9,60.2
					c12,16.7,31.8,25.2,53,22.7c3.4-0.4,6.7-1.2,9.8-2c8.6-2.1,11.3-2.3,14,0.2c1.3,1.3,1.5,1.5,1.4,5.7c0,2.7,0,6.1,0.9,9.9
					c1.5,6.1,5.4,10.4,8.5,13.8c1.5,1.7,3.1,3.4,3.6,4.6c3.1,7.5,1.8,11.6-1.2,20.7c-0.3,0.8-0.5,1.6-0.8,2.4
					c-4.5,14,1.8,27.9,7.3,40.1c1.8,4,3.5,7.7,4.6,11c9,26.7,15.7,32.8,21.1,35.2c2.8,1.3,5.8,1.9,8.7,1.9c14.4,0,29.5-13.4,36.4-21.9
					c4.3-5.3,5.4-10.7,6.2-14.6c0.4-1.9,0.7-3.5,1.3-4.5c0.9-1.5,1.9-2.7,3.3-4.2c2.7-3,6-6.8,8.2-13.6c1.6-5.2,2.8-6.4,6.8-10.6
					c0.7-0.7,1.4-1.5,2.2-2.4c13.3-14.5,9.9-25.6,6.3-37.3c-3.1-9.9,2.3-16.2,15.2-28.2c5.6-5.2,11.4-10.6,15.9-17.1
					c2-2.8,7.9-11.5,4.2-20c-3.6-8.4-12.8-9.8-20.2-10.9c-2.9-0.4-7.3-1.1-8.7-2c-6.2-4-10-12.5-13.6-20.7c-0.7-1.6-1.4-3.2-2.2-4.8
					c-1.3-2.8-2.6-6.1-4-9.7c-3.6-9.4-7.8-20.1-14.8-27.5c-6.3-6.7-18.2-9.9-28.7-12.7c-3.5-1-6.9-1.9-9.1-2.7
					c-1.8-0.7-3.8-0.8-5.7-0.4c-5.1,1.2-8.3,2.5-10.7,4.4c-0.6,0.5-2.5,2.1-3.6,4.8c-3.7-1.8-8.7-5.1-11.8-7.2c-0.1,0-0.1-0.1-0.2-0.1
					c1-4.2,0.1-7.5-0.9-9.6c-5.6-11.8-24.1-10.9-27.8-10.6c-2.1,0.2-4.7,0.3-7.5,0.4c-4.4,0.2-9.2,0.3-14,0.9l0.2-0.7
					c3.6-10.1,13.3-16.9,24-16.9h5.7c5.5,0,10-4.5,10-10s-4.5-10-10-10H219c-15.9,0-30.5,8.4-38.7,21.6l-14.9-20.9l14.7-35.6l64.7-22.7
					c1.8-0.6,3.3-1.7,4.5-3.2l28.8-36.1c73.5,7,142.7,48.2,182.3,116.9c10.6,18.3,18.4,37.5,23.6,57.2l-4.2,3.8
					c-5.5,4.9-8.8,12-8.9,19.4c0,0.1,0,0.2,0,0.2l-6.9-19c-1-2.8-2.5-5.4-4.5-7.7l-12.3-14.4c-4.8-5.6-11.7-8.8-19.1-8.8h-15.1
					c-5.4,0-10.2,2.9-12.8,7.6c-2.5,4.7-2.2,10.4,0.7,14.9l1.5,2.3c-9.7,8.4-21,15.1-33,19.6l-20.7-39.6v-8.8c0-2.7-1.1-5.3-3.1-7.2
					L327,139.8c-1-1-2.3-1.7-3.6-2.2l-16.8-5.9c-5.2-1.8-10.9,0.9-12.7,6.1c-1.8,5.2,0.9,10.9,6.1,12.7l14.7,5.2l14,13.3v7
					c0,1.6,0.4,3.2,1.1,4.6l25.9,49.6c2.3,4.5,7.6,6.5,12.3,4.8l7.5-2.8c17.5-6.5,33.7-16.8,46.9-30c3.4-3.4,3.9-8.6,1.2-12.6l-0.5-0.8
					h4.8c1.5,0,2.9,0.7,3.9,1.8l12.3,14.4c0.4,0.5,0.7,1,0.9,1.6l13.6,37.4c1.2,3.2,3.9,5.6,7.2,6.3c0.7,0.2,1.4,0.2,2.2,0.2
					c2.6,0,5.2-1,7.1-2.9l6.5-6.5c4.1-4.1,6.9-9.3,8.3-15c10,80.2-21.4,163.2-87.9,215.3c-4.3,3.4-5.1,9.7-1.7,14
					c3.4,4.3,9.7,5.1,14,1.7c48-37.7,81-91.5,92.8-151.7C519.2,244.8,508.7,181.8,477.7,128.1 M47.3,282.7l41.2,31.4
					c1.2,0.9,2.6,1.6,4.1,1.9l24.9,4.9c3.2,0.6,5.8,2.5,7.5,5.3c1.6,2.8,2,6,1,9.1c-2.1,6.3-2.1,13,0,19.3l3.2,9.7
					c1.9,5.8-1.3,12.1-7.1,14l-9.9,3.2c-3.5,1.1-6,4.1-6.7,7.6l-8,41.7c-17.6-16.1-33-35.1-45.6-56.8c-15.3-26.5-24.8-54.7-29.1-83.3
					L47.3,282.7z M105.6,123.3l-11.8,12.6c-0.5,0.6-1,1.2-1.4,1.9l-26.7,46.6l-23.7,17c-4.4,3.2-5.5,9.3-2.4,13.8l8.9,13
					c-5.1,0.8-9.7,1.4-12.8,1.7c-0.6-0.6-1.2-1.1-1.8-1.7c-2.4-2.2-5.7-5.2-10.6-9.5c8.1-50.3,32.3-97.6,70.4-133.8
					c7.3,2.3,13.3,7.7,16.2,14.8C113.1,107.7,111.4,116.9,105.6,123.3 M187.1,177.9c4.5-2.5,14.8-2.9,22.4-3.1c3-0.1,5.9-0.2,8.4-0.4
					c1.3-0.1,2.8-0.1,4.3,0c-0.6,1.6-0.8,3.4-0.6,5.2c0.5,3.1,2.4,5.8,5.2,7.3c1.9,1,4.5,2.7,7.3,4.6c7.8,5.2,16.7,11.1,25,11.6
					c7.6,0.5,11.8-3.8,13.6-5.7c0.1-0.1,0.3-0.3,0.4-0.4c1-0.8,2-1.9,2.7-3.3c1.6,0.5,3.3,0.9,5,1.4c5.9,1.6,16.9,4.6,19.4,7.2
					c4.3,4.6,7.7,13.3,10.7,21c1.5,3.9,2.9,7.6,4.5,11c0.7,1.5,1.4,3,2,4.5c4.6,10.3,9.8,22,20.9,29.2c5,3.3,11.2,4.2,16.6,5.1
					c0.7,0.1,1.4,0.2,2.2,0.3c-3.2,4.5-7.8,8.8-12.7,13.3c-12.4,11.5-27.8,25.9-20.7,48.8c3.3,10.8,3.5,12-1.9,17.8
					c-0.7,0.8-1.4,1.5-2.1,2.2c-4.6,4.9-8.3,8.8-11.3,18.3c-0.9,2.8-2,4-4.1,6.4c-1.6,1.9-3.7,4.2-5.6,7.4c-2.3,3.9-3,7.6-3.6,10.6
					c-0.6,3-0.9,4.4-2.1,5.9c-2.6,3.2-7.6,7.9-12.6,11.2c-4.7,3-7.4,3.4-8.4,3.3c-1.1-1.1-4.9-6.2-10.8-23.5c-1.4-4.3-3.4-8.7-5.4-12.9
					c-4.3-9.4-8.7-19.1-6.5-25.7c0.3-0.8,0.5-1.5,0.7-2.3c3.4-10.4,6.7-20.3,0.7-34.6c-1.8-4.4-4.7-7.6-7.3-10.4
					c-1.7-1.8-3.5-3.9-3.8-5.1c-0.4-1.4-0.3-3.1-0.3-5.1c0-5.2,0.1-13.1-7.8-20.5c-11.1-10.4-24-7.2-32.5-5.1c-2.6,0.6-5.1,1.3-7.3,1.5
					c-14,1.6-26.6-3.7-34.4-14.6c-8.2-11.4-9.4-26.7-3.1-40c3-6.4,7.6-11.6,12.9-17.6c1.2-1.3,2.3-2.6,3.5-4c2-2.2,4.1-5.3,6.4-8.5
					C179.9,186.4,184.9,179.3,187.1,177.9"/>
        <path d="M410,344.3l-4.8-18.1c-1.1-4-4.4-6.9-8.5-7.4c-4.1-0.5-8,1.6-10,5.2c-1.1,2-2.1,4.2-3,6.2
					c-1.6,3.5-3.3,7.1-5,9.1c-1,1.1-3.5,2.3-5.9,3.5c-5.8,2.8-13.6,6.6-16.7,16.2c-1.9,6-1.2,10.7-0.7,14.1c0.4,3,0.5,4-0.3,5.9
					l-0.1,0.2c-3.2,7.5-9.1,21.4-0.8,33.2c5.9,8.4,12.2,10.6,17,10.6c0.9,0,1.8-0.1,2.7-0.2c10.2-1.7,17.9-12.3,24.1-33.4l11.8-39.6
					C410.5,347.9,410.5,346.1,410,344.3 M379,383.6c-3.2,10.8-6,15.8-7.7,18c-0.2-0.2-0.4-0.5-0.6-0.8c-1.9-2.7,0.9-9.4,2.8-13.9
					l0.1-0.2c3-7.2,2.2-12.6,1.6-16.7c-0.4-2.6-0.5-3.7,0-5.2c0.4-1.2,2.7-2.5,6.3-4.2c1.6-0.8,3.3-1.6,5-2.6L379,383.6z"/>
        <path d="M270,118.9l-0.7-0.2c-5.2-1.8-10.9,0.9-12.7,6.1c-1.8,5.2,0.9,10.9,6.1,12.7l0.7,0.2c1.1,0.4,2.2,0.6,3.3,0.6
					c4.1,0,8-2.6,9.4-6.7C278,126.4,275.2,120.7,270,118.9"/>
      </symbol>

      <symbol id="search" viewBox="0 0 490 490">
        <path d="M484,455.2L366.2,337.4c29.2-35.6,46.8-81.2,46.8-130.9C413,92.5,320.5,0,206.5,0C92.4,0,0,92.5,0,206.5
			S92.4,413,206.5,413c49.7,0,95.2-17.5,130.8-46.7L455.1,484c8,8,20.9,8,28.9,0C492,476.1,492,463.1,484,455.2 M206.5,371.9
			C115.2,371.9,41,297.7,41,206.5C41,115.3,115.2,41,206.5,41C297.7,41,372,115.3,372,206.5C372,297.7,297.7,371.9,206.5,371.9"/>
      </symbol>

      <symbol id="check" viewBox="0 0 6.3 4.9">
        <path d="M0,2.6l0.9-0.9l1.4,1.4L5.4,0l0.9,0.9l-4,4C1.5,4.1,0.8,3.4,0,2.6"/>
      </symbol>

      <symbol id="download" viewBox="0 0 59.8 60.2">
        <path d="M3,48.5l8.5,8.5c2.1,2.1,4.8,3.2,7.8,3.2s5.7-1.1,7.8-3.2l12-12l-4.2-4.2l-12,12c-0.9,0.9-2.2,1.5-3.5,1.5
				c-1.3,0-2.6-0.5-3.5-1.5l-8.5-8.5c-0.9-0.9-1.5-2.2-1.5-3.5c0-1.3,0.5-2.6,1.5-3.5l12-12l-4.2-4.2l-12,12C1.1,35.2,0,37.9,0,40.9
				S1,46.4,3,48.5"/>
        <path d="M56.8,11.7l-8.5-8.5C46.2,1.1,43.5,0,40.5,0s-5.7,1.1-7.8,3.2l-12,12l4.2,4.2l12-12c0.9-0.9,2.2-1.5,3.5-1.5
					c1.3,0,2.6,0.5,3.5,1.5l8.5,8.5c0.9,0.9,1.5,2.2,1.5,3.5s-0.5,2.6-1.5,3.5l-12,12l4.2,4.2l12-12c2.1-2.1,3.2-4.8,3.2-7.8
					S58.8,13.8,56.8,11.7"/>
        <rect x="15.9" y="27.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.5265 29.958)" width="28" height="6"/>
      </symbol>

      <symbol id="close" viewBox="0 0 60.8 60.8">
        <polygon
            points="53.7,0 30.4,23.3 7.1,0 0,7.1 23.3,30.4 0,53.7 7.1,60.8 30.4,37.5 53.7,60.8 60.8,53.7 37.5,30.4 60.8,7.1 "/>
      </symbol>

      <symbol id="earth" viewBox="0 0 106.8 106.8">
        <circle fill="none" stroke-width="6.82" stroke-linecap="round" stroke-linejoin="round" cx="53.4" cy="53.4"
                r="50"/>
        <ellipse fill="none" stroke-width="6.82" stroke-linecap="round" stroke-linejoin="round" cx="53.4" cy="53.4"
                 rx="18.1" ry="50"/>
        <line fill="none" stroke-width="6.82" stroke-linecap="round" stroke-linejoin="round" x1="6.6" y1="37.4" x2="100"
              y2="37.4"/>
        <line fill="none" stroke-width="6.82" stroke-linecap="round" stroke-linejoin="round" x1="6.6" y1="69.4" x2="100"
              y2="69.4"/>
      </symbol>

      <symbol id="arrow-bottom" viewBox="0 0 48.1 36.1">
        <path
            d="M47.9,1.1C47.5,0.4,46.8,0,46.1,0h-44C1.4,0,0.7,0.4,0.3,1.1C-0.1,1.8,0,2.6,0.4,3.2l22,32 c0.4,0.5,1,0.9,1.6,0.9s1.3-0.3,1.6-0.9l22-32C48.2,2.5,48.2,1.7,47.9,1.1"/>
      </symbol>

      <symbol id="twitter" viewBox="0 0 612 497.1">
        <path d="M612,58.8c-22.5,10-46.7,16.8-72.1,19.8c25.9-15.5,45.8-40.2,55.2-69.4c-24.3,14.4-51.2,24.8-79.8,30.5
				C492.4,15.2,459.8,0,423.7,0c-69.3,0-125.6,56.2-125.6,125.5c0,9.8,1.1,19.4,3.3,28.6C197.1,148.9,104.6,98.9,42.6,22.9
				C31.8,41.5,25.7,63,25.7,86c0,43.6,22.2,82,55.8,104.5c-20.6-0.7-39.9-6.3-56.9-15.8v1.6c0,60.8,43.3,111.6,100.7,123.1
				c-10.5,2.8-21.6,4.4-33.1,4.4c-8.1,0-15.9-0.8-23.6-2.3c16,49.9,62.3,86.2,117.3,87.2c-42.9,33.7-97.1,53.7-155.9,53.7
				c-10.1,0-20.1-0.6-29.9-1.7c55.6,35.7,121.5,56.5,192.4,56.5c230.9,0,357.2-191.3,357.2-357.2l-0.4-16.3
				C573.9,106.1,595.2,84,612,58.8"/>
      </symbol>

      <symbol id="facebook" viewBox="0 0 136.4 291.8">
        <path class="st0" d="M131.1,145.9l-39.9,0l0,145.9H36.5V145.9H0V95.6l36.5,0L36.4,66c0-41,11.1-66,59.4-66h40.2v50.3h-25.2
				c-18.8,0-19.7,7-19.7,20.1l-0.1,25.2h45.2L131.1,145.9z"/>
      </symbol>

      <symbol id="pinterest" viewBox="0 0 512 512">
        <path d="M373.7,0H138.3C62.1,0,0,62.1,0,138.3v235.3C0,449.9,62.1,512,138.3,512h235.3c76.3,0,138.3-62.1,138.3-138.3
					V138.3C512,62.1,449.9,0,373.7,0 M470.6,373.7c0,53.5-43.5,97-97,97H138.3c-53.5,0-97-43.5-97-97V138.3c0-53.5,43.5-97,97-97h235.3
					c53.5,0,97,43.5,97,97V373.7z"/>
        <path d="M370.6,238.1c-3.6-24.5-14.8-46.8-32.4-64.3c-17.5-17.5-39.8-28.7-64.3-32.4c-11.2-1.7-22.5-1.7-33.7,0
					c-30.3,4.5-57.1,20.5-75.4,45.2s-25.9,54.9-21.4,85.2c4.5,30.3,20.5,57.1,45.2,75.4c20,14.8,43.7,22.6,68.2,22.6
					c5.7,0,11.4-0.4,17.1-1.3c30.3-4.5,57.1-20.5,75.4-45.2C367.5,298.7,375.1,268.5,370.6,238.1 M267.8,327.6
					c-19.4,2.9-38.8-2-54.5-13.7s-26-28.8-28.9-48.2c-2.9-19.4,2-38.8,13.7-54.5c11.7-15.8,28.8-26,48.2-28.9c3.6-0.5,7.2-0.8,10.8-0.8
					c3.6,0,7.2,0.3,10.8,0.8c32.2,4.8,57.1,29.6,61.9,61.9C335.6,284.3,307.9,321.7,267.8,327.6"/>
        <path d="M400,112c-3.9-3.9-9.2-6.1-14.6-6.1c-5.4,0-10.8,2.2-14.6,6.1c-3.9,3.9-6.1,9.2-6.1,14.6
					c0,5.5,2.2,10.8,6.1,14.6c3.9,3.9,9.2,6.1,14.6,6.1c5.4,0,10.8-2.2,14.6-6.1c3.9-3.9,6.1-9.2,6.1-14.6
					C406.1,121.1,403.9,115.8,400,112"/>
      </symbol>

      <symbol id="linkedin" viewBox="0 0 639.38 611.14">
        <path
            d="M77.54,0C30.65,0,0,30.79,0,71.25c0,39.57,29.74,71.24,75.74,71.24h.89c47.8,0,77.55-31.67,77.55-71.24C153.28,30.79,124.43,0,77.54,0Z"/>
        <path d="M8.1,198.79H145.16V611.14H8.1Z"/>
        <path
            d="M481.58,189.11c-73.94,0-123.52,69.48-123.52,69.48v-59.8H221V611.14H358.06V380.87c0-12.33.89-24.64,4.51-33.46,9.91-24.61,32.46-50.11,70.32-50.11,49.6,0,69.44,37.81,69.44,93.24v220.6H639.38V374.7C639.38,248.05,571.75,189.11,481.58,189.11Z"/>
      </symbol>

      <symbol id="cycle" viewBox="0 0 700 700">
        <path d="M533.9,134l-16.8,0v-21.6c0-7.1-2.7-13.7-7.7-18.6c-5-5-11.6-7.8-18.7-7.8c-14.5,0-26.4,11.9-26.4,26.4V134H211.1v-21.6
		c0-7-2.7-13.6-7.7-18.6c-5-5-11.6-7.8-18.7-7.8c-14.5,0-26.4,11.9-26.4,26.4V134h-16.8c-34.7,0-63,28.3-63,63v319.2
		c0,34.8,28.3,63.1,63,63.1h392.4c34.7,0,63-28.3,63-63.1V197C596.9,162.3,568.6,134,533.9,134z M580.1,229.3v286.9
		c0,25.5-20.7,46.2-46.2,46.2H141.4c-25.5,0-46.2-20.7-46.2-46.2V229.3H580.1z M141.4,150.8h16.8v20.4c0,14.5,11.8,26.4,26.4,26.4
		c14.6,0,26.4-11.8,26.4-26.4v-20.4h253.2v20.4c0,14.5,11.8,26.4,26.4,26.4c14.6,0,26.4-11.8,26.4-26.4v-20.4h16.8
		c25.5,0,46.2,20.7,46.2,46.2v15.4H95.2V197C95.2,171.6,115.9,150.8,141.4,150.8z M500.3,171.3c0,5.3-4.3,9.6-9.6,9.6
		c-5.3,0-9.6-4.3-9.6-9.6l0-58.8c0-5.3,4.3-9.6,9.6-9.6c2.6,0,4.9,1,6.8,2.9c1.8,1.8,2.8,4.1,2.8,6.7V171.3z M194.3,171.3
		c0,5.3-4.3,9.6-9.6,9.6c-5.3,0-9.6-4.3-9.6-9.6l0-58.8c0-5.3,4.3-9.6,9.6-9.6c2.6,0,5,1,6.8,2.8c1.8,1.8,2.8,4.2,2.8,6.8V171.3z"/>
        <path d="M308.8,435.1c2.3,0,4.5-0.9,6-2.5l28.7-28.7c1.6-1.6,2.5-3.7,2.5-5.9v-39c0-1.7-0.5-3.3-1.4-4.6l1.9-3.9h-8.9
		c-4.6,0-8.4,3.8-8.4,8.4v35.6l-26.3,26.3c-1.6,1.6-2.5,3.7-2.5,6c0,2.2,0.9,4.4,2.5,6C304.5,434.1,306.7,435.1,308.8,435.1z"/>
        <path d="M268,466.4c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.3,0.3,0.5,0.5c18.4,18.2,42.8,28.2,68.6,28.2c25.9,0,50.2-10,68.6-28.2
		c0.2-0.1,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.3,0.5-0.5c18.2-18.4,28.2-42.8,28.2-68.6c0-25.8-10-50.1-28.1-68.5
		c-0.3-0.4-0.7-0.8-1.1-1.1c-18.4-18.2-42.8-28.2-68.6-28.2c-25.8,0-50.1,10-68.5,28.1c-0.4,0.3-0.8,0.7-1.1,1.1
		c-18.2,18.4-28.2,42.8-28.2,68.6S249.8,448,268,466.4z M282.3,354.3c1.6,1.6,3.7,2.5,5.9,2.5c2.3,0,4.5-1,6-2.6
		c1.6-1.6,2.4-3.7,2.4-5.9c0-2.2-0.9-4.4-2.5-6l-7.5-7.5c12.1-9.8,27.1-16,42.5-17.6V328c0,4.6,3.8,8.4,8.4,8.4s8.4-3.8,8.4-8.4
		v-10.7c15.5,1.6,30.4,7.8,42.5,17.6l-7.5,7.5c-1.6,1.6-2.5,3.7-2.5,6c0,2.2,0.9,4.4,2.5,6c1.6,1.6,3.7,2.5,5.9,2.5
		c2.3,0,4.5-0.9,6-2.5l7.5-7.5c9.8,12.1,16,27.1,17.6,42.5h-10.7c-4.6,0-8.4,3.8-8.4,8.4s3.8,8.4,8.4,8.4h10.8
		c-1.6,15.5-7.8,30.4-17.6,42.5l-7.5-7.5c-1.6-1.6-3.7-2.5-6-2.5c-2.2,0-4.4,0.9-6,2.5c-1.6,1.6-2.5,3.7-2.5,6c0,2.2,0.9,4.4,2.5,6
		l7.5,7.5c-12.1,9.8-27.1,16-42.5,17.6v-10.7c0-4.6-3.8-8.4-8.4-8.4s-8.4,3.8-8.4,8.4v10.7c-15.5-1.6-30.4-7.8-42.5-17.6l7.5-7.5
		c1.6-1.6,2.5-3.7,2.5-6c0-2.2-0.9-4.4-2.5-6c-1.6-1.6-3.7-2.5-6-2.5c-2.2,0-4.4,0.9-6,2.5l-7.5,7.5c-9.8-12.1-16-27.1-17.6-42.5
		h10.7c4.6,0,8.4-3.8,8.4-8.4s-3.8-8.4-8.4-8.4h-10.7c1.6-15.5,7.8-30.4,17.6-42.5L282.3,354.3z"/>
        <path d="M337.7,518.2c66.4,0,120.4-54,120.4-120.4c0-66.4-54-120.4-120.4-120.4c-66.4,0-120.4,54-120.4,120.4
		S271.3,518.2,337.7,518.2z M337.7,294.2c57.1,0,103.6,46.5,103.6,103.6c0,57.1-46.5,103.6-103.6,103.6s-103.6-46.5-103.6-103.6
		C234.1,340.7,280.5,294.2,337.7,294.2z"/>
      </symbol>

    </defs>
  </svg>
</template>

<script>
export default {
  name: "SvgIcons"
}
</script>
