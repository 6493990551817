<template>
  <div id="mentions" class="annexe">
    <h1><span>Mentions légales</span></h1>
    <div class="inner">
      <h4>SIÈGE SOCIAL : <span class="">UP</span></h4>
      <h4>EDITEUR DU SITE : <span class="">FONDATION D'ENTREPRISE UP</span></h4>
      Domiciliée au 9 – 11 boulevard Louise Michel<br/>
      92230 Gennevilliers - France
      <br/><br/>
      Autorisation préfectorale n°1909 publiée au Journal officiel le 25 novembre 2017.
      <br/>
      Tél : 01 41 85 05 05 <br/>
      E-mail : <a href="mailto:contact@fondation-up.org">contact@fondation-up.org</a>
      <br/><br/>
      Directeur de publication du site : Monsieur Julien Anglade - Directeur Général <br/>
      Responsable de la rédaction du site : Fondation d'entreprise Up
      <br/><br/>
      <h4>DÉVELOPPEMENT & CONCEPTION GRAPHIQUE</h4>
      Bearideas
      <br/><br/>
      <h4>HÉBERGEUR DU SITE</h4>
      OVH <br/>
      SAS au capital de 10 000 000 € <br/>
      RCS Roubaix - Tourcoing 424 761 419 00045 <br/>
      2 rue Kellermann - 59100 Roubaix - France <br/>
      Tel. : 1007 <br/>
      Site : <a href="https://www.ovh.com">www.ovh.com</a>
      <br/><br/>
      <h4>CONDITIONS GÉNÉRALES D’UTILISATION DU SITE</h4>
      Les présentes conditions générales d’utilisation (ci-après «&nbsp;Conditions Générales&nbsp;») définissent les
      droits et obligations des personnes qui se connectent au Site (ci-après «&nbsp;les Utilisateurs&nbsp;»).
      Les Utilisateurs s’engagent, en se connectant au Site, à respecter les Conditions Générales et à vérifier
      régulièrement si des modifications ont été apportées aux Conditions Générales, la Fondation d’entreprise Up se
      réservant le droit de les modifier à tout moment et sans préavis. <br/>
      Le simple accès au Site implique l’acceptation intégrale et sans réserve de l’ensemble des Conditions Générales.
      <br/><br/>
      <h5>1 – PROPRIETE INTELLECTUELLE</h5>
      Le Site ainsi que tous les éléments qui le composent (textes, images, marques, logos, logiciels, bases de données)
      sont la propriété de la Fondation d’entreprise Up et/ou la propriété de ses concédants ou partenaires et sont
      protégés par le droit de la propriété intellectuelle ou par les dispositions relatives au droit à l’image. <br/>
      L’ensemble des éléments présents sur le site peuvent être reproduits sur un support papier ou électronique sous
      réserve de : (1) n’utiliser les éléments qu’à des fins strictement personnelles excluant tout usage à des fins
      commerciales et/ou publicitaires; (2) ne pas modifier les éléments; (3) reproduire sur toutes copies la mention
      des droits d’auteur de la Fondation d’entreprise Up. <br/>
      Toute autre utilisation ou reproduction du Site ou d’éléments de ce dernier, non expressément autorisée par la
      Fondation d’entreprise Up est interdite et susceptible de constituer une contrefaçon au sens de la loi française.
      <br/>
      La Fondation d’entreprise Up met le Site à la disposition des Utilisateurs pour leur permettre de s’informer sur
      la Fondation d’entreprise Up, son actualité, ses activités et ses actions de mécénat.
      <br/><br/>
      <h5>2 – RESPONSABILITE</h5>
      Les Utilisateurs s’engagent à utiliser le Site conformément à sa destination. <br/>
      La Fondation d’entreprise Up fait ses meilleurs efforts pour rendre le Site accessible aux Utilisateurs. <br/>
      Le site est fourni ” en l’état “, sans garantie d’aucune sorte, ni expresse, ni tacite autre que celle prévue par
      la loi en vigueur, et notamment sans garantie que les informations figurant sur le site répondent aux besoins de
      l’Utilisateur ni qu’elles soient à jour. <br/>
      Bien que la Fondation d’entreprise Up s'efforce d'assurer au mieux l’exactitude et la mise à jour des informations
      diffusées sur le Site, elle ne garantit pas que son site web soit exempt d’inexactitudes, d’erreurs typographiques
      et d’omissions. <br/>
      Les Utilisateurs sont informés que les informations diffusées sur le Site n’ont pas un caractère exhaustif. <br/>
      La responsabilité de la Fondation d’entreprise Up ne saurait être engagée pour tous les dommages directs ou
      indirects résultant notamment : <br/>
      <ul>
        <li>De l’utilisation ou de l’impossibilité partielle ou totale d’utiliser le Site,</li>
        <li>Des contenus (informations, données, images…) publiés sur le Site,</li>
        <li>De la qualité des services fournis par le Site,</li>
        <li>De l’utilisation du réseau Internet.</li>
      </ul>
      La Fondation d’entreprise Up se réserve le droit de corriger ou modifier le Site et les informations qu'il
      contient à tout moment et sans notification préalable.
      <br/><br/>
      <h5>3 – DONNEES PERSONNELLES</h5>
      Retrouvez les informations pratiques en consultant :<br/>
      <ul>
        <li>
          <router-link to="/politique-de-confidentialite">la politique de confidentialité</router-link>
        </li>
      </ul>
      <br/>
      <h5>4 – LIENS HYPERTEXTES</h5>
      Le Site peut contenir des liens hypertextes vers d’autres sites Internet. Les liens vers ces autres ressources
      font quitter le Site.<br/>
      La Fondation d’entreprise Up n’est en aucun cas responsable du contenu de ces autres sites.<br/>
      Il est possible de créer un lien vers la page de présentation du Site sans autorisation expresse de la Fondation
      d’entreprise Up. Il convient toutefois d’afficher le Site dans une nouvelle fenêtre du navigateur. La Fondation
      d’entreprise Up se réserve le droit de faire supprimer lesdits liens sans avoir à motiver sa décision.
      <br/><br/>
      <h5>5 – DROIT APPLICABLE</h5>
      Tout litige en lien avec le Site est soumis au droit français.<br/>
      Les juridictions françaises sont seules compétentes pour connaître des litiges en lien avec le Site.
    </div>
  </div>
</template>

<script>
export default {
  name: 'mentions'
}
</script>