<template>
  <div id="home">

    <div id="hero">
      <div class="picture cover" style="background-image:url('img/bg.jpg');">
        <img :src="$root.publicPath + 'img/bg.jpg'" class="bg"/>
        <img :src="$root.publicPath + 'img/pouvoir.png'" class="pouvoir"/>
        <h2>Impulser le pouvoir d’agir ensemble, <br/>aux personnes en situation <br/>d’isolement ou de dépendance</h2>
      </div>
      <div class="bgcolor"></div>
      <!--<router-link to="/depot-projet" class="dossier"><p>Déposer <strong>un projet en ligne</strong></p></router-link>-->
      <div class="scroll"><span>notre ambition</span>
        <div>
          <svg>
            <use xlink:href="#arrow"></use>
          </svg>
        </div>
      </div>
    </div>

    <div id="ambition" class="mb100">
      <h1><span>L'ambition du </span>Groupe Up</h1>
      <div class="grid-middle-noBottom-noGutter row1">
        <div class="col-8_md-12 left">
          <div class="pict">
            <div class="videoWrapper">
              <script type="didomi/html" data-vendor="c:youtube" v-if="isProd">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/lDPGkpmNBOI?list=PL4aDB-0ELZqGJXneNHAx6CljOAfEJ7eLF" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </script>
              <iframe width="560" height="315"
                      src="https://www.youtube.com/embed/lDPGkpmNBOI?list=PL4aDB-0ELZqGJXneNHAx6CljOAfEJ7eLF"
                      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen v-if="!isProd"></iframe>
            </div>
            <!--<a href="#" class="play"></a> https://www.youtube.com/embed/7JD25an-E0s-->
          </div>
        </div>
        <div class="col-4_md-12 right">
          <div class="txt">
            <h3>“Promouvoir un modèle de développement économique, social et solidaire pour construire une société plus
              juste et démocratique”</h3>
            <router-link to="/mission" class="arrow">
              <div>
                <svg>
                  <use xlink:href="#arrow"></use>
                </svg>
              </div>
              Découvrez notre Fondation
            </router-link>
          </div>
        </div>
      </div>
      <div class="grid-middle-noBottom-noGutter row2">
        <div class="col-8_md-12 left">
          <!--<div class="slider"></div>-->
        </div>
        <div class="col-4_md-12 right">
          <div class="pict">
            <img :src="$root.publicPath + 'img/picture/ambition2.jpg'"/>
          </div>
        </div>
      </div>
    </div>

    <!--
    <iframe src='https://www.juicer.io/api/feeds/bearideas/iframe' frameborder='0' width='1000' height='1000' style='display:block;margin:0 auto;'></iframe>
    <ul class="juicer-feed" data-feed-id="fondation-up" data-origin="embed-code"></ul>
    -->

    <!--<script src='https://widgets.sociablekit.com/linkedin-page-posts/widget.js' async defer></script>-->

    <!--
    <div id="linkedin" style="background:#eee;">
      <div class="item">
        <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7041780340683624448" allowfullscreen="" title="Post intégré" width="504" height="667" frameborder="0"></iframe>
      </div>
    </div>
    -->

    <div id="linkedin" style_="line-height:1.5;">
      <h3 class="center">Nos actualités sur Linkedin</h3>
      <div class='sk-ww-linkedin-page-post' data-embed-id='165415'></div>
    </div>

    <div id="world" class="mb100">
      <h3 class="center">La Fondation Up soutient des projets dans <span v-html="this.countries.length"></span> pays
        d'implantation du groupe Up </h3>
      <div class="grid-noGutter content">
        <div class="col-12 map">
          <img :src="$root.publicPath + 'img/world.png'"/>
          <div class="countries">
            <flickity class="flickity" ref="flickity" :options="flickityOptionsWorld" v-if="countries.length > 0">
              <div class="carousel-cell" v-for="(country, index) in countries" :key="index">
                <router-link :to="'actions#' + country.id">{{ country.nom }}</router-link>
              </div>
            </flickity>
          </div>
        </div>
      </div>
    </div>


    <div id="homekeys" class="clearfix mb100">
      <h2 class="orange">Les 6 clés de notre engagement</h2>
      <h3>Notre fondation, c’est un ensemble de femmes <br class="md-hidden"/>et d’hommes salariés du groupe Up, qui
        porte <br class="md-hidden"/>une seule et unique ambition au service de la <br class="md-hidden"/>société
        civile.</h3>
      <div class="list">
        <div class="bullets">
          <div v-for="(item, index) in engagements" v-bind:class="{ 'on' : currentKey==index }"></div>
        </div>

        <div class="items">
          <template v-for="(item, index) in engagements">
            <transition name="key-in" enter-active-class="animated zoomIn" leave-active-class="animated hidden zoomOut"
                        mode="out-in" appear>
              <div class="item" v-if="currentKey==index">
                <div class="box" v-html="item.titre"></div>
              </div>
            </transition>
          </template>
        </div>
        <div class="txt">
          <p class="margin">La Fondation d’entreprise en permet l’expression et le partage, en fédérant toutes les
            composantes du groupe. <br/>Elle intègre la culture et les valeurs de l’entreprise en y inscrivant les
            principes de l’ESS.</p>
          <router-link to="/fonctionnement" class="arrow">
            <div>
              <svg>
                <use xlink:href="#arrow"></use>
              </svg>
            </div>
            Découvrez notre fonctionnement
          </router-link>
        </div>
      </div>
    </div>

    <div id="partners" class="grid-noGutter-spaceBetween-middle mb100">
      <div class="col-3_md-12">
        <p><strong>Partenaires</strong><!--<br /><span>Ils soutiennent nos actions</span>--></p>
      </div>
      <div class="col-9_md-12">
        <div class="slider" v-if="partners.length > 0">
          <flickity class="flickity" ref="flickity" :options="flickityOptions">
            <div class="carousel-cell" v-for="(item, index) in partners" :key="index">
              <div class="item">
                <a :href="item.url" :title="item.nom" target="_blank">
                  <img :src="$root.publicPath + item.logo" draggable="false"/>
                </a>
              </div>
            </div>
          </flickity>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from 'vue'

import Engagements from '../json/engagements.json'

import * as external from '../js/external.js'


export default {
  name: 'home',
  data() {
    return {
      partners: {},
      engagements: {},
      timer: null,
      currentNumber: 0,
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
        autoPlay: 2000,
        pauseAutoPlayOnHover: true,
        cellAlign: 'left',
        percentPosition: true
      },

      countries: {},

      flickityOptionsWorld: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
        autoPlay: 1000,
        pauseAutoPlayOnHover: true,
        cellAlign: 'left'
      }
    }
  },

  computed: {
    currentKey: function () {
      return Math.abs(this.currentNumber) % this.engagements.length;
    },
    isProd: function () {
      return process.env.VUE_APP_URL == 'https://fondation-up.org/';
    }
  },

  mounted() {

    console.log('URL => ' + this.$root.publicPath);

    this.fetch();
    this.startRotation();

    //external.head_script('https://assets.juicer.io/embed.js');
    //external.head_link('https://assets.juicer.io/embed.css');

    external.head_script('https://widgets.sociablekit.com/linkedin-page-posts/widget.js');
  },

  destroyed() {
    //external.del_script('https://assets.juicer.io/embed.js');
    //external.del_link('https://assets.juicer.io/embed.css');
    external.del_script('https://widgets.sociablekit.com/linkedin-page-posts/widget.js');
  },

  beforeDestroy() {
    $('div.countries').hide();
  },

  methods: {
    async fetch() {

      this.engagements = Engagements;

      await axios.get(this.$root.publicURI + 'api/getPartners', {})
          .then(res => {
            this.partners = res.data;
            this.$refs.flickity.rerender();
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getCountries/1', {})
          .then(res => {
            this.countries = res.data;
          })
          .catch(function (error) {

          });
    },

    startRotation: function () {
      this.timer = setInterval(this.nextKey, 3000);
    },

    nextKey: function () {
      this.currentNumber += 1
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  font-size: calc(50px + (120 - 50) * ((100vw - 300px) / (1024 - 300))) !important;
  line-height: calc(48px + (120 - 48) * ((100vw - 300px) / (1024 - 300))) !important;
}

#hero {
  position: relative;
  margin-bottom: 300px;
}

#hero div.picture {
  position: relative;
  height: 640px;
  z-index: 1;
}

#hero div.picture img.bg {
  display: none;
}

#hero div.picture img.pouvoir {
  display: block;
  height: 150px;
}

#hero div.bgcolor {
  position: absolute;
  bottom: -150px;
  left: -160px;
  background: #f59100;
  width: 160px;
  height: 100%;
}

#hero h2 {
  position: absolute;
  bottom: -150px;
  background: #f59100;
  color: #fff;
  padding: 8vh 4vw 8vh 0;
  max-width: 44.3vw;
  margin: 0;
}

#hero a.dossier {
  float: right;
  margin-top: 42px;
}

#hero div.scroll {
  position: absolute;
  bottom: -130px;
  left: 50%;
  background: blue;
  font-size: 15px;
  color: #999;
  z-index: 2;
  width: 21px;
  margin-left: -10px;
}

#hero div.scroll span {
  position: absolute;
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  white-space: nowrap;
}

#hero div.scroll div {
  position: absolute;
  bottom: -100px;
}

#hero div.scroll div:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 60px;
  bottom: 6px;
  left: 10px;
  background: #f59100;
}

#hero div.scroll svg {
  position: absolute;
  transform: rotate(90deg);
  bottom: 0;
  left: 0;
  width: 21px;
  height: 21px;
  fill: #f59100;
}

#ambition {
  position: relative;
  max-width: 1260px;
  margin: 0 auto;
  padding-top: 240px;
  overflow: hidden;
}

#ambition h1 {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  border: 1px solid #f0f0f0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#ambition div.txt {
  padding: 70px 0 70px 60px;
}

#homekeys {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
}

#homekeys div.list {
  position: relative;
  margin-top: 40px;
}

#homekeys div.items {
  margin-bottom: 40px;
  margin-right: 50px;
  border: 6px solid #f59100;
}

#homekeys div.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px;
  padding: 20px;
  text-align: center;
  font-size: 70px;
  font-weight: 800;
  color: #f59100;
}

#homekeys div.txt {
  float: right;
  max-width: 500px;
}

#homekeys div.bullets {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 90px;
  padding: 20px 0;
  background: #fff;
}

#homekeys div.bullets div {
  float: right;
  width: 50px;
  height: 1px;
  background: #c1c1c1;
  margin: 7px 0;
}

#homekeys div.bullets div.on {
  width: 90px;
  background: #f59100;
}

#partners {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

#partners * {
  outline: none !important;
}

#partners div.slider {
  position: relative;
  overflow: hidden;
}

#partners div.flickity {
  border_: 1px solid red;
}

#partners div.flickity div.carousel-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25.2%;
  height: 100px;
}

#partners div.flickity div.item {
  border_: 1px solid orange;
  padding: 10px 30px;
  display: block;
  width_: 100%;
}

#partners p {
  line-height: 26px;
}

#partners span {
  color: #bababa;
  font-weight: 500;
}

#world {
  position: relative;
}

#world div.content {
  margin-top: 50px;
}

#world div.map {
  position: relative;
  text-align: center;
}

#world div.txt {
  padding-top: 50px;
}

#world div.countries {
  position: absolute;
  left: 55%;
  bottom: 150px;
  width: 30vw;
  z-index: 2;
  background: rgba(255, 255, 255, .9); /*box-shadow:1px 1px 10px rgba(232,232,232,.5);*/
  overflow: hidden;
}

#world div.countries a {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 800;
  color: #f59100;
  text-transform: uppercase;
  padding: 15px 10px 12px;
  white-space: nowrap;
}

#world div.countries a:hover {
  text-decoration: underline;
}

/*#linkedin .grid-content				{margin:0 !important;}*/

.sk-linkedin-page-post-bottom-btn-container {
  display: none !important;
}

@media (max-width: 100em) {
  #hero {
    margin-bottom: 280px;
  }
  #hero div.picture {
    height: 490px;
  }
  #hero div.scroll {
    bottom: -80px;
    font-size: 12px;
  }
  #hero div.scroll div {
    bottom: -70px;
  }
  #hero div.scroll div:before {
    height: 30px;
    bottom: 6px;
  }
  #home h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (max-width: 80em) {
  #home_ h1 {
    font-size: 140px;
    line-height: 140px;
    letter-spacing: 0;
  }
  #hero {
    margin-bottom: 500px;
  }
  #hero h2 br {
    display: none;
  }
  #hero div.scroll {
    bottom: -280px;
  }
  #world div.countries {
    position: relative;
    left: auto;
    bottom: auto;
  }
  #world div.txt {
    padding-top: 0;
  }
  #world div.countries {
    left: 12vw;
    bottom: 0;
    width: 40vw;
  }
}

@media (max-width: 64em) {
  #hero {
    margin-bottom: 300px;
  }
  #hero div.bgcolor {
    display: none;
  }
  #hero div.picture {
    background: none !important;
    height: auto;
  }
  #hero div.picture img.bg {
    display: block;
  }
  #hero div.picture img.pouvoir {
    height: 100px;
    margin: 20px auto;
  }
  #hero h2 {
    position: relative;
    bottom: auto;
    max-width: 100%;
    padding: 20px;
    font-size: 20px;
    line-height: 28px;
    bottom: auto;
    top: auto;
  }
  #hero a.dossier {
    float: none;
    margin-top: 30px;
  }
  #hero div.scroll {
    bottom: -150px;
  }
  #ambition {
    padding-top: 140px;
  }
  #ambition img {
    display: block;
    margin: 0 auto;
  }
  #ambition div.txt {
    padding: 40px 20px;
  }
  #homekeys div.bullets {
    position: relative;
    top: auto;
    transform: none;
    right: auto;
    margin: 0 auto;
    display: none;
  }
  #homekeys div.items {
    margin-right: 0;
  }
  #homekeys div.box {
    font-size: 24px;
    height: auto;
    min-height: 120px;
  }
  #partners div.flickity div.carousel-cell {
    width: 50%;
  }
  #world div.countries {
    left: 25%;
    width: 50%;
  }
}


@media (max-width: 48em) {
  #partners div.flickity div.carousel-cell {
    width: 100%;
  }
  #world div.countries {
    left: 0;
    width: 100%;
  }
}

.juicer-feed h1.referral {
  display: none !important;
  border: 20px solid blue;
}
</style>