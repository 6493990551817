<template>
  <div id="donnees" class="annexe">
    <h1><span>Politique de confidentialité</span></h1>
    <div class="inner">
      Au sein de la Fondation d’entreprise Up, la protection des données à caractère personnel est un enjeu majeur.
      C’est la raison pour laquelle, vous trouverez ci-dessous la politique de protection des données à caractère
      personnel de la Fondation d’entreprise Up. <br/>Cette politique vous informe des conditions dans lesquelles vos
      données à caractère personnel sont collectées, traitées et protégées. <br/>Elle s’applique au site internet fourni
      par la Fondation d’entreprise Up. <br/><br/>
      <a :href="$root.publicPath + 'pdf/politique-de%20protection-des-donnees_Fondation_Up_2019.pdf'" class="arrow"
         target="_blank">
        <div>
          <svg>
            <use xlink:href="#arrow"></use>
          </svg>
        </div>
        Télécharger le document de politique de protection des données personnelles de la Fondation d'entreprise Up
      </a>

      <br/><br/>
      <h3>1. Déclaration relative à l’utilisation de cookies</h3>
      Lors de la consultation de notre site https://fondation-up.org/ (ci-après le « Site ») des cookies sont déposés
      sur l’ordinateur, la tablette ou tout terminal utilisé par vous (ci-après l’« Utilisateur »). Ceci nous (ci-après
      l’« Editeur » ou La Fondation d’Entreprise Up) permet d’analyser la fréquentation des pages et d’améliorer le
      Site. Les cookies sont utilisés selon leur nature et aux fins ci-après indiquées.<br/>
      L’Utilisateur a la possibilité d’accepter ou de refuser les cookies en utilisant le mécanisme intégré au bandeau
      ou les outils décrits ci-dessous.
      <br/><br/>
      <h3>2. Qu’est-ce qu’un Cookie?</h3>
      Un cookie (ci-après le(s) "Cookie(s) ") est un fichier texte déposé sur un terminal lors de la visite du Site ou
      de la consultation d’une publicité. Un Cookie a notamment pour but de collecter des informations relatives à la
      navigation de l’Utilisateur sur le Site et d’adresser à l’Utilisateur des services et contenus personnalisés.
      <br/><br/>
      <h3>3. Identification des Cookies</h3>
      Il existe différents types de Cookies :
      <br/>
      <h4>a. Cookies tiers et Cookies propriétaires</h4>
      Un Cookie est dit « propriétaire » ou « tiers » selon le domaine dont il émane. Les Cookies propriétaires sont
      ceux installés par le Site lorsque l’Utilisateur est en train de le consulter. Les Cookies tiers sont des Cookies
      installés par un domaine autre que celui du Site (à titre d’exemple, un cookie peut être déposé par une régie
      publicitaire tierce sur le Site). Lorsqu’un Utilisateur consulte le Site et qu’une autre entité installe un Cookie
      par l’intermédiaire du Site, ce Cookie est un Cookie tiers.
      <br/><br/>
      <h4>b. Cookies de session</h4>
      Ces Cookies permettent aux exploitants du Site de suivre les actions d’un Utilisateur le temps d’une session de
      navigation. La session de navigation commence dès que l’Utilisateur ouvre la fenêtre du navigateur et finit
      lorsqu’il ferme cette fenêtre. Les Cookies de session ont un caractère temporaire. Une fois le navigateur fermé,
      tous les Cookies de session sont supprimés.
      <br/><br/>
      <h4>c. Cookies persistants</h4>
      Ces Cookies restent sur l’appareil de l’Utilisateur après la fermeture de la session de son navigateur et ce, pour
      la durée prévue par chacun de ces Cookies. Ils sont activés chaque fois que l’Utilisateur consulte le Site.
      <br/><br/>
      <h3>4. Quels types de Cookies l’Editeur utilise et pour quelles finalités ?</h3>
      Différents types de Cookies sont utilisés sur le Site, ils ont des finalités différentes. Certains sont
      nécessaires pour utiliser le Site, d’autres sont optionnels selon vos choix.
      <br/>
      <h4>a. Les Cookies techniques strictement nécessaires</h4>
      Ces Cookies sont nécessaires au fonctionnement du Site. Ils permettent à l’Utilisateur d’utiliser les principales
      fonctionnalités du Site. Sans ces Cookies, l’Utilisateur ne peut pas utiliser le Site normalement. Ces Cookies
      requièrent uniquement une information de l’Utilisateur pour être déposés sur le terminal de l’Utilisateur et
      n’autorisent aucun suivi comportemental ou d’identification.
      <br/><br/>
      <h4>b. Les Cookies fonctionnels de personnalisation</h4>
      Ces Cookies permettent de personnaliser l’expérience de l’Utilisateur sur le Site en mémorisant ses préférences et
      permettent notamment :
      <ul>
        <li>d'adapter la présentation du Site aux préférences d'affichage du terminal (langue utilisée, résolution
          d'affichage, système d'exploitation utilisé, etc…) lors des visites des Utilisateurs sur le Site, selon les
          matériels et les navigateurs ;
        </li>
        <li>de mémoriser des informations relatives aux formulaires que l'Utilisateur a rempli sur le Site ou à des
          informations que l'Utilisateur a choisies sur le Site ;
        </lI>
        <li>d'offrir à l'Utilisateur un accès à son compte ou à tout autre espace réservé grâce à ses identifiants ;
        </li>
        <li>de mettre en œuvre des mesures de sécurité, par exemple lorsqu'il est demandé à l'Utilisateur de se
          connecter à nouveau à un contenu ou à un service après une certaine durée écoulée.
        </li>
      </ul>
      <br/>
      <h4>c. Les Cookies de mesure d'audience</h4>
      Les Cookies de mesure d'audience aident à établir des statistiques de fréquentation et d'utilisation des divers
      éléments composant le Site (rubriques et contenus visités, parcours). Ils permettent à l’Editeur d'améliorer
      l'intérêt et l'ergonomie du Site.
      <br/><br/>
      <h4>d. Les Cookies de publicité</h4>
      Les Cookies de publicité permettent de déterminer quelle publicité afficher en fonction de la navigation de
      l'Utilisateur sur le Site afin notamment de limiter le nombre d’affichages, de mesurer l'efficacité d'une campagne
      publicitaire, ou d’adapter les publicités en fonction des préférences de l’Utilisateur, si l’Utilisateur en a
      préalablement accepté la possibilité.
      <br/><br/>
      <h4>e. Les Cookies de partage de réseaux sociaux</h4>
      L’Editeur offre aux Utilisateurs des fonctionnalités permettant de partager des contenus éditoriaux ou autres
      publiés sur le Site à travers les réseaux sociaux. Ces Cookies permettent également aux réseaux sociaux de cibler
      leurs offres publicitaires. Les Cookies de réseaux sociaux sont gérés par l'éditeur du réseau social.
      L'Utilisateur est invité à prendre connaissance de la politique de gestion des Cookies des réseaux sociaux sur les
      sites concernés.
      <br/><br/>
      <h3>5. Comment l’Utilisateur peut-il gérer les Cookies ?</h3>
      Lors de sa première connexion, l'Utilisateur est informé que les partenaires de l’Editeur et tout autre tiers
      identifié peuvent déposer des Cookies via le Site. Seul l'émetteur d'un Cookie est susceptible de lire des
      informations qui y sont contenues et l’Editeur n’a aucun accès sur les Cookies que des tiers peuvent être amenés à
      utiliser. L'émission et l'utilisation de Cookies par des tiers sont soumises aux politiques de confidentialité de
      ces tiers en plus des dispositions de la présente Politique. En conséquence l'Utilisateur est invité à se rendre
      sur les sites Internet de ces tiers pour plus d'informations sur les Cookies qu'ils enregistrent et comment
      l'Utilisateur peut les gérer.
      <br/><br/>
      <h3>La gestion par l’Utilisateur du dépôt des Cookies</h3>
      L'Utilisateur peut gérer le dépôt des Cookies de la manière suivante :<br/>
      <ul>
        <li>Le dépôt des Cookies techniques strictement nécessaires est activé par défaut et ne peut être désactivé par
          l’Utilisateur, ces Cookies étant indispensables au fonctionnement du Site ;
        </li>
        <li>L’Utilisateur désactive ou active l’implantation des Cookies soumis au recueil de son consentement (Cookies
          de mesure d’audience, Cookies d’analyse comportementale, Cookies de publicité, etc.) via le gestionnaire
          suivant : <a href="javascript:Didomi.preferences.show()">Gestionnaire des Cookies</a>. Toute désactivation
          entraine le refus par l’Utilisateur du dépôt du Cookie en question ;
        </li>
        <li>En poursuivant sa navigation, c'est-à-dire lorsque l'Utilisateur a cliqué sur un élément du Site (image,
          lien, bouton « rechercher » etc.) ou s'est rendu sur une autre page du Site, l’Utilisateur valide
          l’implantation des Cookies, selon le paramétrage par défaut proposé.
        </li>
      </ul>
      <br/><br/>
      Si l'Utilisateur active l'enregistrement de Cookies dans son terminal, les Cookies intégrés dans les pages et
      contenus qu'il a consultés pourront être stockés temporairement dans un espace dédié de son terminal. Ils y seront
      lisibles uniquement par leur émetteur. Le consentement donné par l'Utilisateur n'est valable que pour une durée de
      treize (13) mois à compter du premier dépôt dans l'équipement du terminal de l'Utilisateur, faisant suite à
      l'expression du consentement de ce dernier.
      <br/><br/>
      Si l'Utilisateur désactive l'enregistrement de Cookies dans son terminal ou son navigateur, ou s'il supprime ceux
      qui y sont enregistrés, l'Utilisateur est informé que sa navigation et son expérience sur le Site pourraient être
      différentes des autres Utilisateurs ayant activé les Cookies (contenus non personnalisés). Cela pourrait également
      être le cas lorsque l’Editeur ou l’un de nos prestataires ne pourrait pas reconnaître, à des fins de compatibilité
      technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d'affichage ou le pays
      depuis lequel le terminal semble connecté à Internet. Le cas échéant, l’Editeur décline toute responsabilité pour
      les conséquences liées au fonctionnement dégradé du Site résultant du refus de Cookies par l'Utilisateur.
      <br/><br/>
      <h3>Pour en savoir plus</h3>
      L’Utilisateur peut se rendre sur le site internet de la CNIL afin de recueillir des informations concernant les
      cookies via notamment la page suivante :<br/>
      <a href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs" target="_blank">https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs</a>.
    </div>
  </div>
</template>

<script>
export default {
  name: 'donnees'
}
</script>