<template>
  <div id="contact">
    <div class="header">
      <h1>Contact</h1>
      <img :src="$root.publicPath + 'img/picture/contact.jpg'"/>
    </div>

    <div class="grid-noGutter">
      <div class="col-4 md-hidden"></div>
      <div class="col-8_md-12">
        <div class="content">
          <div class="inner">
            <p>Vous êtes un particulier, un porteur de projet associatif, une fondation, un acteur de l’intérêt général,
              un partenaire de demain...</p>
            <br/>
            <h4>Pour contacter la Fondation d’entreprise Up, écrivez directement à <a
                href="mailto:contact@fondation-up.org">contact@fondation-up.org</a> ou remplissez le formulaire
              ci-dessous :</h4>

            <h5 class="orange success" v-if="success"><br/>Nos équipes ont bien réceptionné votre message. <br/>Nous
              allons vous recontacter dans les prochains jours.</h5>

            <form method="POST" id="form-contact" v-if="!success" @submit.prevent="subscribe">
              <input type="hidden" name="token" :value="csrf_token"/>
              <div class="loader" v-if="loading"></div>

              <!--
              <div class="errors" v-if="errors.length > 0">
                <div v-for="(error, index) in errors">{{ errors }} / {{ index }}</div>
              </div>
              -->

              <div class="errors" v-if="errors.length > 0 || error_email">
                <p v-if="errors.length > 0">Merci de remplir tous les champs obligatoires</p>
                <p v-if="error_email">L'adresse email n'est pas valide</p>
              </div>

              <div class="grid-noBottom grid-fields">
                <div class="col-12">
                  <div class="field" v-bind:class="{ 'error' : errors.indexOf('civilite_id') > -1 }">
                    <label for="civilite" class="label">Civilité </label>
                    <div class="radios">
                      <label for="civilite1"><input type="radio" name="civilite_id" id="civilite1" value="1"
                                                    v-model="fields.civilite_id"/>M.</label>
                      <label for="civilite2"><input type="radio" name="civilite_id" id="civilite2" value="2"
                                                    v-model="fields.civilite_id"/>Mme</label>
                      <label for="civilite3"><input type="radio" name="civilite_id" id="civilite3" value="3"
                                                    v-model="fields.civilite_id"/>Mlle</label>
                    </div>
                  </div>
                </div>
                <div class="col-6_xs-12">
                  <div class="field" v-bind:class="{ error : errors.indexOf('nom') > -1 }">
                    <input type="text" name="nom" value="" v-model="fields.nom" class="text"/>
                    <label for="nom" v-if="fields.nom == ''" class="label abs">Nom </label>
                  </div>
                </div>
                <div class="col-6_xs-12">
                  <div class="field" v-bind:class="{ error : errors.indexOf('prenom') > -1 }">
                    <input type="text" name="prenom" value="" v-model="fields.prenom" class="text"/>
                    <label for="prenom" v-if="fields.prenom == ''" class="label abs">Prénom</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="field" v-bind:class="{ error : ( errors.indexOf('email') > -1 || error_email ) }">
                    <input type="text" name="email" value="" v-model="fields.email" class="text"/>
                    <!--  @change="validateEmail()" @keyup="validateEmail()" -->
                    <label for="email" v-if="fields.email == ''" class="label abs">Email</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="field" v-bind:class="{ error : errors.indexOf('objet') > -1 }">
                    <input type="text" name="objet" value="" v-model="fields.objet" class="text"/>
                    <label for="objet" v-if="fields.objet == ''" class="label abs">Objet de votre message</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="field" v-bind:class="{error : errors.indexOf('message') > -1 }">
                    <textarea type="text" name="message" value="" v-model="fields.message" class="text"/></textarea>
                    <label for="objet" v-if="fields.message == ''" class="label abs msg">Votre message</label>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" name="send">Envoyer
                    <svg>
                      <use xlink:href="#check"></use>
                    </svg>
                  </button>
                </div>
              </div>

              <p class="rgpd">
                Les données à caractère personnel qui vous sont demandées via ce formulaire de contact, font l’objet
                d’un traitement automatisé par la Fondation d'entreprise Up, et nous sont indispensables pour
                communiquer avec vous et pour vous envoyer une réponse à vos questions et aux besoins que vous exprimez.
                Ce traitement repose sur la relation précontractuelle.<br/><br/>
                L’ensemble de vos données personnelles sont confidentielles. Elles sont destinées aux services internes
                de la Fondation d'entreprise Up et ne sont rendues accessibles qu’aux services et personnels habilités à
                en prendre connaissance.<br/><br/>
                Vos données sont conservées jusqu’à 3 ans à compter de votre demande.<br/><br/>
                Conformément au Règlement UE 2016/79 (RGPD), vous disposez d’un droit d’accès, de rectification,
                d’opposition, d’effacement, de limitation au traitement et d’un droit à la portabilité des données vous
                concernant, en contactant par mail le délégué à la protection des données à <a
                  href="mailto:rgpd.cooperative@up.coop">rgpd.cooperative@up.coop</a> ou par courrier à l’adresse :
                Fondation d'entreprise Up - 27/29 avenue des Louvresses - 92230 Gennevilliers.<br/><br/>
                En cas de non-réponse à votre exercice de droits, vous pouvez porter votre réclamation auprès de la
                CNIL.<br/><br/>
                Pour avoir plus d’informations concernant ce traitement, vous pouvez consulter notre <a
                  href="https://fondation-up.org/pdf/politique-de%20protection-des-donnees_Fondation_Up_2019.pdf"
                  target="_blank">Politique de Protection des Données Personnelles</a>.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact',

  data() {
    return {
      csrf_token: '',
      fields: {
        civilite_id: 1,
        nom: '',
        prenom: '',
        email: '',
        objet: '',
        message: ''
      },
      errors: [],
      error_email: false,
      loading: false,
      success: false
    }
  },

  mounted() {
    let token = document.head.querySelector('meta[name="csrf-token"]');
    if (token) {
      this.csrf_token = token.content;
    }
  },

  methods: {
    subscribe() {
      this.loading = true;
      this.errors = [];
      this.error_email = false;

      if (!this.fields.civilite_id) {
        this.errors.push('civilite_id')
      }
      if (!this.fields.nom) {
        this.errors.push('nom');
      }
      if (!this.fields.prenom) {
        this.errors.push('prenom');
      }
      if (!this.fields.email) {
        this.errors.push('email');
      } else {
        /*if( !this.validateEmail(this.fields.email) ){
          this.error_email = true;
        }*/
      }
      if (!this.fields.objet) {
        this.errors.push('objet');
      }
      if (!this.fields.message) {
        this.errors.push('message');
      }

      if (this.errors.length == 0 && !this.error_email) {

        let _this = this;

        const params = new URLSearchParams();
        params.append('civilite_id', this.fields.civilite_id);
        params.append('nom', this.fields.nom);
        params.append('prenom', this.fields.prenom);
        params.append('email', this.fields.email);
        params.append('objet', this.fields.objet);
        params.append('message', this.fields.message);
        params.append('token', this.csrf_token);

        axios({
          method: 'post',
          url: process.env.VUE_APP_BASE_URI + 'api/contact',
          data: params
        })
            /*
            axios.post('/api/contact', {
              civilite_id: this.fields.civilite_id,
              nom: this.fields.nom,
              prenom: this.fields.prenom,
              email: this.fields.email,
              objet: this.fields.objet,
              message: this.fields.message
            })
            */
            .then(res => {
              // alert(res);
              _this.errors = [];
              _this.error_email = false;
              console.log(res);
              console.log('------------');
              console.log(res.data);
              setTimeout(() => {

                _this.loading = false;
                _this.success = true; // res.data.statut;
                //alert('ok');
                //alert(_this.success);
              }, 100);
            })
      }
    }
  }
}

</script>


<style scoped lang="scss">
#contact {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

#contact div.header {
  max-width: 1200px;
  margin: 100px auto 0;
}

#contact div.header h1 {
  text-align: right;
}

#contact div.header img {
  position: relative;
  top: -50px;
}

#contact div.content {
  position: relative;
  top: -250px;
  padding: 100px 100px 0;
  background: #fff;
  border-top: 1px solid #f59100;
}

#contact div.inner {
  max-width: 640px;
}

#contact form {
  margin-top: 60px;
}

#contact div.field {
  position: relative;
  margin-bottom: 30px;
}

#contact label {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

#contact label.abs {
  position: absolute;
  top: 11px;
  left: 0px;
  pointer-events: none;
  z-index: 9;
}

#contact label.label:after {
  position: absolute;
  content: '';
  width: 4px;
  height: 4px;
  top: 7px;
  right: -10px;
  background: #ff0000;
  border-radius: 50%;
}

#contact label.label.msg {
  left: 10px;
}

#contact div.radios {
  display: inline-block;
  margin-left: 40px;
}

#contact div.radios > label {
  margin-right: 40px;
}

#contact div.radios input {
  margin-right: 5px;
}

#contact button {
  display: inline-block;
  float: right;
  background: #f59100;
  border: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  padding: 15px 20px;
  cursor: pointer;
}

#contact button svg {
  position: relative;
  top: 2px;
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-left: 5px;
}

#contact button:hover {
  background: #4F5D5B;
}

#contact button:hover svg {
}

input.text, select, textarea {
  display: block;
  width: 100%;
  border: solid #333;
  border-width: 0 0 1px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 600;
}

textarea {
  border-width: 1px;
  height: 140px;
  margin-top: 10px;
  padding: 10px;
}

/*
#contact label.error				{display:none !important;}
#contact .error						{border-color:#cc3300 !important;}
#contact .error:after				{display:none !important;}
*/
#contact div.error label.label {
  color: #cc3300;
}

#contact div.errors {
  background: #efefef;
  margin: 0 0 20px;
  padding: 10px;
}

#contact div.errors p {
  font-size: 13px;
  font-weight: 600;
  color: #cc3300;
  margin: 0 !important;
}

#contact h2.success {
  padding: 60px 0;
}

p.rgpd {
  padding-top: 70px;
  font-size: 13px;
  line-height: 18px;
}

p.rgpd a {
  color: #000;
  text-decoration: underline;
}

@media (max-width: 64em) {
  #contact div.header {
    margin-top: 50px;
  }
  #contact div.header img {
    top: auto;
    margin: 10px 0 30px;
  }
  #contact div.content {
    top: auto;
    padding: 20px 0 0;
    max-width: 640px;
    margin: 0 auto 100px;
  }
  #contact div.radios {
    display: block;
    margin: 20px 0 0;
  }
  #contact div.grid-fielsds {
    padding: 0;
  }
}
</style>