<template>
  <div id="domaines">
    <div class="header">
      <h1><span>Nos </span>actions</h1>
    </div>
    <div class="intro grid-noGutter mb100">
      <div class="col-5_sm-12">
        <div class="txt1">
          <h3>Impulser le pouvoir d’agir ensemble aux personnes en situation d’isolement ou de dépendance, toutes
            générations confondues, en leur permettant d’accéder à l’essentiel pour s’épanouir plus librement.</h3>
        </div>
      </div>
      <div class="col-1_sm-12"></div>
      <div class="col-6_sm-12">
        <div class="txt1">
          <p>Pour contribuer à l'épanouissement collectif en donnant à chacun le pouvoir d'agir d'une manière librement
            choisie, 4 domaines d’actions essentiels viennent nourrir cette ambition.</p>
          <a href="#filters" class="link" v-on:click.prevent="scrollToList(500)">Voir les projets soutenus par la
            Fondation UP</a>
          <!--<router-link to="/depot-projet" class="btn">Déposer un projet</router-link>-->
        </div>
      </div>
    </div>

    <div id="filters">
      <h3 class="center">Parcourir nos actions</h3>
      <div class="grid grid-noGutter">
        <div class="col-2_sm-3" v-for="(rubrique, index) in rubriques">
          <label
              :class="{ rub1 : rubrique.id==1, rub2 : rubrique.id==2, rub3 : rubrique.id==3, rub4 : rubrique.id==4, on : selected_rub.indexOf(rubrique.id) >= 0 }"
              v-on:click.prevent="toggleRub(rubrique.id)">
            <svg>
              <use :xlink:href="'#' + rubrique.code"></use>
            </svg>
            <p>{{ rubrique.titre }}</p>
            <div class="check"></div>
          </label>
        </div>
        <div class="col-2_sm-6 c_c1">
          <label class="select xs-hidden">
            <svg>
              <use xlink:href="#world"></use>
            </svg>
            <p><span v-html="country_name"></span></p>
            <div>
              <ul id="countries_list">
                <li>
                  <a href="#" :class="{ on : !selected_country }" v-on:click.prevent="selected_country = false">Tous les
                    pays</a>
                </li>
                <li v-for="(country, index) in countries" :key="country.id">
                  <a :href="'#' + country.id" :class="{ on : selected_country == country.id }"
                     v-on:click.prevent="selected_country = country.id">{{ country.nom }}</a>
                </li>
              </ul>
            </div>
          </label>
          <select id="countries_select" v-model="selected_country" class="xs-visible">
            <option value="0">Tous les pays</option>
            <option v-for="(country, index) in countries" :value="country.id" :key="'select' + country.id">
              {{ country.nom }}
            </option>
          </select>
        </div>
        <div class="col-2_sm-6 c_c2">
          <label class="select xs-hidden">
            <svg>
              <use xlink:href="#cycle"></use>
            </svg>
            <p><span v-html="cycle_name"></span></p>
            <div>
              <ul id="cycles_list">
                <li>
                  <a href="#" :class="{ on : !selected_cycle }" v-on:click.prevent="selected_cycle = false">Tous les
                    cycles</a>
                </li>
                <li v-for="(cycle, index) in cycles" :key="cycle.id">
                  <a :href="'#' + cycle.id" :class="{ on : selected_cycle == cycle.id }"
                     v-on:click.prevent="selected_cycle = cycle.id">{{ cycle.nom }}</a>
                </li>
              </ul>
            </div>
          </label>
          <select id="cycles_select" v-model="selected_cycle" class="xs-visible">
            <option value="0">Tous les cycles</option>
            <option v-for="(cycle, index) in cycles" :value="cycle.id" :key="'select' + cycle.id">{{
                cycle.nom
              }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div id="actions" class="mb100">
      <div class="no-result grid-noGutter-middle-center">
        <h5>Aucun résultat</h5>
      </div>

      <div id="actions_list" class="list grid-center-equalHeight">
        <router-link :to="'/action/'+item.slug" class="col-4_md-6_sm-12 item" v-for="(item, index) in actions"
                     :key="item.id"
                     v-show="selected_rub.indexOf(item.rubrique_id) >= 0 && ( !selected_country || selected_country == item.country_id ) && ( !selected_cycle || selected_cycle == item.cycle_id )">
          <div class="inner">
            <div class="txt">
              <div class="cycle" :class="{ current : item.cycle_current }">{{ item.cycle_nom }}</div>
              <svg :style="{'fill' : item.color}">
                <use :xlink:href="'#'+item.rubrique_code"></use>
              </svg>
              <h6>{{ item.structure }}</h6>
              <h4>{{ item.titre }}</h4>
              <p v-html="item.chapo"></p>
            </div>

            <div class="pict cover" :style="'background-image:url(' + item.visuel + ')'" v-if="item.visuel">
              <div class="more">
                <svg>
                  <use xlink:href="#plus"></use>
                </svg>
              </div>
            </div>

            <!--
            <div class="pict cover" style="background-image:url('https://picsum.photos/1000/510')" v-if="!item.visuel">
              <div class="more"><svg><use xlink:href="#plus"></use></svg></div>
            </div>
            -->
          </div>
        </router-link>
      </div>
      <div class="loading">loading</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'actions',

  data() {
    return {
      rubriques: {},
      rubriques_names: ['alimentation', 'logement', 'education', 'sante'],
      selected_rub: [1, 2, 3, 4],
      actions: {},
      countries: {},
      selected_country: 0,
      country_name: 'sélectionner <br />un pays',
      cycles: {},
      selected_cycle: 0,
      cycle_name: 'sélectionner <br />un cycle'
    }
  },

  watch: {
    selected_country: function (id) {
      let list = document.querySelectorAll('#countries_list a');
      let found = false;
      let country = [...list].filter(c => {
        if (!found && c.href.includes("#" + id)) {
          this.country_name = c.innerHTML;
          found = true;
        }
      });
      if (!found) {
        this.country_name = list[0].innerHTML;
      }
    },
    selected_cycle: function (id) {
      let list = document.querySelectorAll('#cycles_list a');
      let found = false;
      let cycle = [...list].filter(c => {
        if (!found && c.href.includes("#" + id)) {
          this.cycle_name = c.innerHTML;
          found = true;
        }
      });
      if (!found) {
        this.cycle_name = list[0].innerHTML;
      }
    }
  },

  mounted() {
    this.fetch();

    if (window.location.hash.replace('#', '') != '') {
      this.scrollToList(0);
    }
  },

  methods: {
    async fetch() {

      await axios.get(this.$root.publicURI + 'api/getRubriques', {})
          .then(res => {
            this.rubriques = res.data;
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getCountries/1', {})
          .then(res => {
            this.countries = res.data;
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getCycles', {})
          .then(res => {
            this.cycles = res.data;
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getActions', {})
          .then(res => {
            this.actions = res.data;

            let c = window.location.hash.replace('#', '');
            if (c) {
              this.selected_country = c;
              this.country_name = c;
            }

            if (this.$route.params.slug) {
              this.selected_rub = [this.rubriques_names.indexOf(this.$route.params.slug) + 1];
            }

          })
          .catch(function (error) {
            //alert(error);
          });
    },

    toggleRub(id) {
      if (this.selected_rub.indexOf(id) >= 0) {
        this.selected_rub.splice(this.selected_rub.indexOf(id), 1);
      } else {
        this.selected_rub.push(id);
      }
    },

    scrollToList(duration) {
      $('html, body').animate({'scrollTop': $('#filters').offset().top - 30}, duration);
    }
  }
}
</script>


<style scoped lang="scss">
h1 {
  font-size: calc(50px + (120 - 50) * ((100vw - 300px) / (1024 - 300))) !important;
  line-height: calc(48px + (120 - 48) * ((100vw - 300px) / (1024 - 300))) !important;
}

#domaines div.header {
  margin: 100px 0 30px;
}

#domaines div.intro {
  max-width: 1280px;
  margin: 80px auto 0;
}

#domaines div.intro h2 {
  position: relative;
  top: -60px;
  margin: 0;
}

#domaines div.intro p {
  margin-bottom: 40px;
}

#domaines div.intro a {
  margin-right: 25px;
}

#filters {
  position: relative;
  z-index: 3;
  margin-bottom: 50px;
}

#filters div.grid {
  margin: 50px auto 0;
}

#filters h3 {
  font-weight: 900;
}

#filters label {
  position: relative;
  display: block;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

#filters label svg {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 10px;
}

#filters p {
  font-weight: 800;
}

#filters label.rub1 svg {
  fill: #8cd096;
}

#filters label.rub2 svg {
  fill: #bcc5d9;
}

#filters label.rub3 svg {
  fill: #a1c5c3;
}

#filters label.rub4 svg {
  fill: #c7e9f9;
}

#filters label.rub1 p {
  color: #8cd096;
}

#filters label.rub2 p {
  color: #bcc5d9;
}

#filters label.rub3 p {
  color: #a1c5c3;
}

#filters label.rub4 p {
  color: #c7e9f9;
}

#filters div.check {
  display: block;
  width: 36px;
  height: 36px;
  margin: 10px auto;
  background: url('../img/picto/check.gif') no-repeat 0 0;
}

#filters label.on div.check {
  background-position: 0 -36px;
}

#filters label.select {
  position: relative;
}

#filters label.select p {
  background: url('../img/picto/arrow-select.png') no-repeat bottom center;
  font-size: 13px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000;
  padding-bottom: 20px;
}

#filters label.select div {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 20px;
  display: none;
}

#filters label.select:hover div {
  display: block;
}

#filters label.select ul {
  list-style: none;
  text-align: left;
  background: #f59100;
  padding: 15px 20px;
}

#filters label.select ul li a {
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  white-space: nowrap;
}

#filters label.select ul li a:hover {
  color: #fff !important;
  text-decoration: underline;
}

#filters label.select ul li a.on {
  color: #333 !important;
  font-weight: 800;
  text-decoration: none;
}

select {
  display: block;
  width: 100%;
  padding: 8px 10px;
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: 800;
}

option {
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: 800 !important;
}

#actions {
  position: relative;
  min-height: 570px;
}

#actions div.no-result {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  height: 540px;
  text-align: center;
  border: 2px solid #f8f8f8;
}

#actions_list {
  position: relative;
  z-index: 1;
  background: #fff;
}

#actions a.item div.inner {
  position: relative;
}

#actions a.item div.txt {
  text-align: center;
  padding: 60px 60px 260px;
  background: #f8f8f8;
  border: 2px solid #f8f8f8;
}

#actions a.item div.txt svg {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 20px;
}

#actions a.item div.txt h4 {
  color: #2f2f2f;
}

#actions a.item div.txt h6 {
  color: #f59100;
}

#actions a.item div.txt p {
  color: #2f2f2f;
}

#actions a.item div.txt div.cycle {
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -50px;
  background: #000;
  padding: 8px 20px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
}

#actions a.item div.txt div.cycle.current {
  background: #f59100;
}

#actions a.item div.pict {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #2f2f2f;
}

#actions a.item div.pict div.more {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 15px;
}

#actions a.item div.pict svg {
  width: 20px;
  height: 20px;
}

#actions a.item:hover div.txt {
  background: #fff;
}

#actions a.item:hover div.pict div.more {
  background: #f59100;
}

#actions a.item:hover div.pict svg {
  fill: #fff;
}

#actions div.loading {
  display: none;
}


@media (max-width: 80em) {
  #actions a.item div.txt {
    padding: 60px 30px 260px;
  }
}

@media (max-width: 64em) {
  #domaines div.header {
    margin-top: 50px;
  }
  #actions_list {
    margin: 0;
  }
  #actions a.item div.txt {
    padding: 60px 25px 230px;
  }
  #actions a.item div.pict {
    height: 180px;
  }
}

@media (max-width: 48em) {
  #domaines div.intro {
    margin-top: 0;
  }
  #domaines div.intro a {
    margin: 0 0 25px;
  }
  #filters {
    margin-bottom_: 0;
  }
  #filters label.select br {
    display: none;
  }
  #filters select {
    width: calc(100% - 10px);
    font-size: 12px;
  }
  #actions_list {
    margin: 0;
  }
  #actions a.item {
    padding: 0;
    margin-bottom: 50px;
  }
  #actions a.item div.txt {
    padding: 60px 15px 200px;
  }
  #actions a.item div.pict {
    height: 150px;
  }
}
</style>