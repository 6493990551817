<template>
  <div id="rapport">

    <div id="ra_hero" class="cover" :style="'background-image:url(' + $root.publicPath + rapport.hero + ')'">
      <div class="wrap">
        <img :src="$root.publicPath + 'img/ra/fondation-up.png'"/>
        <h1 v-html="rapport.intro"></h1>
      </div>
    </div>


    <div id="ra_ambition" class="section" v-if="rapport.ambition_titre || rapport.ambition_txt || rapport.ambition_img">
      <div class="wrap">
        <div class="grid-noBottom">
          <div class="col-6_lg-7_md-8_sm-12">
            <div class="txt">
              <h2 class="orange line left" v-html="rapport.ambition_titre"></h2>
              <p v-html="rapport.ambition_txt"></p>
            </div>
          </div>
          <div class="col-6_lg-5_md-4_sm-12">
            <img :src="$root.publicPath + rapport.ambition_img" data-speed="-0.1" data-type="background"/>
          </div>
        </div>
      </div>
    </div>

    <div id="ra_dirigeant" class="section" v-if="rapport.dirigeant_titre || rapport.dirigeant_txt">
      <div class="wrap">
        <h2 class="orange line right" v-html="rapport.dirigeant_titre" data-aos="fade-up"></h2>
        <div class="grid" data-aos="fade-up">
          <div class="col-5_sm-12">
            <div class="picts">
              <div class="item item1">
                <img :src="$root.publicPath + rapport.dirigeant1_img" data-speed="-0.2" data-type="background"/>
                <p class="author"><strong>{{ rapport.dirigeant1_nom }}</strong><br/><em>{{
                    rapport.dirigeant1_fonction
                  }}</em><span></span></p>
              </div>
              <div class="item item2">
                <img :src="$root.publicPath + rapport.dirigeant2_img" data-speed="-0.3" data-type="background"/>
                <p class="author"><strong>{{ rapport.dirigeant2_nom }}</strong><br/><em>{{
                    rapport.dirigeant2_fonction
                  }}</em><span></span></p>
              </div>
            </div>
          </div>
          <div class="col-7_sm-12">
            <div class="txt">
              <img :src="$root.publicPath + 'img/ra/big-quote.png'"/>
              <p v-html="rapport.dirigeant_txt"></p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="ra_engagement" class="section" v-if="rapport.engagement_titre || rapport.engagement_txt">
      <div class="wrap">
        <h2 class="orange line left" v-html="rapport.engagement_titre" data-aos="fade-up"></h2>
        <div class="grid-noBottom">
          <div class="col-7_md-12 chiffres">
            <div class="grid-noGutter">
              <div class="col-6_sm-12">
                <p v-for="(item, index) in chiffres_col1"><strong class="counter" v-html="item.value"></strong>
                  {{ item.label }}</p>
              </div>
              <div class="col-6_sm-12">
                <p v-for="(item, index) in chiffres_col2"><strong class="counter" v-html="item.value"></strong>
                  {{ item.label }}</p>
              </div>
            </div>
          </div>
          <div class="col-5_md-12">
            <div class="txt">
              <div class="side left"></div>
              <div class="side right"></div>
              <p v-html="rapport.engagement_txt"></p>
            </div>
          </div>
        </div>
        <img :src="$root.publicPath + 'img/ra/world.png'"/>
      </div>
    </div>


    <div id="ra_actions" class="section"><!--  v-if="rubriques.length && actions.length" -->
      <div class="wrap">
        <h2 class="orange line right" data-aos="fade-up">
          Découvrez <br/>toutes les actions
        </h2>

        <div id="ra_rubs" class="wrap_list">
          <div class="arrows">
            <a href="#" class="arrow prev" id="rubs_prev">
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="circle">
                <circle cx="50" cy="50" r="46"/>
              </svg>
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="circle2">
                <circle cx="50" cy="50" r="46"/>
              </svg>
              <svg viewBox="0 0 27.951 17.841" class="icon">
                <g fill="none" stroke="#1d1d1d" stroke-linecap="round" stroke-width="3">
                  <path d="M26.451 8.921H4.339"/>
                  <path stroke-linejoin="round" d="M8.937 15.72L2.12 8.903l6.782-6.781"/>
                </g>
              </svg>
            </a>
            <a href="#" class="arrow next" id="rubs_next">
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="circle">
                <circle cx="50" cy="50" r="46"/>
              </svg>
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="circle2">
                <circle cx="50" cy="50" r="46"/>
              </svg>
              <svg viewBox="0 0 27.951 17.841" class="icon">
                <g fill="none" stroke="#1d1d1d" stroke-linecap="round" stroke-width="3">
                  <path d="M1.5 8.92h22.112"/>
                  <path stroke-linejoin="round" d="M19.014 2.121l6.817 6.817-6.782 6.781"/>
                </g>
              </svg>
            </a>
          </div>

          <div id="rubs_splide" class="list splide">
            <div class="splide__track">
              <ul class="splide__list">
                <li class="splide__slide" v-for="(rubrique, index) in rubriques" :key="'rub_'+ index"
                    v-if="nb_projet[rubriques_names[rubrique.id-1]] > 0">
                  <div class="item"
                       :class="{ rub1 : rubrique.id==1, rub2 : rubrique.id==2, rub3 : rubrique.id==3, rub4 : rubrique.id==4, on : selected_rub.indexOf(rubrique.id) >= 0 }"
                       v-on:click.prevent="toggleRub(rubrique.id)">
                    <svg>
                      <use :xlink:href="'#' + rubrique.code"></use>
                    </svg>
                    <p>{{ rubrique.nom }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap">
        <transition name="recaps" class="recaps list grid-center-equalHeight">
          <div class="recap" :class="'rub' + rubrique.id" :style="{ 'background-color' : color_rub[rubrique.id-1] }"
               v-for="(rubrique, index) in rubriques" v-if="selected_rub.indexOf(rubrique.id) >= 0">
            <div class="grid grid-noGutter">
              <div class="col-5_md-5_sm-12">
                <div class="grid grid-noGutter">
                  <div class="col-6_md-12">
                    <p><strong>{{ nb_projet[rubriques_names[rubrique.id - 1]] }}
                      PROJET{{ (parseInt(nb_projet[rubriques_names[rubrique.id - 1]]) > 1) ? 'S' : '' }} </strong><br/>soutenu{{
                        (nb_projet[rubriques_names[rubrique.id - 1]] > 1) ? 's' : ''
                      }}</p>
                  </div>
                  <div class="col-6_md-12">
                    <p><strong>{{
                        aides[rubriques_names[rubrique.id - 1]]
                      }}€ </strong><br/>engagé{{ (parseInt(aides[rubriques_names[rubrique.id - 1]]) > 1) ? 's' : '' }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-1_md-1_sm-12">
                <div class="sep"></div>
              </div>
              <div class="col-6_md-6_sm-12">
                <div class="repartition">
                  <p>Répartition de nos projets par domaine d’action</p>
                  <div><strong>{{ pourcentage[rubriques_names[rubrique.id - 1]] }}%</strong></div>
                  <div class="bar">
                    <div :style="{'width' : pourcentage[rubriques_names[rubrique.id-1]] + '%' }"></div>
                  </div>
                  <div><strong>{{ rubrique.nom }}</strong></div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div id="ra_actlist">
          <div class="no-result grid-noGutter-middle-center" v-if="selected_rub.length == 0">
            <p>Aucun résultat</p>
          </div>
          <transition-group name="actions" class="actions list grid-center-equalHeight">
            <router-link :to="'/action/'+item.slug" target="_blank" class="col-4_sm-6_xs-12 item"
                         :class="'rub'+ item.rubrique_id" v-for="(item, index) in actions" :key="item.id"
                         v-if="selected_rub.indexOf(item.rubrique_id) >= 0">
              <div class="inner" data-aos_="fade-right">
                <h6>{{ item.structure }}</h6>
                <p>{{ item.country_nom }}</p>
                <h4>{{ item.titre }}</h4>
              </div>
            </router-link>
          </transition-group>
          <div class="loading">loading</div>
        </div>
        <div class="more">
          <router-link to="/actions" target="_blank" class="arrow2 orange">
            <svg viewBox="0 0 27.951 17.841">
              <g fill="none" stroke-linecap="round" stroke-width="3">
                <path d="M1.5 8.92h22.112"/>
                <path stroke-linejoin="round" d="M19.014 2.121l6.817 6.817-6.782 6.781"/>
              </g>
            </svg>
            Voir tous les projets soutenus
          </router-link>
        </div>
      </div>
    </div>


    <div id="ra_instances" class="section" v-if="rapport.instances_titre || rapport.instances_txt">
      <div class="wrap">
        <h2 class="orange line left" v-html="rapport.instances_titre" data-aos="fade-up"></h2>

        <div class="grid">
          <div class="col-7_lg-12 col_picts">

            <div id="instances_picts" class="picts">
              <div v-for="(item, index) in communaute" class="pict" :class="'item' + (index+1)"
                   :key="'communaute_' + index" v-if="index < 4">
                <div class="photo cover one" :class="{ on : communaute_step == 1 }"
                     :style="{ backgroundImage: 'url(' + $root.publicPath + 'upload/member/' + communaute[index] + '.jpg)', 'transitionDelay' : (index%4==0) ? '.5s' : ( (index%2==0) ? '0s' : '.3s' ) }">
                  <p style="display:inline-block;font-size:12px;background:#fff;display_:none;">ONE =>
                    {{ communaute[index] }} <br/>TWO => {{ communaute_new[index] }}</p></div>
                <div class="photo cover two" :class="{ on : communaute_step == 2 }"
                     :style="{ backgroundImage: 'url(' + $root.publicPath + 'upload/member/' + communaute_new[index] + '.jpg)', 'transitionDelay' : (index%4==0) ? '.5s' : ( (index%2==0) ? '0s' : '.3s' ) }">
                  <p style="display:inline-block;font-size:12px;font-weight:800;background:#fff;display_:none;">ONE =>
                    {{ communaute[index] }} <br/>TWO => {{ communaute_new[index] }}</p></div>
              </div>
            </div>
          </div>
          <div class="col-5_lg-12">
            <div class="txt" v-html="rapport.instances_txt"></div>
          </div>
        </div>
      </div>
    </div>


    <div id="ra_evaluation" class="section" v-if="rapport.evaluations && rapport.evaluations.length">
      <div class="wrap">
        <h2 class="orange line left" data-aos="fade-up">L’évaluation</h2>

        <div class="arrows">
          <a href="#" class="prev" id="evaluation_prev">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="circle">
              <circle cx="50" cy="50" r="46"/>
            </svg>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="circle2">
              <circle cx="50" cy="50" r="46"/>
            </svg>
            <svg viewBox="0 0 27.951 17.841" class="icon">
              <g fill="none" stroke="#1d1d1d" stroke-linecap="round" stroke-width="3">
                <path d="M26.451 8.921H4.339"/>
                <path stroke-linejoin="round" d="M8.937 15.72L2.12 8.903l6.782-6.781"/>
              </g>
            </svg>
          </a>
          <a href="#" class="next" id="evaluation_next">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="circle">
              <circle cx="50" cy="50" r="46"/>
            </svg>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="circle2">
              <circle cx="50" cy="50" r="46"/>
            </svg>
            <svg viewBox="0 0 27.951 17.841" class="icon">
              <g fill="none" stroke="#1d1d1d" stroke-linecap="round" stroke-width="3">
                <path d="M1.5 8.92h22.112"/>
                <path stroke-linejoin="round" d="M19.014 2.121l6.817 6.817-6.782 6.781"/>
              </g>
            </svg>
          </a>
        </div>

        <div id="evaluations_splide" class="splide">
          <div class="splide__track">
            <ul class="splide__list">
              <li class="splide__slide" v-for="(item, index) in rapport.evaluations" :key="'evaluation_'+index">
                <div class="grid-noGutter-noBottom">
                  <div class="col-4_md-12">
                    <div class="intro" v-html="item.titre"></div>
                  </div>
                  <div class="col-8_lg-7_md-12">
                    <div class="txt" v-html="item.txt"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>


    <div id="ra_members" class="section">
      <div class="wrap">
        <div class="groups grid-spaceBetween">
          <a href="#" v-on:click.prevent="loadMembersList(1)" :class="{ on : this.current_list != 4 }">La
            gouvernance</a>
          <a href="#" v-on:click.prevent="loadMembersList(4)" :class="{ on : this.current_list == 4 }">L’équipe de la
            Fondation</a>
        </div>
        <div class="cats" v-if="current_list != 4">
          <a href="#" v-on:click.prevent="loadMembersList(1)" :class="{ on : this.current_list == 1 }">Représentants du
            Fondateur</a>
          <a href="#" v-on:click.prevent="loadMembersList(2)" :class="{ on : this.current_list == 2 }">Représentants du
            personnel issus du Fondateur</a>
          <a href="#" v-on:click.prevent="loadMembersList(3)" :class="{ on : this.current_list == 3 }">Personnalités
            qualifiées dans les domaines d’expertise de la Fondation</a>
        </div>


        <div id="members1" class="list grid-center-noBottom-noGutter" :class="{ on : this.current_list == 1 }"
             v-if="current_list == 1">
          <div class="item col-3_lg-3_md-4_sm-6_xs-12" v-for="(item, index) in members1" :key="'members1_'+index"
               v-on:click="toggleMember()">
            <!-- v-on:click="e => e.target.parentNode.parentNode.classList.toggle('on')" -->
            <div class="inner">
              <div class="pict cover" :style="'background-image:url(' + $root.publicPath + item.photo + ')'"></div>
              <div class="card">
                <div><strong>{{ item.nom }}</strong><em>{{ item.fonction }}</em>
                  <p v-html="item.txt" v-if="item.txt"></p></div>
              </div>
            </div>
          </div>
        </div>

        <div id="members2" class="list grid-center-noBottom-noGutter" :class="{ on : this.current_list == 2 }"
             v-if="current_list == 2">
          <div class="item col-3_lg-3_md-4_sm-6_xs-12" v-for="(item, index) in members2" :key="'members2_'+index"
               v-on:click="toggleMember()">
            <div class="inner">
              <div class="pict cover" :style="'background-image:url(' + $root.publicPath + item.photo + ')'"></div>
              <div class="card">
                <div><strong>{{ item.nom }}</strong><em>{{ item.fonction }}</em>
                  <p v-html="item.txt" v-if="item.txt"></p></div>
              </div>
            </div>
          </div>
        </div>

        <div id="members3" class="list grid-center-noBottom-noGutter" :class="{ on : this.current_list == 3 }"
             v-if="current_list == 3">
          <div class="item col-3_lg-3_md-4_sm-6_xs-12" v-for="(item, index) in members3" :key="'members3_'+index"
               v-on:click="toggleMember()">
            <div class="inner">
              <div class="pict cover" :style="'background-image:url(' + $root.publicPath + item.photo + ')'"></div>
              <div class="card">
                <div><strong>{{ item.nom }}</strong><em>{{ item.fonction }}</em>
                  <p v-html="item.txt" v-if="item.txt"></p></div>
              </div>
            </div>
          </div>
        </div>

        <div id="members4" class="list grid-center-noBottom-noGutter" :class="{ on : this.current_list == 4 }"
             v-if="current_list == 4">
          <div class="item col-3_lg-3_md-4_sm-6_xs-12" v-for="(item, index) in members4" :key="'members4_'+index"
               v-on:click="toggleMember()">
            <div class="inner">
              <div class="pict cover" :style="'background-image:url(' + $root.publicPath + item.photo + ')'"></div>
              <div class="card">
                <div><strong>{{ item.nom }}</strong><em>{{ item.fonction }}</em>
                  <p v-html="item.txt" v-if="item.txt"></p></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div id="ra_quotes" class="section" v-if="rapport.quotes && rapport.quotes.length">
      <div class="wrap_list grabbable">
        <div class="list splide" id="quotes_splide">
          <div class="splide__track">
            <ul class="splide__list">
              <li class="splide__slide" v-for="(item, index) in rapport.quotes" :key="'quotes_'+ index">
                <div class="item">
                  <div class="side left"></div>
                  <div class="side right"></div>
                  <div class="inner">
                    <div class="card">
                      <img :src="$root.publicPath + item.photo"/>
                      <p class="author"><strong>{{ item.auteur }}</strong><br/><em>{{ item.fonction }}</em></p>
                    </div>
                    <div class="txt" v-html="item.txt"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>


    <div id="ra_bilan" class="section" v-if="rapport.bilan_img || rapport.bilan_titre || rapport.bilan_txt">
      <div class="wrap">
        <div class="grid-middle-noBottom">
          <div class="col-6_sm-12" v-if="rapport.bilan_img">
            <img :src="$root.publicPath + rapport.bilan_img"/>
          </div>
          <div :class="rapport.bilan_img ? 'col-6_sm-12' : 'col-12_sm-12 center'">
            <div class="txt">
              <h3>{{ rapport.bilan_titre }}</h3>
              <p v-html="rapport.bilan_txt"></p>
              <a :href="$root.publicPath + rapport.bilan_file" target="_blank" class="arrow2">
                <!--<svg><use xlink:href="#arrow"></use></svg>-->
                <svg viewBox="0 0 27.951 17.841">
                  <g fill="none" stroke="#ffffff" stroke-linecap="round" stroke-width="3">
                    <path d="M1.5 8.92h22.112"/>
                    <path stroke-linejoin="round" d="M19.014 2.121l6.817 6.817-6.782 6.781"/>
                  </g>
                </svg>
                Télécharger le rapport
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<style lang="scss">
.splide__list {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.splide__list:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

#wrap.rapport {
  max-width: 100% !important;
  margin: 0 auto !important;
  padding: 0 !important;
  overflow-x: hidden;
}

div.wrap {
  position: relative;
  width: 100%;
  max-width: 1370px;
  margin: 0 auto;
  padding: 0 45px;
}

#rapport p, #rapport div.txt, #rapport a.arrow {
  font-size: 22px;
  line-height: 1.3em;
}

#rapport p.author {
  font-size: 18px;
  line-height: 24px;
}

#rapport p.author em {
  color: #707070;
}

#rapport div.section {
  margin-bottom: 100px;
}

#rapport div.grid {
  clear: both;
}

#ra_hero {
  width: 100%;
  height: 800px;
  padding: 50px 0;
  margin-bottom: 50px;
  background-color: #ddd;
  background-position: bottom center;
}

#ra_hero h1 {
  font-size: 26px !important;
  line-height: 1.2em !important;
  color: #2F2F2F;
  max-width: 500px;
  margin: 170px 0 100px;
}

#ra_hero h1 strong {
  position: relative;
  display: inline-block;
  font-size: 65px !important;
  line-height: 1.1em !important;
  color: #fff;
  margin-bottom: 20px;
}

#ra_hero h1 strong:after {
  position: absolute;
  content: '';
  width: 173px;
  height: 174px;
  top: -80%;
  right: 0;
  background-image: url('../img/ra/pouvoir.png');
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#ra_hero img {
  width: 320px;
  max-width: 100%;
}

#ra_ambition div.txt {
  padding: 50px 0;
}

#ra_ambition img {
  position: relative;
  top: -100px;
}

#rapport h2.line {
  position: relative;
  max-width: 900px;
  margin-bottom: 50px;
  font-size: 65px;
  line-height: 1.1em;
}

#rapport h2.line.right {
  text-align: right;
  float: right;
}

#rapport h2.line:after {
  position: absolute;
  content: '';
  width: 80px;
  height: 3px;
  top: 50%;
  background: #e78400;
}

#rapport h2.line.left:after {
  left: -110px;
}

#rapport h2.line.right:after {
  right: -110px;
}

#ra_dirigeant div.txt {
  padding: 100px 0;
}

#ra_dirigeant div.picts {
  position: relative;
  width: calc(100% + 100px);
  height: 700px;
  left: -100px;
}

#ra_dirigeant div.picts .item {
  position: absolute;
  width: calc(50% + 25px);
}

#ra_dirigeant div.picts .item1 {
  top: 0;
  right: 0;
  z-index: 1;
}

#ra_dirigeant div.picts .item2 {
  bottom: 0;
  left: 0;
}

#ra_dirigeant div.picts img {
  display: block;
  margin: 0 auto;
}

#ra_dirigeant div.picts p {
  position: relative;
  display: inline-block;
  width: 180px;
  background: #fff;
  padding: 5px;
}

#ra_dirigeant div.picts .item1 p {
  position: absolute;
  right: 0;
  margin-top: 50px;
}

#ra_dirigeant div.picts .item2 p {
  position: absolute;
  bottom: 50px;
  left: calc(200% - 250px);
}

#ra_dirigeant div.picts .item1 p span {
  position: absolute;
  bottom: 0;
  right: 25px;
  width: 3px;
  height: 120px;
  background: #e78400;
}

#ra_dirigeant div.picts .item1 p span:before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 20px;
  height: 3px;
  background: #e78400;
}

#ra_dirigeant div.picts .item1 p span:after {
  position: absolute;
  content: '';
  bottom: 0;
  right: 0;
  width: 20px;
  height: 3px;
  background: #e78400;
}

#ra_dirigeant div.picts .item2 p span {
  position: absolute;
  bottom: 0;
  left: -125px;
  width: 3px;
  height: 100%;
  background: #e78400;
}

#ra_dirigeant div.picts .item2 p span:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background: #e78400;
}

#ra_dirigeant div.picts .item2 p span:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background: #e78400;
}

#ra_dirigeant div.txt {
  position: relative;
  padding-left: 50px;
}

#ra_dirigeant div.txt img {
  position: absolute;
  margin-top: -40px;
  left: 0;
  width: 120px;
}

#ra_dirigeant div.txt p {
  position: relative;
  z-index: 1;
}

#ra_dirigeant div.txt p u {
  text-decoration: none;
  background: #e9e9e9;
  padding: 1px 3px;
}

#ra_engagement {
  margin-bottom: 500px !important;
}

#ra_engagement h2 {
  margin: 0 auto 50px;
}

#ra_engagement div.chiffres {
  position: relative;
}

#ra_engagement div.chiffres:before {
  position: absolute;
  content: '';
  width: 300%;
  height: 100%;
  top: 30px;
  right: 0;
  margin-right: -50px;
  background: #f5f5f5;
}

#ra_engagement div.chiffres > div {
  position: relative;
  z-index: 1;
  padding: 100px 0;
}

#ra_engagement div.chiffres p {
  margin-bottom: 30px;
}

#ra_engagement div.chiffres p strong {
  display: block;
  font-size: 45px;
  line-height: 1.2em;
  font-weight: 600;
  color: #e78400;
  padding-right: 5px;
}

#ra_engagement div.txt {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 4vw;
  max-width: 480px;
  margin: 0 auto;
}

#ra_engagement div.txt div {
  position: absolute;
  width: 3px;
  height: 100%;
  background: #e78400;
}

#ra_engagement div.txt div.left {
  left: 0;
}

#ra_engagement div.txt div.right {
  right: 0;
}

#ra_engagement div.txt div:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 3px;
  top: 0;
  left: 0;
  background: #e78400;
}

#ra_engagement div.txt div:after {
  position: absolute;
  content: '';
  width: 50px;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #e78400;
}

#ra_engagement div.txt div.right:before,
#ra_engagement div.txt div.right:after {
  left: auto;
  right: 0;
}

#ra_engagement img {
  position: absolute;
  right: 0;
  max-width: 1000px;
  margin-top: -250px;
}


#ra_rubs {
  position: relative;
  z-index: 3;
  clear: both;
  margin-bottom: 10px;
}

#ra_rubs div.arrows {
  display: none;
  text-align: center;
}

#ra_rubs div.arrows a {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 0;
  margin: 0 15px 15px;
  transition: all .3s ease;
}

#ra_rubs div.arrows a svg.icon {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  fill: #e78400;
  transition: fill 1s ease;
}

#ra_rubs div.arrows a svg.circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: #ccc;
  transition: all 1s ease;
}

#ra_rubs div.arrows a svg.circle2 {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: #e78400;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: all 1s ease;
}

#ra_rubs div.arrows a:hover {
  border-color: #4F5D5B;
}

#ra_rubs div.arrows a:hover svg.icon g {
  stroke: #e78400;
  transition: stroke .3s ease;
}

#ra_rubs div.arrows a:hover svg.circle {
  stroke-dashoffset: 1000;
}

#ra_rubs div.arrows a:hover svg.circle2 {
  stroke-dashoffset: 0;
  fill: #fff;
}

#rubs_splide {
  text-align: center;
}

#rubs_splide ul {
  width: 100%;
}

#ra_rubs li {
  display: inline-block;
}

#ra_rubs div.item {
  position: relative;
  cursor: pointer;
  margin: 0 65px 25px 0;
  padding-left: 50px;
}

#ra_rubs div svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  margin-right: 15px;
  opacity: .7;
}

#ra_rubs div.on svg {
  opacity: 1;
}

#ra_rubs p {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 33px;
  line-height: 1em;
  font-weight: 600;
  color: #ccc;
}

#ra_rubs div.rub1 svg {
  fill: #8cd096;
}

#ra_rubs div.rub2 svg {
  fill: #bcc5d9;
}

#ra_rubs div.rub3 svg {
  fill: #a1c5c3;
}

#ra_rubs div.rub4 svg {
  fill: #c7e9f9;
}

#ra_rubs div.rub1.on p,
#ra_rubs div.rub1:hover p {
  color: #8cd096;
}

#ra_rubs div.rub2.on p,
#ra_rubs div.rub2:hover p {
  color: #bcc5d9;
}

#ra_rubs div.rub3.on p,
#ra_rubs div.rub3:hover p {
  color: #a1c5c3;
}

#ra_rubs div.rub4.on p,
#ra_rubs div.rub4:hover p {
  color: #c7e9f9;
}


#ra_actions {
  margin-bottom: 200px !important;
}

#ra_actions div.sep {
  display: inline-block;
  width: 1px;
  height: 100%;
  background: #eee;
}

#ra_actions div.recap {
  position: relative;
  margin-bottom: 30px;
  padding: 20px 50px;
  font-size: 22px;
  line-height: 1.4em;
  color: #fff;
}

.recaps-enter-active, .recaps-leave-active {
  transition: opacity .5s;
}

.recaps-enter, .recaps-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

#ra_actions div.recap:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  bottom: 0;
  left: 0;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
}

#ra_actions div.recap:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border-top: 15px solid #fff;
  border-left: 15px solid transparent;
}

#ra_actions div.recap p {
  color: #fff;
}

#ra_actions div.recap div.repartition div {
  position: relative;
  display: inline-block;
}

#ra_actions div.recap div.repartition div.bar {
  width: 188px;
  height: 5px;
  background: #f1f1f1;
  border-radius: 3px;
  margin: 0 10px;
}

#ra_actions div.recap div.repartition div.bar div {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 5px;
  border-radius: 3px;
  background: #111;
}

.color_rub1 {
  color: #8cd096 !important;
}

.color_rub2 {
  color: #bcc5d9 !important;
}

.color_rub3 {
  color: #a1c5c3 !important;
}

.color_rub4 {
  color: #c7e9f9 !important;
}

#ra_rubs div.on p {
  text-decoration: underline;
}

#ra_actions {
  position: relative;
}

#ra_actions div.no-result {
  font-weight: 600;
  text-align: center;
  padding: 20px;
  border: 2px solid #f8f8f8;
}

#ra_actlist {
  position: relative;
  z-index: 1;
  background: #fff;
  margin-bottom: 30px;
}

#ra_actlist a.item {
  transition: all .5s ease-in-out;
}

.actions-enter {
  transform: scale(0.1) translateY(-80px);
  opacity: 0;
}

.actions-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.actions-leave-active {
  position: absolute;
  z-index: -1;
}

#ra_actlist a.item div.inner {
  position: relative;
  text-align: center;
  height_: 300px;
  padding: 60px 30px;
  background: #f8f8f8;
  border: 2px solid #f8f8f8;
}

#ra_actlist a.item div.inner h4 {
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 700;
  color: #2f2f2f;
  margin: 0;
}

#ra_actlist a.item div.inner h6 {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 700;
  color: #e78400;
  margin: 0 0 5px;
}

#ra_actlist a.item div.inner p {
  font-size: 11px;
  line-height: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  color: #999;
  margin: 0 0 15px;
}

#ra_actlist a.item:hover div.inner {
  background: #fff;
}

#ra_actlist a.item.rub1 div.inner h6 {
  color: #8cd096 !important;
}

#ra_actlist a.item.rub2 div.inner h6 {
  color: #bcc5d9 !important;
}

#ra_actlist a.item.rub3 div.inner h6 {
  color: #a1c5c3 !important;
}

#ra_actlist a.item.rub4 div.inner h6 {
  color: #c7e9f9 !important;
}

#ra_actlist div.loading {
  display: none;
}

#ra_actions div.more {
  text-align: center;
}

#ra_instances {
}

#ra_instances h2 {
  margin: 0 auto 50px;
}

#ra_instances div.txt {
  padding: 50px;
}

#ra_instances div.txt strong {
  display: inline-block;
  font-size: 45px;
  line-height: 1em;
  margin-bottom: 15px;
}

#ra_instances div.col_picts {
  max-width: 720px;
  max-height: 720px;
  margin: 0 auto;
}

#ra_instances div.picts {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
}

#ra_instances div.pict {
  position: absolute;
  overflow: hidden;
  padding-bottom: 100%;
}

#ra_instances div.picts .item1 {
  top: 5%;
  left: 0;
  width: 55%;
}

#ra_instances div.picts .item2 {
  top: 65%;
  left: 25%;
  width: 30%;
}

#ra_instances div.picts .item3 {
  top: 0;
  left: 60%;
  width: 30%;
}

#ra_instances div.picts .item4 {
  top: 35%;
  left: 60%;
  width: 40%;
}

#ra_instances div.pict div.photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100%;
  heisght: 100%;
  transition: transform .5s ease;
}

#ra_instances div.pict div.photo.one {
  transform: translateX(-100%);
}

#ra_instances div.pict div.photo.two {
  transform: translateX(100%);
}

#ra_instances div.pict div.photo.on {
  transform: translateX(0);
}

#ra_instances div.pict div.photo p {
  display: none !important;
}

#ra_evaluation {
  margin-bottom: 100px !important;
}

#ra_evaluation div.intro {
  font-size: 45px;
  line-height: 1em;
  font-weight: 600;
  margin-bottom: 15px;
  padding-right: 30px;
  max-width: 320px;
}

#ra_evaluation div.grid {
  margin-right: 100px;
}

#ra_evaluation div.txt {
  max-width: 640px;
}

#ra_evaluation div.arrows {
  position: absolute;
  top: 120px;
  right: 30px;
  background: #fff;
}

#ra_evaluation div.arrows a {
  position: relative;
  display: block;
  width: 72px;
  height: 72px;
  margin-bottom: 15px;
  transition: all .3s ease;
}

#ra_evaluation div.arrows a svg.icon {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  fill: #e78400;
  transition: fill 1s ease;
}

#ra_evaluation div.arrows a svg.circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: #ccc;
  transition: all 1s ease;
}

#ra_evaluation div.arrows a svg.circle2 {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: #e78400;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: all 1s ease;
}

#ra_evaluation div.arrows a:hover {
  border-color: #4F5D5B;
}

#ra_evaluation div.arrows a:hover svg.icon g {
  stroke: #e78400;
  transition: stroke .3s ease;
}

#ra_evaluation div.arrows a:hover svg.circle {
  stroke-dashoffset: 1000;
}

#ra_evaluation div.arrows a:hover svg.circle2 {
  stroke-dashoffset: 0;
  fill: #fff;
}

#ra_members {
}

#ra_members div.groups {
  margin: 0 -3rem 50px;
}

#ra_members div.groups a {
  font-size: 65px;
  font-weight: 700;
  color: #d5d5d5;
}

#ra_members div.groups a:hover,
#ra_members div.groups a.on {
  color: #e78400;
  text-decoration: underline;
}

#ra_members div.cats {
  text-align: center;
  margin-bottom: 30px;
}

#ra_members div.cats a {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #707070;
  border: 1px solid #aaa;
  border-radius: 25px;
  padding: 10px 25px;
  margin: 0 7px 15px;
}

#ra_members div.cats a:hover,
#ra_members div.cats a.on {
  color: #fff !important;
  border-color: #e78400;
  background: #e78400;
}

#ra_members div.item {
  position: relative;
  overflow: hidden;
}

#ra_members div.inner {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 150%;
}

#ra_members div.pict {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: grayscale(100%);
}

#ra_members div.card {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 20px;
  transform: translate3d(0%, 100%, 0);
  transition: transform .3s ease;
}

#ra_members div.card div {
  font-size: 13px;
}

#ra_members div.card div p {
  position: relative;
  display: block;
  padding-top: 20px;
  font-size: 13px;
}

#ra_members div.card div p:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 1px;
  top: 10px;
  left: 0;
  background: #e78400;
}

#ra_members div.card em {
  color: #666;
}

#ra_members div.card div strong {
  display: block;
  margin-bottom: 3px;
  font-size: 15px;
}

#ra_members div.item:hover div.pict,
#ra_members div.item.on div.pict {
  filter: none;
}

#ra_members div.item:hover div.card,
#ra_members div.item.on div.card {
  transform: translate3d(0%, 0%, 0);
}


#ra_quotes {
}

#ra_quotes div.wrap_list {
  width: calc(1280px + ((100vw - 1280px) / 2));
}

#ra_quotes div.list {
  display: flex;
  flex-flow: row wrap;
}

#ra_quotes .splide__list {
  display: flex;
}

#ra_quotes .splide__list li {
  align-self: stretch;
  display: flex;
}

#ra_quotes .item {
  position: relative;
  display: flex;
  flex-basis: 600px;
  max-width: 600px;
  width: 600px;
  margin-right: 40px;
  margin-bottom: 40px;
  background: #fff;
}

#ra_quotes .item div.side {
  position: absolute;
  width: 3px;
  height: 100%;
  background: #e78400;
}

#ra_quotes .item div.left {
  left: 0;
}

#ra_quotes .item div.right {
  right: 0;
}

#ra_quotes .item div.side:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 3px;
  top: 0;
  left: 0;
  background: #e78400;
}

#ra_quotes .item div.side:after {
  position: absolute;
  content: '';
  width: 50px;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #e78400;
}

#ra_quotes .item div.right:before,
#ra_quotes .item div.right:after {
  left: auto;
  right: 0;
}

#ra_quotes div.inner {
  position: relative;
  padding: 70px;
}

#ra_quotes div.card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

#ra_quotes div.card img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-right: 30px;
}

#ra_quotes div.card p {
  flex: 1;
}

#ra_quotes div.txt {
  font-style: italic;
}


#ra_quotes div.arrows {
  position: absolute;
  top: 120px;
  right: 30px;
}

#ra_quotes div.arrows a {
  position: relative;
  display: block;
  width: 72px;
  height: 72px;
  margin-bottom: 15px;
  transition: all .3s ease;
}

#ra_quotes div.arrows a svg.icon {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  fill: #e78400;
  transition: fill 1s ease;
}

#ra_quotes div.arrows a svg.circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: #ccc;
  transition: all 1s ease;
}

#ra_quotes div.arrows a svg.circle2 {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: #e78400;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: all 1s ease;
}

#ra_quotes div.arrows a:hover {
  border-color: #4F5D5B;
}

#ra_quotes div.arrows a:hover svg.icon g {
  stroke: #e78400;
  transition: stroke .3s ease;
}

#ra_quotes div.arrows a:hover svg.circle {
  stroke-dashoffset: 1000;
}

#ra_quotes div.arrows a:hover svg.circle2 {
  stroke-dashoffset: 0;
  fill: #fff;
}

#ra_bilan {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F59100;
  margin-bottom: 0px !important;
}

#ra_bilan div.txt {
  color: #fff;
  padding: 50px 0;
}

#ra_bilan div.txt a {
  display: inline-block;
  margin-top: 30px;
  color: #fff;
}


@media (max-width: 100em) {
  div.wrap {
    padding: 0 30px;
  }
  #ra_dirigeant div.picts {
    width: auto;
    height: 600px;
    left: auto;
  }
  #ra_dirigeant div.picts p {
  }
  #ra_dirigeant div.picts .item1 p span {
    right: 20px;
  }
  #ra_dirigeant div.picts .item2 p span {
    left: -40px;
  }
  #ra_dirigeant div.picts .item2 p span:before,
  #ra_dirigeant div.picts .item2 p span:after {
    width: 20px;
  }
}

@media (max-width: 80em) {
  div.wrap {
    padding: 0 20px;
  }
  #ra_hero {
    background-position: 10% center;
    height: auto;
  }
  #rapport h2.line {
    font-size: 44px;
    margin-bottom: 30px;
    max-width: 600px;
  }
  #rapport p, #rapport div.txt, #rapport a.arrow {
    font-size: 18px;
  }
  #ra_engagement div.chiffres p strong {
    font-size: 36px;
  }
  #ra_rubs div.item {
    padding-left: 34px;
    margin-right: 5vw;
  }
  #ra_rubs div svg {
    width: 27px;
    height: 27px;
  }
  #ra_rubs p {
    font-size: 2.7vw !important;
  }
  #ra_members div.groups {
    margin: 0 0 50px;
    justify-content: center;
  }
  #ra_members div.groups a {
    margin: 15px;
  }
  #ra_evaluation div.intro {
    font-size: 36px;
  }
  #ra_instances div.txt {
    padding: 50px 0;
  }
}

@media (max-width: 64em) {
  div.wrap {
    padding: 0 15px;
  }
  #ra_dirigeant div.picts {
    height: auto;
    text-align: center;
  }
  #ra_dirigeant div.picts .item {
    position: relative;
    width: auto;
    max-width: 280px;
    margin: 0 auto 50px;
  }
  #ra_dirigeant div.picts .item p {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    margin-top: 20px;
  }
  #ra_dirigeant div.picts .item p span {
    display: none;
  }
  #ra_dirigeant div.txt {
    padding: 0;
  }
  #ra_engagement {
    margin-bottom: 50px !important;
  }
  /*#ra_engagement div.chiffres p strong{display:inline-block;}*/
  #ra_engagement div.chiffres:before {
    display: none;
  }
  #ra_engagement img {
    position: relative;
    right: auto;
    max-width: 100%;
    margin-top: auto;
  }
  #ra_engagement div.txt {
    top: -100px;
  }
  #ra_actions div.sep {
    display: inline-block;
    width: 100%;
    height: 1px;
    margin: 15px auto 10px;
  }
  #ra_actions div.recap p {
    margin-top: 125px;
  }
  #ra_actions div.recap p:first-child {
    margin-top: 0;
  }
  #ra_actions div.recap p br {
    display: none;
  }
  #ra_actions div.recap {
    padding: 20px 20px;
    font-size: 18px;
    color: #fff;
  }
  #ra_rubs div.item {
    margin-right: 4vw;
  }
  #ra_rubs p {
    font-sisze: 2.7vw !important;
  }
  #ra_rubs a.arrow {
    display: block;
  }
  #ra_actlist a.item div.inner {
    height_: 250px;
  }
  #ra_actlist a.item div.inner h4 {
    font-size: 20px;
  }
  #ra_actlist a.item div.inner h6 {
    font-size: 17px;
  }
  #ra_members div.groups a {
    font-size: 36px;
  }
  #ra_members div.cats a {
    font-size: 13px;
    border-radius: 25px;
    padding: 10px 25px;
    margin: 0 7px 15px;
  }
  #ra_members div.card p {
    font-size: 11px;
  }
  #ra_members div.card p strong {
    font-size: 13px;
    margin-bottom: 2px;
  }
  #ra_evaluation div.intro {
    font-size: 28px;
    margin-bottom: 30px;
    max-width: 100%;
  }
  #ra_evaluation div.txt {
    margin-bottom: 30px;
  }
  #ra_evaluation div.grid {
    margin-right: 0;
  }
  #ra_evaluation div.txt {
    max-width: 100%;
  }
  #ra_evaluation div.arrows {
    position: relative;
    top: auto;
    right: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #ra_evaluation div.arrows a {
    width: 48px;
    height: 48px;
    margin: 0 15px 30px;
  }
  #ra_evaluation div.arrows a svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 48em) {
  #ra_hero {
    background-position: bottom left;
  }
  #ra_hero h1 {
    font-size: 16px !important;
    max-width: 300px;
    margin: 120px 0 40px;
  }
  #ra_hero h1 strong {
    font-size: 36px !important;
  }
  #ra_hero h1 strong:after {
    width: 86.5px;
    height: 87px;
  }
  #rapport h2.line {
    max-width: 100%;
    font-size: 36px;
    max-width: 480px;
  }
  #rapport p, #rapport div.txt, #rapport a.arrow {
    font-size: 17px;
  }
  #rapport div.section {
    margin-bottom: 30px;
  }
  #ra_ambition div.txt {
    padding: 0 0 50px 0;
  }
  #ra_ambition img {
    top: -25px;
  }
  #ra_instances div.txt {
    padding: 0;
  }
  #ra_instances div.txt strong {
    font-size: 28px;
  }
  #ra_engagement div.chiffres > div {
    padding: 25px 0 0 0;
  }
  #ra_engagement div.txt {
    top: auto;
    margin: 50px auto;
  }
  #ra_engagement img {
    margin-bottom: 50px;
  }
  #ra_evaluation {
    margin-bottom: 30px !important;
  }
  #ra_rubs div.item {
    margin-right: 40px;
  }
  #ra_rubs p {
    font-size: 24px !important;
  }
  #ra_actions {
    margin-bottom: 100px !important;
  }
  #ra_quotes .item {
    flex-basis: 260px;
    max-width: 260px;
    width: 260px;
  }
  #ra_quotes div.inner {
    padding: 30px;
  }
  #ra_bilan img {
    display: block;
    max-width: 300px;
    margin: 0 auto;
  }
  #ra_bilan div.txt {
    text-align: center;
    padding-bottom: 50px;
  }
  #ra_members div.item {
    max-width: 280px;
    margin: 0 auto 50px;
  }
  #ra_members div.card {
    border: 1px solid #eee;
  }
}

@media (max-width: 840px) {
  #ra_rubs div.arrows {
    display: block;
  }
}

</style>


<script>
/*
import MembersList1 from '../json/membres1.json'
import MembersList2 from '../json/membres2.json'
import MembersList3 from '../json/membres3.json'
import MembersList4 from '../json/equipe.json'
*/

//import Splide from '@splidejs/splide'
import Splide from '../js/splide.esm'
import '../css/splide.min.css'

//import counterUp from 'counterup2'
//import countTo from '../js/countTo.js'

import AOS from 'aos'

export default {
  name: 'rapport',

  data() {
    return {
      rapport: {},
      chiffres_col1: {},
      chiffres_col2: {},

      rubriques: {},
      rubriques_names: ['alimentation', 'logement', 'education', 'sante'],
      //selected_rub		: [1, 2, 3, 4],
      selected_rub: [1],
      color_rub: ['#8cd096', '#bcc5d9', '#a1c5c3', '#c7e9f9'],
      actions: {},

      nb_projet: {},
      aides: {},
      pourcentage: {},

      members1: {},
      members2: {},
      members3: {},
      members4: {},
      current_list: false,

      communaute_interval: false,
      communaute_step: 1,
      communaute_nb: 0,
      communaute: [],
      communaute_new: []
    }
  },


  computed: {
    pad_left: function () {
      var winw = $(window).width();
      var wrapw = $('div.wrap:first').width();
      // alert(wrapw);
      return parseInt((winw - wrapw) / 2);
    }
  },

  mounted() {
    this.fetch();

    /*
		this.members1 = MembersList1;
		this.members2 = MembersList2;
		this.members3 = MembersList3;
		this.members4 = MembersList4;
		*/

    this.loadMembersList(1, false);

    AOS.init();

    window.addEventListener("scroll", this.handleScroll);


    /*$('#ra_members div.item').on('click', function(e){
			e.prevent
		});
		:hover div.card*/


    // var vm = this;
    /*setTimeout(function(){
			vm.splideEvaluations();
		}, 5000);*/

    /*
		this.$nextTick(function () {
			vm.splideEvaluations();
		});*/

    /*window.addEventListener('resize', () => {
			var winw  = $(window).width();
			var wrapw = $('div.wrap:first').width();
			// alert(wrapw);
			this.pad_left = parseInt( ( winw - wrapw ) / 2 );
		})*/

  },

  methods: {

    handleScroll() {
      /*$('[data-type="background"]').each(function(){
				var _this = $(this);
				$(window).scroll(function(){
					var distance = $(window).scrollTop() * _this.attr('data-speed');
					console.log('distance => ' + distance );
					_this.css('transform','translate3d(0, ' + distance +'px,0)');
				});
			});*/
    },


    toggleMember() {
      let item = $(event.target).parents('.item');
      let supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
      if (supportsTouch) {
        item.toggleClass('on');
      }

    },

    async fetch() {

      let vm = this;
      let slug = vm.$route.params.slug;

      await axios.get(this.$root.publicURI + 'api/getMembers/member/2/' + slug, {})
          .then(res => {
            vm.members1 = res.data;
          });

      await axios.get(this.$root.publicURI + 'api/getMembers/member/3/' + slug, {})
          .then(res => {
            vm.members2 = res.data;
          });

      await axios.get(this.$root.publicURI + 'api/getMembers/member/4/' + slug, {})
          .then(res => {
            vm.members3 = res.data;
          });

      await axios.get(this.$root.publicURI + 'api/getMembers/member/1/' + slug, {})
          .then(res => {
            vm.members4 = res.data;
          });

      await axios.get(this.$root.publicURI + 'api/getRapport/' + slug, {})
          .then(res => {
            vm.rapport = res.data.item;
            // vm.prev	= res.data.prev;
            // vm.next	= res.data.next;

            vm.$nextTick(() => {
              setTimeout(function () {
                vm.splideEvaluations();
                vm.splideQuotes();
              }, 1000);
            });
          });

      await axios.get(this.$root.publicURI + 'api/getChiffres', {})
          .then(res => {
            vm.chiffres_col1 = res.data.slice(0, 4);
            vm.chiffres_col2 = res.data.slice(4);
            // console.log( this.chiffres );
            vm.$nextTick(() => {
              setTimeout(function () {
                vm.counterAnimation();
              }, 1000);
            });
          });

      await axios.get(this.$root.publicURI + 'api/getRubriques', {})
          .then(res => {
            vm.rubriques = res.data;
            vm.$nextTick(() => {
              setTimeout(function () {
                vm.splideRubriques();
              }, 1000);
            });
          });

      await axios.get(this.$root.publicURI + 'api/getActions/' + slug, {})
          .then(res => {
            vm.actions = res.data;
            /*if( this.$route.params.slug ){
					this.selected_rub = [this.rubriques_names.indexOf(this.$route.params.slug)+1];
				}*/
          });


      await axios.get(this.$root.publicURI + 'api/getStats/' + slug, {})
          .then(res => {
            vm.nb_projet = res.data.nb_projet;
            vm.aides = res.data.aides;
            vm.pourcentage = res.data.pourcentage;
          });

      await axios.get(this.$root.publicURI + 'api/getMembers/communaute', {})
          .then(res => {
            for (let i = 0; i < res.data.length; i++) {
              let id = res.data[i].id;
              vm.communaute.push(id);
              vm.communaute_new.push(id);
            }
            vm.communaute_new = _.shuffle(vm.communaute_new);

            vm.communaute_interval = setInterval(function () {
              vm.communaute_step = (vm.communaute_step == 1) ? 2 : 1;
              // console.log(vm.communaute_step);

              setTimeout(function () {
                if (vm.communaute_step == 2) {
                  vm.communaute = _.shuffle(vm.communaute);
                  // console.log(vm.communaute_step);
                } else {
                  vm.communaute_new = _.shuffle(vm.communaute_new);
                  // console.log(vm.communaute_step);
                }
              }, 2000);
            }, 4000);
          });
    },

    toggleRub(id) {
      /*if( this.selected_rub.indexOf(id) >= 0 ){
				this.selected_rub.splice(this.selected_rub.indexOf(id), 1);
			}
			else{
				this.selected_rub.push(id);
			}*/
      this.selected_rub = [id];
    },

    scrollToList(duration) {
      $('html, body').animate({'scrollTop': $('#ra_actions div.filters').offset().top - 30}, duration);
    },


    loadMembersList(num, scroll = true) {

      this.current_list = num;

      if (scroll) {
        $('html, body').animate({'scrollTop': $('#ra_members').offset().top - 30}, 500);
      }
    },


    splideEvaluations() {

      var splide = new Splide('#evaluations_splide', {
        arrows: false,
        pagination: false,
        autoplay: false,
        interval: 4000,
        speed: 1000
      }).mount();

      /*
			var prevBtn = document.querySelector('#evaluation_prev');
			prevBtn.addEventListener('click', function (event) {
				splide.go( '-1' );
			});

			var nextBtn = document.querySelector('#evaluation_next');
			nextBtn.addEventListener('click', function (event) {
				splide.go( '+1' );
			});
			*/

      $('#evaluation_prev').on('click', function (e) {
        e.preventDefault();
        splide.go('-1');
      });

      $('#evaluation_next').on('click', function (e) {
        e.preventDefault();
        splide.go('+1');
      });
    },


    splideQuotes() {

      if ($('#quotes_splide li').length) {

        var splide = new Splide('#quotes_splide', {
          arrows: false,
          pagination: false,
          autoplay: false,
          interval: 4000,
          speed: 1000,
          perPage_: 2,
          autoWidth: true,

          padding: {
            left: this.pad_left,
            right: '0',
          }
        }).mount();
      }
    },


    splideRubriques() {

      // alert( this.pad_left );

      var splide = new Splide('#rubs_splide', {
        arrows: false,
        pagination: false,
        autoplay: false,
        interval: 4000,
        speed: 1000,
        //perPage_: 2,
        autoWidth: true,

        breakpoints: {
          10000: {
            //type: 'slide',
            //perPage: 3,
            destroy: true
          },
          840: {
            //type: 'loop',
            //perPage: 1,
            destroy: false
          }
        },


        padding_: {
          left: this.pad_left,
          right: '0',
        },

        breakpoints___: {
          992: {
            arrows: true
          }
        },

        breakpoints__: {
          10000: {
            fixedWidth: '640px',
          },
          992: {
            fixedWidth: '320px',
          }
        },
        breakpoints_: {
          10000: {
            type: 'slide',
            perPage: 3,
            destroy: true
          },
          992: {
            type: 'loop',
            perPage: 1,
            destroy: false
          }
        }
      }).mount();


      $('#rubs_prev').on('click', function (e) {
        e.preventDefault();
        splide.go('-1');
      });

      $('#rubs_next').on('click', function (e) {
        e.preventDefault();
        splide.go('+1');
      });

    },


    counterAnimation() {

      let vm = this;

      var countTo = function countTo(item, options) {

        var defaults = {
          from: 100,
          to: 10,
          speed: 500,
          refreshInterval: 100,
          decimals: 0,
          onUpdate: null,
          onComplete: null,
          separator: '.',
          thousand_separator: ' '
        };

        options = $.extend({}, defaults, options || {});

        var loops = Math.ceil(options.speed / options.refreshInterval),
            increment = (options.from - options.to) / loops;
        Number.prototype.formatMoney = function (c, d, t) {
          var n = this,
              c = isNaN(c = Math.abs(c)) ? 2 : c,
              d = d == undefined ? "." : d,
              t = t == undefined ? " " : t,
              s = n < 0 ? "-" : "",
              i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
              j = (j = i.length) > 3 ? j % 3 : 0;
          return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        };

        return item.each(function () {

          var _this = this,
              loopCount = 0,
              value = options.from,
              interval = setInterval(updateTimer, options.refreshInterval);

          function updateTimer() {
            value -= increment;
            loopCount++;
            var newVal = value.formatMoney(options.decimals, options.separator, options.thousand_separator); //  + '%';
            $(_this).html(newVal);

            if (typeof options.onUpdate == 'function') {
              options.onUpdate.call(_this, value);
            }

            if (loopCount >= loops) {
              clearInterval(interval);
              value = options.to;

              if (typeof options.onComplete == 'function') {
                options.onComplete.call(_this, value);
              }
            }
          }
        });
      }

      var counterAnim = function counterAnim(entries) {

        return entries.forEach(function (entry) {
          if (entry.intersectionRatio > 0) {
            var item = $(entry.target);
            if (!item.hasClass('set')) {
              var nb = item.find('span');
              var nb_value = nb.data('val');
              var decimal_separator = ',';
              var thousand_separator = (parseInt(nb_value) >= 100000) ? '.' : ' ';

              setTimeout(function () {
                countTo(nb, {
                  from: 0,
                  to: nb_value,
                  speed: 700,
                  decimals: 0,
                  separator: decimal_separator,
                  thousand_separator: thousand_separator
                });
                item.addClass('set');
              }, 100);
            }
          }
        });
      };

      var Obs = new IntersectionObserver(counterAnim);
      var counters = document.querySelectorAll(".counter");
      counters.forEach(function (el) {
        return Obs.observe(el);
      });
    }

  }
}
</script>
