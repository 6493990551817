<template>
  <div id="mission">
    <div class="header clearfix">
      <h1><span>Notre </span> mission</h1>
      <h3>Depuis 2017, et après 18 ans d’action <br class="sm-hidden"/>sous l’égide de la Fondation de <br
          class="sm-hidden"/>France, le groupe Up a décidé de <br class="sm-hidden"/>créer sa Fondation d’entreprise
      </h3>
    </div>

    <div class="intro mb100">
      <h4>La fondation Up porte désormais l’ambition...</h4>
      <div class="pict">
        <div class="box">
          <div class="before"></div>
          <div class="after"></div>
          <p><strong>...d’impulser le pouvoir d’agir ensemble, aux personnes en situation d’isolement ou de dépendance
            (« les invisibles »), toutes générations confondues, en leur permettant d’accéder à l’essentiel pour
            s’épanouir plus librement.</strong></p>
        </div>
        <img :src="$root.publicPath + 'img/picture/mission.jpg'"/>
      </div>
    </div>

    <div id="world" class="mb100">
      <h3 class="center">La Fondation Up définit le cadre général des actions <br/>de mécénat pour l’ensemble du groupe
        Up.</h3>
      <div class="grid-noGutter content">
        <div class="col-9_lg-8_md-12 map">
          <img :src="$root.publicPath + 'img/world.png'"/>
          <div class="countries">
            <flickity class="flickity" ref="flickity" :options="flickityOptions" v-if="countries.length > 0">
              <div class="carousel-cell" v-for="(country, index) in countries" :key="index">
                <router-link :to="'actions#' + country.id">{{ country.nom }}</router-link>
              </div>
            </flickity>
          </div>
        </div>
        <div class="col-3_lg-4_md-12 txt">
          <p class="margin">Elle conduit la politique de mécénat à travers tous les pays d’implantation du groupe Up et
            guide la filiale mécène, à sa demande, à travers les correspondants mécénat locaux pour porter les nouvelles
            ambitions</p>
          <router-link to="/actions" class="arrow">
            <div>
              <svg>
                <use xlink:href="#arrow"></use>
              </svg>
            </div>
            Découvrez nos actions
          </router-link>
        </div>
      </div>
    </div>

    <div id="presidente" class="grid-noGutter mb100">
      <div class="col-6_md-12 profil">
        <img :src="$root.publicPath + 'img/president.png'"/>
        <p class="author"><strong>Youssef Achour</strong> Président Fondation d'entreprise Up</p>
      </div>
      <div class="col-6_md-12 citation">
        <h2 class="orange">“notre politique de mécénat concrétise le pouvoir <br/>d'agir ensemble”</h2>
        <p class="margin">«&nbsp;La Fondation du groupe Up montre à travers ses nombreuses actions de soutien à la
          société civile, la volonté et la capacité du groupe Up à s’engager en faveur du bien commun. <br/>Sur ce
          terreau porteur, notre politique de mécénat se donne l’ambition de structurer, coordonner et développer ces
          actions dans l’esprit des valeurs de l’ESS&nbsp;».</p>
        <router-link to="engagements" class="arrow">
          <div>
            <svg>
              <use xlink:href="#arrow"></use>
            </svg>
          </div>
          Découvrez notre engagement
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mission',

  data() {
    return {
      countries: {},
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
        autoPlay: 1000,
        pauseAutoPlayOnHover: true,
        cellAlign: 'left'
      }
    }
  },

  mounted() {
    this.fetch();
  },

  beforeDestroy() {
    $('div.countries').hide();
  },

  methods: {
    async fetch() {

      await axios.get(this.$root.publicURI + 'api/getCountries', {})
          .then(res => {
            this.countries = res.data;
          })
          .catch(function (error) {

          });
    }
  }
}
</script>


<style scoped lang="scss">
h1 {
  font-size: calc(50px + (120 - 50) * ((100vw - 300px) / (1024 - 300))) !important;
  line-height: calc(48px + (120 - 48) * ((100vw - 300px) / (1024 - 300))) !important;
}

#mission div.header {
  max-width: 900px;
  margin: 100px auto;
}

#mission div.header h3 {
  position: relative;
  float: right;
  top: -45px;
  z-index: 2;
}

#mission div.intro {
  position: relative;
}

#mission div.intro h4 {
  font-weight: 400;
}

#mission div.intro div.pict {
  position: relative;
  margin: 20px auto;
}

#mission div.intro div.box {
  position: absolute;
  max-width: 480px;
  padding: 30px 50px;
  background: #fff;
}

#mission div.intro div.box .before {
  position: absolute;
  width: 6px;
  height: 100%;
  top: 0;
  left: 0;
  background: #f59100;
}

#mission div.intro div.box .after {
  position: absolute;
  width: 6px;
  height: 100%;
  top: 0;
  right: 0;
  background: #f59100;
}

#mission div.intro div.box .before:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 6px;
  top: 0;
  left: 0;
  background: #f59100;
}

#mission div.intro div.box .before:after {
  position: absolute;
  content: '';
  width: 30px;
  height: 6px;
  bottom: 0;
  left: 0;
  background: #f59100;
}

#mission div.intro div.box .after:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 6px;
  top: 0;
  right: 0;
  background: #f59100;
}

#mission div.intro div.box .after:after {
  position: absolute;
  content: '';
  width: 30px;
  height: 6px;
  bottom: 0;
  right: 0;
  background: #f59100;
}

#world {
  position: relative;
}

#world div.content {
  margin-top: 50px;
}

#world div.map {
  position: relative;
}

#world div.txt {
  padding-top: 50px;
}

#world div.countries {
  position: absolute;
  left: 60%;
  bottom: 150px;
  width: 30vw;
  z-index: 2;
  background: rgba(255, 255, 255, .9); /*box-shadow:1px 1px 10px rgba(232,232,232,.5);*/
  overflow: hidden;
}

#world div.countries a {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 800;
  color: #f59100;
  text-transform: uppercase;
  padding: 15px 10px 12px;
  white-space: nowrap;
}

#world div.countries a:hover {
  text-decoration: underline;
}

#presidente {
  max-width: 1180px;
  margin: 0 auto;
}

#presidente img {
  display: block;
  margin: 0 auto 20px;
  width: 400px;
  max-width: 100%;
}

#presidente p.author {
  position: relative;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

#presidente p.author strong {
  display: block;
}

#presidente p.author:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 30px;
  background: #f59100;
  top: -35px;
  left: 50%;
}

#presidente div.citation {
  margin-top: 60px;
}


@media (max-width: 80em) {
  #world div.countries {
    position: relative;
    left: auto;
    bottom: auto;
  }
  #world div.txt {
    padding-top: 0;
  }
  #world div.countries {
    left: 12vw;
    bottom: 0;
    width: 40vw;
  }
}

@media (max-width: 64em) {
  #mission div.header {
    margin: 50px auto;
  }
  #mission div.header h3 {
    float: right;
    top: auto;
  }
  #mission div.intro div.box {
    position: relative;
    padding: 30px;
  }
  #world div.countries {
    left: 25%;
    width: 50%;
  }
}

@media (max-width: 48em) {
  #mission div.header h1 {
    margin-bottom: 20px;
  }
  #world div.countries {
    left: 0;
    width: 100%;
  }
}

</style>