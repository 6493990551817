var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"mission"}},[_vm._m(0),_c('div',{staticClass:"intro mb100"},[_c('h4',[_vm._v("La fondation Up porte désormais l’ambition...")]),_c('div',{staticClass:"pict"},[_vm._m(1),_c('img',{attrs:{"src":_vm.$root.publicPath + 'img/picture/mission.jpg'}})])]),_c('div',{staticClass:"mb100",attrs:{"id":"world"}},[_vm._m(2),_c('div',{staticClass:"grid-noGutter content"},[_c('div',{staticClass:"col-9_lg-8_md-12 map"},[_c('img',{attrs:{"src":_vm.$root.publicPath + 'img/world.png'}}),_c('div',{staticClass:"countries"},[(_vm.countries.length > 0)?_c('flickity',{ref:"flickity",staticClass:"flickity",attrs:{"options":_vm.flickityOptions}},_vm._l((_vm.countries),function(country,index){return _c('div',{key:index,staticClass:"carousel-cell"},[_c('router-link',{attrs:{"to":'actions#' + country.id}},[_vm._v(_vm._s(country.nom))])],1)}),0):_vm._e()],1)]),_c('div',{staticClass:"col-3_lg-4_md-12 txt"},[_c('p',{staticClass:"margin"},[_vm._v("Elle conduit la politique de mécénat à travers tous les pays d’implantation du groupe Up et\n          guide la filiale mécène, à sa demande, à travers les correspondants mécénat locaux pour porter les nouvelles\n          ambitions")]),_c('router-link',{staticClass:"arrow",attrs:{"to":"/actions"}},[_c('div',[_c('svg',[_c('use',{attrs:{"xlink:href":"#arrow"}})])]),_vm._v("\n          Découvrez nos actions\n        ")])],1)])]),_c('div',{staticClass:"grid-noGutter mb100",attrs:{"id":"presidente"}},[_c('div',{staticClass:"col-6_md-12 profil"},[_c('img',{attrs:{"src":_vm.$root.publicPath + 'img/president.png'}}),_vm._m(3)]),_c('div',{staticClass:"col-6_md-12 citation"},[_vm._m(4),_vm._m(5),_c('router-link',{staticClass:"arrow",attrs:{"to":"engagements"}},[_c('div',[_c('svg',[_c('use',{attrs:{"xlink:href":"#arrow"}})])]),_vm._v("\n        Découvrez notre engagement\n      ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header clearfix"},[_c('h1',[_c('span',[_vm._v("Notre ")]),_vm._v(" mission")]),_c('h3',[_vm._v("Depuis 2017, et après 18 ans d’action "),_c('br',{staticClass:"sm-hidden"}),_vm._v("sous l’égide de la Fondation de "),_c('br',{staticClass:"sm-hidden"}),_vm._v("France, le groupe Up a décidé de "),_c('br',{staticClass:"sm-hidden"}),_vm._v("créer sa Fondation d’entreprise\n    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"before"}),_c('div',{staticClass:"after"}),_c('p',[_c('strong',[_vm._v("...d’impulser le pouvoir d’agir ensemble, aux personnes en situation d’isolement ou de dépendance\n          (« les invisibles »), toutes générations confondues, en leur permettant d’accéder à l’essentiel pour\n          s’épanouir plus librement.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"center"},[_vm._v("La Fondation Up définit le cadre général des actions "),_c('br'),_vm._v("de mécénat pour l’ensemble du groupe\n      Up.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"author"},[_c('strong',[_vm._v("Youssef Achour")]),_vm._v(" Président Fondation d'entreprise Up")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"orange"},[_vm._v("“notre politique de mécénat concrétise le pouvoir "),_c('br'),_vm._v("d'agir ensemble”")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"margin"},[_vm._v("« La Fondation du groupe Up montre à travers ses nombreuses actions de soutien à la\n        société civile, la volonté et la capacité du groupe Up à s’engager en faveur du bien commun. "),_c('br'),_vm._v("Sur ce\n        terreau porteur, notre politique de mécénat se donne l’ambition de structurer, coordonner et développer ces\n        actions dans l’esprit des valeurs de l’ESS ».")])
}]

export { render, staticRenderFns }