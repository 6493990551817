<template>
  <div id="app">
    <svg-icons></svg-icons>
    <div id="wrap" :class="$route.name">
      <header-view v-if="$route.name != 'rapport'"></header-view>
      <transition name="default" mode="out-in">
        <router-view :key="$route.fullPath"/>
      </transition>
      <div id="push" v-if="$route.name != 'rapport'"></div>
    </div>
    <footer-view></footer-view>
    <back-to-top></back-to-top>
    <viewport-check :active="false"></viewport-check>
  </div>
</template>


<script>
export default {
  name: 'app'
}
</script>

