import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    //base: process.env.VUE_APP_BASE_URI,
    // base: '/upf/public/',

    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    },

    routes: [
        {
            path: '/',
            name: 'home',
            component: require('./views/home.vue').default,
            meta: {
                title: 'Fondation Up',
            }
        },
        {
            path: '/mission',
            name: 'mission',
            component: require('./views/mission.vue').default,
            meta: {
                title: 'Fondation Up : Mission',
            }
        },
        {
            path: '/gouvernance',
            name: 'gouvernance',
            component: require('./views/gouvernance.vue').default,
            meta: {
                title: 'Fondation Up : Gouvernance',
            }
        },
        {
            path: '/fonctionnement',
            name: 'fonctionnement',
            component: require('./views/fonctionnement.vue').default,
            meta: {
                title: 'Fondation Up : Fonctionnement',
            }
        },
        {
            path: '/engagements',
            name: 'engagements',
            component: require('./views/engagements.vue').default,
            meta: {
                title: 'Fondation Up : Engagements',
            }
        },
        {
            path: '/actions',
            name: 'actions',
            component: require('./views/actions.vue').default,
            meta: {
                title: 'Fondation Up : Nos actions',
            }
        },
        {
            path: '/actions/:slug',
            name: 'action_rub',
            component: require('./views/actions.vue').default,
            meta: {
                title: 'Fondation Up : Nos actions',
            }
        },
        {
            path: '/action/:slug',
            name: 'action',
            component: require('./views/action.vue').default,
            meta: {
                title: 'Fondation Up : Nos actions',
            }
        },
        {
            path: '/publications',
            name: 'publications',
            component: require('./views/publications.vue').default,
            meta: {
                title: 'Fondation Up : Publications',
            }
        },
        {
            path: '/contact',
            name: 'contact',
            component: require('./views/contact.vue').default,
            meta: {
                title: 'Fondation Up : Contact',
            }
        },
        {
            path: '/plan-du-site',
            name: 'plan-du-site',
            component: require('./views/sitemap.vue').default,
            meta: {
                title: 'Fondation Up : Plan du site',
            }
        },
        {
            path: '/mentions-legales',
            name: 'mentions-legales',
            component: require('./views/mentions-legales.vue').default,
            meta: {
                title: 'Fondation Up : Mentions légales',
            }
        },
        {
            path: '/donnees-personnelles',
            name: 'donnees-personnelles',
            component: require('./views/donnees-personnelles.vue').default,
            meta: {
                title: 'Fondation Up : Politique des données personnelles',
            }
        },
        {
            path: '/politique-de-confidentialite',
            name: 'politique-de-confidentialite',
            component: require('./views/politique-de-confidentialite.vue').default,
            meta: {
                title: 'Fondation Up : Politique de confidentialité',
            }
        },

        /*
        {
            path: '/depot-projet',
            name: 'projet',
            component: require('./views/depot-projet.vue').default,
            meta: {
                title : 'Fondation Up : Déposer un projet en ligne',
            }
        },
         {
            path: '/depot-projet-formulaire',
            name: 'projetform',
            component: require('./views/depot-projet-iframe.vue').default,
            meta: {
                title : 'Fondation Up : Déposer un projet en ligne',
            }
       },
       */

        {
            path: '/rapports',
            name: 'rapports',
            component: require('./views/rapports.vue').default,
            meta: {
                title: 'Fondation Up : Rapports annuels',
            }
        },
        {
            path: '/rapport/:slug',
            name: 'rapport',
            component: require('./views/rapport.vue').default,
            meta: {
                title: 'Fondation Up : Rapport annuel',
            }
        },


        {
            path: '*',
            name: '404',
            component: require('./views/404.vue').default,
            meta: {
                title: 'Fondation Up : Page introuvable',
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
})

export default router;