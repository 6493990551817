<template>
  <div id="footer">
    <div class="wrap grid-noGutter">
      <div class="col-3_md-12 left">
        <a href="https://up.coop/" target="_blank" id="up"><img :src="$root.publicPath + 'img/up.png'"/></a>
      </div>
      <div class="col-9_md-12 right">
        <div class="grid grid-3-noGutter">
          <!--
          <div class="col-5_md-6_sm-12">
            <router-link to="/depot-projet" class="dossier"><p><span>Déposer</span> <strong>votre projet</strong></p></router-link>
          </div>
          -->
          <div class="col-9_md-12_sm-12">
            <router-link to="/contact" class="contact">
              <svg>
                <use xlink:href="#contact"></use>
              </svg>
              <p>Contacter l'équipe <br/>de la Fondation</p></router-link>
          </div>
          <div class="col-3_md-12_sm-12">
            <ul>
              <li>
                <router-link to="/mentions-legales">Mentions légales</router-link>
              </li>
              <li>
                <router-link to="/politique-de-confidentialite">Politique de confidentialité</router-link>
              </li>
              <li><a href="javascript:Didomi.preferences.show()">Gestion des cookies</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView"
}
</script>

<style scoped lang="scss">
#footer {
  height: 250px;
  margin: 0 auto;
  background: #f7f7f7;
}

#footer div.wrap {
  position: relative;
  max-width: 1660px;
  height: 100%;
  margin: 0 auto;
}

#footer div.wrap:before {
  position: absolute;
  content: '';
  top: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background: #efefef;
}

#footer div.left {
  padding: 60px 80px;
  background: #efefef;
  text-align: right;
}

#footer div.right {
  padding: 30px 80px;
  background: #f7f7f7;
}

#footer div.right div.grid div {
  padding: 30px 0;
  background: #f7f7f7;
}

#footer a.dossier:after {
  background: #f7f7f7;
}

#footer a.contact {
  font-weight: 700;
}

#footer a.contact svg {
  width: 30px;
  height: 30px;
  fill: #f59100;
}

#footer ul {
  display: inline-block;
  list-style: none;
  border-left: 1px solid #d0d0d0;
  padding: 15px 0 15px 3vw;
}

#footer li a {
  font-size: 10px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}


@media (max-width: 64em) {
  #footer {
    text-align: center;
  }
  #footer div.left {
    padding: 30px;
    text-align: center;
  }
  #footer div.right {
    padding: 30px;
  }
  #footer ul {
    widtsh: 30%;
    border-left: 0;
    border-top: 1px solid #eee;
    padding: 20px 0 80px;
  }
  #footer li {
    display: inline-block;
    margin: 0 30px;
  }
  #footer #up img {
    width: 60px;
  }
}
</style>




