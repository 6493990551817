<template>
  <div id="header">
    <div class="wrap grid-noGutter-spaceBetween-noBottom">
      <router-link to="/" id="logo"><img :src="$root.publicPath + 'img/fondation-up.png'"
                                         v-on:click="$root.closeMobileNav()"/></router-link>
      <div id="nav" class="grid-noGutter-bottom">
        <div>
          <span class="rub"
                :class="{ 'active' : $route.name === 'mission' || $route.name === 'gouvernance' || $route.name === 'fonctionnement' }">La fondation</span>
          <div>
            <div>
              <div>
                <router-link to="/mission">Mission</router-link>
              </div>
              <div>
                <router-link to="/gouvernance">Gouvernance</router-link>
              </div>
              <div>
                <router-link to="/fonctionnement">Fonctionnement</router-link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <router-link to="/engagements" :class="{ 'active' : $route.name === 'engagements' }">Engagements</router-link>
        </div>
        <div>
          <router-link to="/actions" :class="{ 'active' : $route.name === 'actions' || $route.name === 'action' }">
            Actions
          </router-link>
        </div>
        <div>
          <router-link to="/publications" :class="{ 'active' : $route.name === 'publications' }">Publications
          </router-link>
        </div>
        <div class="langs">
          <p>
            <svg class="earth">
              <use xlink:href="#earth"></use>
            </svg>
            <span>
							<span>FR</span>
							<svg class="arrow"><use xlink:href="#arrow-bottom"></use></svg>
						</span>
          </p>
          <div>
            <div>
              <div><a href="#">FR</a></div>
              <div><a href="#">EN</a></div>
            </div>
          </div>
        </div>
        <div class="menu"><a href="#" v-on:click.prevent="$root.toggleMobileNav()"
                             :class="{ 'active' : $parent.mobileNav }"><span>Menu</span></a></div>
      </div>


      <transition name="mobilenav-in" mode="out-in" appear>
        <div id="mobilenav" v-if="$root.mobileNav" v-scroll-lock="$root.mobileNav">
          <div class="inner grid-noGutter-spaceBetween-noBottom-column">
            <div class="pages">
              <div>
								<span
                    :class="{ 'active' : $route.name === 'mission' || $route.name === 'gouvernance' || $route.name === 'fonctionnement' }">
									La fondation 
									<svg class="arrow"><use xlink:href="#arrow-bottom"></use></svg>
								</span>
                <div>
                  <div>
                    <div>
                      <router-link to="/mission">Mission</router-link>
                    </div>
                    <div>
                      <router-link to="/gouvernance">Gouvernance</router-link>
                    </div>
                    <div>
                      <router-link to="/fonctionnement">Fonctionnement</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <router-link to="/engagements" :class="{ 'active' : $route.name === 'engagements' }">Engagements
                </router-link>
              </div>
              <div>
                <router-link to="/actions" :class="{ 'active' : $route.name === 'actions' }">Actions</router-link>
              </div>
              <div>
                <router-link to="/publications" :class="{ 'active' : $route.name === 'publications' }">Publications
                </router-link>
              </div>
            </div>

            <div class="more">
              <router-link to="/depot-projet" class="dossier"><p><span>Déposer</span> <strong>un projet en
                ligne</strong></p></router-link>
            </div>
          </div>
        </div>
      </transition>


    </div>
  </div>

</template>

<script>
export default {
  name: "HeaderView"
}
</script>

<style scoped lang="scss">
#header {
  display: block;
  height: 130px;
  padding: 40px 0 0;
  border-bottom: 1px solid #c1c1c1;
}

#header #logo img {
  max-width: 50vw;
}

#nav {
  margin-bottom: 3px;
}

#nav > div {
  position: relative;
  margin-left: 4vw;
}

#nav a {
  font-size: 16px;
  font-weight: 600;
  color: #999;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
}

#nav span.rub {
  display: block;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #999;
  text-transform: uppercase;
  cursor: pointer;
}

#nav a:hover,
#nav .active {
  color: #f59100 !important;
}

#nav div.menu {
  display: none;
}

#nav div.menu a {
  color: #1d1d1d;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-transform: none;
  background: url('../../img/picto/menu.png') no-repeat 0 0;
  padding-left: 26px;
}

#nav div.menu a:hover,
#nav div.menu a.active {
  color: #f59100;
  background-position: 0 -24px;
}

#nav div.menu a.active:hover {
  color: #1d1d1d !important;
  background-position: 0 0;
}

#nav > div > div {
  position: absolute;
  z-index: 2;
  padding-top: 25px;
  display: none;
}

#nav > div > div > div {
  background: #f59100;
  padding: 20px 30px;
}

#nav > div:hover div {
  display: block;
}

#nav > div:hover span.rub {
  color: #f59100;
}

#nav > div div a {
  font-size: 14px;
  line-height: 40px;
  color: #fff;
  white-space: nowrap;
}

#nav > div div a:hover {
  color: #1d1d1d !important;
}

#nav div.langs {
  position: relative;
  top: 3px;
  display: none;
}

#nav div.langs p {
  position: relative;
  padding-left: 30px;
  margin: 0;
}

#nav div.langs p > span {
  position: relative;
  padding-right: 20px;
}

#nav svg.earth {
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 20px;
  stroke: #f59100;
}

#nav svg.arrow {
  position: absolute;
  top: 10px;
  right: 0;
  width: 10px;
  height: 10px;
  fill: #f59100;
}

#mobilenav {
  position: fixed;
  top: 149px;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f59100;
  z-index: 9999;
}

#mobilenav div.inner {
  width: 100%;
  height: calc(100vh - 120px);
  padding: 10vw;
  flex-wrap: nowrap;
}

#mobilenav div.inner > div {
  width: 100% !important;
}

#mobilenav div.pages a,
#mobilenav div.pages span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 10px 20px 10px 0;
  padding: 1.5vh 20px 1.5vh 0;
}

#mobilenav div.pages a:hover,
#mobilenav div.pages span:hover,
#mobilenav div.pages a.on,
#mobilenav div.pages span.on {
  color: #905500 !important;
}

#mobilenav div.pages span {
  font-weight: 700;
  color: #905500 !important;
}

#mobilenav div.pages > div > div {
  display_: none;
  background_: green;
  margin: 5px 0 30px 20px;
}

#mobilenav svg.arrow {
  position: absolute;
  top: 15px;
  right: -5px;
  width: 10px;
  height: 10px;
  fill: #905500;
}

#mobilenav div.more {
  align-self: flex-end;
}

#mobilenav a.dossier {
  border: 5px solid #fff;
  padding: 15px 20px;
  padding: 10px;
  max-width: 200px;
}

#mobilenav a.dossier p {
  color: #fff;
}

#mobilenav a.dossier:after {
  background: #f59100;
}

#mobilenav a.dossier:hover {
  border-color: #905500;
}

#mobilenav a.dossier:hover p {
  color: #905500;
}

.mobilenav-in-enter-active {
  animation: mobilenav-anim-in .5s;
}

.mobilenav-in-leave-active {
  animation: mobilenav-anim-out .5s;
  display_: none;
}

@keyframes mobilenav-anim-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes mobilenav-anim-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

/*
v-enter
v-enter-active
v-enter-to
v-leave
v-leave-active
v-leave-to
*/

@media (max-width: 100em) {
  #nav > div {
    margin-left: 3.5vw;
  }
}

@media (max-width: 80em) {
  #nav > div {
    margin-left: 2.5vw;
  }
}

@media (max-width: 64em) {
  #nav > div {
    display: none;
  }
  #nav > div.menu {
    display: block;
  }
}

@media (max-width: 48em) {
  #header {
    height: 120px;
    padding: 40px 0;
  }
  #mobilenav {
    top: 119px;
  }
  #mobilenav div.inner {
    height: calc(100vh - 90px);
  }
}

@media (max-width: 36em) {
  #header {
    height: 90px;
    padding: 30px 0;
  }
  #header #logo img {
    width: 150px;
  }
  #mobilenav {
    top: 89px;
  }
  #mobilenav div.inner {
    height: calc(100vh - 90px);
  }
}
</style>
