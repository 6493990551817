<template>
  <div id="engagements">
    <div class="header">
      <h1><span>Nos </span> engagements</h1>
    </div>
    <div class="intro">
      <div class="grid-noGutter">
        <div class="col-4_sm-12">
          <div class="txt1">
            <h3>Faire du bien au quotidien<br/>des publics les plus fragiles</h3>
            <p>La Fondation d’entreprise Up s’engage à redonner du pouvoir d’agir ensemble, aux personnes en situation
              d’isolement ou de dépendance, toutes générations confondues, en leur permettant d’accéder à l’essentiel
              pour s’épanouir plus librement.</p>
          </div>
        </div>
        <div class="col-1_sm-12"></div>
        <div class="col-7_sm-12">
          <img :src="$root.publicPath + 'img/picture/engagement1.jpg'"/>
        </div>
      </div>

      <div class="txt2">
        <h3>4 domaines d’action essentiels</h3>
        <p>Vecteur de lien social pour développer le pouvoir d'agir et lutter contre l'exclusion</p>
      </div>
    </div>


    <div id="engactions">
      <div class="nav grid-noBottom-noGutter">
        <div class="col-3" v-for="(rubrique, index) in rubriques" :key="rubrique.id">
          <a href="#" class="grid-noBottom-noGutter-middle-center"
             :class="{ rub1 : rubrique.id==1, rub2 : rubrique.id==2, rub3 : rubrique.id==3, rub4 : rubrique.id==4, on : current_rub== rubrique.id}"
             v-on:click.prevent="setRub(rubrique.id)">
            <svg>
              <use :xlink:href="'#'+rubrique.code"></use>
            </svg>
          </a>
        </div>
      </div>

      <div class="item rub1" :class="{ on : current_rub == 1 }">
        <div class="cover" :style="{ backgroundImage : 'url(' + $root.publicPath + 'img/picture/alimentation.jpg)' }">
          <img :src="$root.publicPath + 'img/picture/alimentation.jpg'"/>
        </div>
        <div class="inner">
          <div class="grid-noBottom-noGutter-spaceBetween">
            <div class="col-8_md-12">
              <h2>Alimentation</h2>
              <p class="margin">
                Pour tous c’est se nourrir. <br/>
                Notre ambition c’est de favoriser la production, le partage et «&nbsp;l’agir&nbsp;ensemble&nbsp;».
              </p>
              <router-link to="/actions/alimentation" class="arrow">
                <div>
                  <svg>
                    <use xlink:href="#arrow"></use>
                  </svg>
                </div>
                Découvrez nos actions
              </router-link>
              <!--
              <br />
              <router-link to="/depot-projet" class="dossier"><p><span>Déposer</span> <strong>un projet en ligne</strong></p></a></router-link>
              -->
            </div>
            <div class="col-1_md-12"></div>
            <div class="col-3_md-12">
              <div class="nb">
                <img :src="$root.publicPath + 'img/picto/project.png'"/>
                <p><strong>{{ nb_projet.alimentation }}</strong> projets soutenus</p>
              </div>
              <div class="nb">
                <p><strong>{{ aides.alimentation }}€ </strong> engagés</p>
              </div>
              <div class="repartition">
                <h5>Répartition de nos projets par domaine d’action</h5>
                <p>Alimentation <strong>{{ pourcentage.alimentation }}<sup>%</sup></strong></p>
                <div class="bar">
                  <div :style="{'width' : pourcentage.alimentation + '%' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="item rub2" :class="{ on : current_rub == 2 }">
        <div class="cover" :style="{ backgroundImage : 'url(' + $root.publicPath + 'img/picture/logement.jpg)' }">
          <img :src="$root.publicPath + 'img/picture/logement.jpg'"/>
        </div>
        <div class="inner">
          <div class="grid-noBottom-noGutter-spaceBetween">
            <div class="col-8_md-12">
              <h2>Logement</h2>
              <p class="margin">
                Pour tous c’est se loger. <br/>
                Notre ambition c’est de favoriser l’accessibilité, l’autonomie et le « vivre ensemble ».
              </p>
              <router-link to="/actions/logement" class="arrow">
                <div>
                  <svg>
                    <use xlink:href="#arrow"></use>
                  </svg>
                </div>
                Découvrez nos actions
              </router-link>
              <!--
              <br />
              <router-link to="/depot-projet" class="dossier"><p><span>Déposer</span> <strong>un projet en ligne</strong></p></a></router-link>
              -->
            </div>
            <div class="col-1_md-12"></div>
            <div class="col-3_md-12">
              <div class="nb">
                <img :src="$root.publicPath + 'img/picto/project.png'"/>
                <p><strong>{{ nb_projet.logement }}</strong> projets soutenus</p>
              </div>
              <div class="nb">
                <p><strong>{{ aides.logement }}€ </strong> engagés</p>
              </div>
              <div class="repartition">
                <h5>Répartition de nos projets par domaine d’action</h5>
                <p>Logement <strong>{{ pourcentage.logement }}<sup>%</sup></strong></p>
                <div class="bar">
                  <div :style="{'width' : pourcentage.logement + '%' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="item rub3" :class="{ on : current_rub == 3 }">
        <div class="cover" :style="{ backgroundImage : 'url(' + $root.publicPath + 'img/picture/education.jpg)' }">
          <img :src="$root.publicPath + 'img/picture/education.jpg'"/>
        </div>
        <div class="inner">
          <div class="grid-noBottom-noGutter-spaceBetween">
            <div class="col-8_md-12">
              <h2>Éducation et culture</h2>
              <p class="margin">
                Pour tous c’est apprendre et comprendre. <br/>
                Notre ambition c’est de favoriser la compréhension du monde, le libre accès à la culture et le «
                progresser ensemble ».
              </p>
              <router-link to="/actions/education" class="arrow">
                <div>
                  <svg>
                    <use xlink:href="#arrow"></use>
                  </svg>
                </div>
                Découvrez nos actions
              </router-link>
              <!--
              <br />
              <router-link to="/depot-projet" class="dossier"><p><span>Déposer</span> <strong>un projet en ligne</strong></p></a></router-link>
              -->
            </div>
            <div class="col-1_md-12"></div>
            <div class="col-3_md-12">
              <div class="nb">
                <img :src="$root.publicPath + 'img/picto/project.png'"/>
                <p><strong>{{ nb_projet.education }}</strong> projets soutenus</p>
              </div>
              <div class="nb">
                <p><strong>{{ aides.education }}€ </strong> engagés</p>
              </div>
              <div class="repartition">
                <h5>Répartition de nos projets par domaine d’action</h5>
                <p>Éducation <strong>{{ pourcentage.education }}<sup>%</sup></strong></p>
                <div class="bar">
                  <div :style="{'width' : pourcentage.education + '%' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="item rub4" :class="{ on : current_rub == 4 }">
        <div class="cover" :style="{ backgroundImage : 'url(' + $root.publicPath + 'img/picture/sante.jpg)' }">
          <img :src="$root.publicPath + 'img/picture/sante.jpg'"/>
        </div>
        <div class="inner">
          <div class="grid-noBottom-noGutter-spaceBetween">
            <div class="col-8_md-12">
              <h2>Santé</h2>
              <p class="margin">
                Pour tous c’est prendre soin de soi. <br/>
                Notre ambition c’est de favoriser l’épanouissement des patients comme de leur entourage, des soignants
                et « faire face ensemble ».
              </p>
              <router-link to="/actions/sante" class="arrow">
                <div>
                  <svg>
                    <use xlink:href="#arrow"></use>
                  </svg>
                </div>
                Découvrez nos actions
              </router-link>
              <!--
              <br />
              <router-link to="/depot-projet" class="dossier"><p><span>Déposer</span> <strong>un projet en ligne</strong></p></a></router-link>
              -->
            </div>
            <div class="col-1_md-12"></div>
            <div class="col-3_md-12">
              <div class="nb">
                <img :src="$root.publicPath + 'img/picto/project.png'"/>
                <p><strong>{{ nb_projet.sante }}</strong> projets soutenus</p>
              </div>
              <div class="nb">
                <p><strong>{{ aides.sante }}€ </strong> engagés</p>
              </div>
              <div class="repartition">
                <h5>Répartition de nos projets par domaine d’action</h5>
                <p>Santé <strong>{{ pourcentage.sante }}<sup>%</sup></strong></p>
                <div class="bar">
                  <div :style="{'width' : pourcentage.sante + '%' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="engagekeys" class="mb100 clearfix">
      <h3 class="orange center">Les 6 clés de notre engagement</h3>
      <div class="grid grid-noBottom-noGutter-spaceBetween-equalHeight">
        <div class="col-4_md-12" v-for="(item, index) in engagements">
          <div class="item grid-noBottom-noGutter-middle-center" v-bind:class="{ 'on' : current_key==(index+1) }"
               v-on:click.prevent="setKey(index+1)">
            <div>
              <h3 v-html="item.titre"></h3>
              <p v-html="item.txt"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="link-actions">
        <router-link to="/actions" class="arrow">
          <div>
            <svg>
              <use xlink:href="#arrow"></use>
            </svg>
          </div>
          Découvrez nos actions
        </router-link>
      </div>
    </div>


    <div id="engagetotal" class="grid-noBottom-noGutter-middle-spaceBetween mb100">
      <div class="col-6_md-12"><h2 class="orange">Notre engagement mécénat <br class="sm-hidden"/>en quelques chiffres
      </h2></div>
      <div class="col-6_md-12">
        <div class="box grid-noBottom-noGutter-middle-center-spaceBetween">
          <div>
            <svg>
              <use xlink:href="#reload"></use>
            </svg>
            <template v-for="(item, index) in chiffres">
              <transition name="key-in" enter-active-class="animated fadeIn" leave-active-class="animated hidden"
                          mode="out-in" appear>
                <p v-if="currentKey==index"><strong v-html="item.value"></strong> {{ item.label }}</p>
              </transition>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Engagements from '../json/engagements.json'

export default {
  name: 'engagements',

  data() {
    return {
      rubriques: {},
      chiffres: {},
      engagements: {},
      nb_projet: {},
      aides: {},
      pourcentage: {},
      current_rub: 1,
      current_key: 0,
      timer: null,
      currentNumber: 0
    }
  },

  computed: {
    currentKey: function () {
      return Math.abs(this.currentNumber) % this.chiffres.length;
    }
  },

  mounted() {
    this.fetch();
    this.startRotation();
  },

  methods: {

    async fetch() {

      this.engagements = Engagements;

      await axios.get(this.$root.publicURI + 'api/getRubriques', {})
          .then(res => {
            this.rubriques = res.data;
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getStats', {})
          .then(res => {
            this.nb_projet = res.data.nb_projet;
            this.aides = res.data.aides;
            this.pourcentage = res.data.pourcentage;
          })
          .catch(function (error) {

          });

      await axios.get(this.$root.publicURI + 'api/getChiffres', {})
          .then(res => {
            this.chiffres = res.data;
          })
          .catch(function (error) {

          });
    },


    setRub(id) {
      this.current_rub = id;
    },

    setKey(id) {
      this.current_key = id;
    },


    startRotation: function () {
      this.timer = setInterval(this.nextKey, 3000);
    },

    /*stopRotation: function() {
      clearTimeout(this.timer);
      this.timer = null;
    },*/

    nextKey: function () {
      this.currentNumber += 1
    }
  }
}
</script>


<style scoped lang="scss">
#engagements div.header {
  margin: 100px 0 30px;
}

#engagements div.intro {
  margin-left: 100px;
  margin-bottom: 100px;
}

#engagements div.intro div.txt1 {
  margin-top: 100px;
  max-width: 460px;
}

#engagements div.intro div.txt2 {
  margin-top: 100px;
}

#engagements div.intro img {
  position: relative;
  margin-top: -80px;
}

#engactions {
  position: relative;
  margin-bottom: 250px;
}

#engactions div.nav {
  position: absolute;
  top: 0;
  right: 0;
  width: 600px;
  z-index: 1;
}

#engactions div.nav a {
  position: relative;
  width: 150px;
  height: 150px;
  background: #fff;
  border: solid #dadada;
  border-width: 1px 1px 1px 0;
}

#engactions div.nav a:first-child {
  border-width: 1px;
}

#engactions div.nav a svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  width: 35%;
  height: 35%;
  fill: #c6c6c6;
}

#engactions div.nav a:hover svg,
#engactions div.nav a.on svg {
  fill: #fff;
}

#engactions div.nav a.rub1:hover,
#engactions div.nav a.rub1.on {
  background: #8cd096;
}

#engactions div.nav a.rub2:hover,
#engactions div.nav a.rub2.on {
  background: #bcc5d9;
}

#engactions div.nav a.rub3:hover,
#engactions div.nav a.rub3.on {
  background: #a1c5c3;
}

#engactions div.nav a.rub4:hover,
#engactions div.nav a.rub4.on {
  background: #c7e9f9;
}

#engactions div.item {
  position: relative;
  display: none;
  transform: translateX(-100%);
  transition: transform .3s ease;
}

#engactions div.item.on {
  display: block;
  transform: translateX(0);
}

#engactions div.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1130px;
  height: calc(100% + 150px);
}

#engactions div.cover img {
  display: none;
}

#engactions div.inner {
  position: relative;
  padding: 100px 0 100px 100px;
  background: #fff;
  margin: 0 0 0 33.33%;
  top: 150px;
}

#engactions div.inner div.nb p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0 50px;
}

#engactions div.inner div.nb p strong {
  position: relative;
  top: -2px;
  display: block;
  font-size: 40px;
  line-height: 40px;
  font-weight: 800;
  margin-bottom: 8px;
}

#engactions div.inner h5 {
  margin-bottom: 10px;
}

#engactions div.repartition p {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 10px;
}

#engactions div.repartition p strong {
  color: #9cce9b;
}

#engactions a.dossier {
  border: 3px solid #f59100;
  padding: 10px 20px;
  margin: 40px 0 30px;
}

#engactions a.dossier:after {
  width: 3px;
  right: -3px;
}

#engactions a.dossier p {
  font-size: 18px;
}

#engactions div.repartition div.bar {
  position: relative;
  display: block;
  height: 5px;
  background: #f1f1f1;
  border-radius: 3px;
}

#engactions div.repartition div.bar div {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 5px;
  border-radius: 3px;
}

#engactions div.rub1 h2,
#engactions div.rub1 div.inner div.nb p strong,
#engactions div.rub1 div.inner div.engage p strong,
#engactions div.rub1 div.repartition p strong {
  color: #8cd096;
}

#engactions div.rub1 div.repartition div.bar div {
  background: #8cd096;
}

#engactions div.rub2 h2,
#engactions div.rub2 div.inner div.nb p strong,
#engactions div.rub2 div.inner div.engage p strong,
#engactions div.rub2 div.repartition p strong {
  color: #bcc5d9;
}

#engactions div.rub2 div.repartition div.bar div {
  background: #bcc5d9;
}

#engactions div.rub3 h2,
#engactions div.rub3 div.inner div.nb p strong,
#engactions div.rub3 div.inner div.engage p strong,
#engactions div.rub3 div.repartition p strong {
  color: #a1c5c3;
}

#engactions div.rub3 div.repartition div.bar div {
  background: #a1c5c3;
}

#engactions div.rub4 h2,
#engactions div.rub4 div.inner div.nb p strong,
#engactions div.rub4 div.inner div.engage p strong,
#engactions div.rub4 div.repartition p strong {
  color: #c7e9f9;
}

#engactions div.rub4 div.repartition div.bar div {
  background: #c7e9f9;
}

#engagekeys div.grid {
  margin-bottom: 50px;
}

#engagekeys div.item {
  border: 1px solid #dadada;
  padding: 60px;
  min-height: 340px;
  text-align: center;
  cursor: pointer;
}

#engagekeys div.item h3 {
  color: #acacac;
  margin: 0;
}

#engagekeys div.item p {
  display: none;
  padding-top: 15px;
}

#engagekeys div.item:hover h3 {
  color: #f59100;
}

#engagekeys div.item.on {
  background: #f59100;
}

#engagekeys div.item.on h3 {
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

#engagekeys div.item.on p {
  display: block;
  color: #fff;
}

#engagekeys div.link-actions {
  text-align: center;
}

#engagetotal {
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
}

#engagetotal div.box {
  position: relative;
  display: flex;
  justify-content: center;
  border: 5px solid #f59100;
  padding: 40px 10%;
}

#engagetotal div.box:after {
  position: absolute;
  content: '';
  width: 5px;
  height: 50px;
  top: 50%;
  right: -5px;
  margin-top: -25px;
  background: #fff;
}

#engagetotal div.box div {
  position: relative;
  padding-left: 120px;
}

#engagetotal div.box p {
  position: relative;
  min-height: 60px;
  width: 240px;
  max-width: 100%;
}

#engagetotal div.box p strong {
  display: block;
  font-size: 44px;
  line-height: 44px;
  white-space: nowrap;
}

#engagetotal div.box svg {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(20deg);
  transform-origin: 50% 50%;
  width: 60px;
  height: 60px;
  fill: #c7c7c7;
  animation: rotating 3s linear infinite;
}

@keyframes rotating {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

@keyframes reload-anim {
  0% {
    transform: translateY(-50%) rotate(20deg);
  }
  33% {
    transform: translateY(-50%) rotate(390deg);
  }
  100% {
    transform: translateY(-50%) rotate(390deg);
  }
}

@media (max-width: 80em) {
  #engactions div.inner {
    padding: 50px 0 50px 50px;
  }
}

@media (max-width: 64em) {
  #engagements div.header {
    margin-top: 50px;
  }
  #engagements div.intro {
    margin-left: 0;
    margin-bottom: 50px;
  }
  #engagements div.intro div.txt1 {
    margin-top: 0;
    max-width: 100%;
  }
  #engagements div.intro img {
    margin-top: 20px;
  }
  #engactions div.nb {
    margin-top: 50px;
  }
  #engagekeys div.item {
    padding: 60px;
    min-height: auto;
  }
}

@media (max-width: 48em) {
  #engactions {
    margin-bottom: 100px;
  }
  #engactions div.nav {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    margin: 0 auto;
  }
  #engactions div.nav a {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
  #engactions div.cover {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 1130px;
    height: calc(100% + 150px);
    background: none !important;
  }
  #engactions div.cover img {
    display: block;
  }
  #engactions div.inner {
    padding: 30px 0;
    margin: 0;
    top: auto;
  }
  #engagekeys div.item {
    padding: 30px;
  }

  #engagetotal div.box div {
    padding: 100px 0 0 0;
    text-align: center;
  }
  #engagetotal div.box svg {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  #engagetotal div.box p strong {
    font-size: 30px;
    line-height: 30px;
    white-space: normal;
  }
  #engagetotal div.box svg {
    top: 30px;
    left: 50%;
    margin-left: -30px;
  }
}
</style>