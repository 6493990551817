<template>
  <div id="page404">
    <h1>404</h1>
    <br/>
    <h4>Cette page n'existe pas ou a été supprimée</h4>
    <br/>
    <router-link to="/" class="btn">Retourner à la page d'accueil</router-link>
  </div>
</template>

<script>
export default {
  name: 'page404'
}
</script>