require('intersection-observer');
import URLSearchParams from '@ungap/url-search-params'
/*
window.bodyScrollLock			= require('body-scroll-lock');
window.disableBodyScroll		= bodyScrollLock.disableBodyScroll;
window.enableBodyScroll			= bodyScrollLock.enableBodyScroll;
window.clearAllBodyScrollLocks	= bodyScrollLock.clearAllBodyScrollLocks;
*/
window._ = require('lodash');
window.$ = window.jQuery = require('jquery');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './css/gridlex.css'
import './css/flickity.css'
import './css/app.css'

Vue.config.productionTip = false

import Flickity from 'vue-flickity'

Vue.component('Flickity', Flickity)

import Vue2TouchEvents from 'vue2-touch-events'
// Vue.use(Vue2TouchEvents)
Vue.use(Vue2TouchEvents, {
    disableClick: true,
    touchClass: '',
    tapTolerance: 10,
    swipeTolerance: 30,
    longTapTimeInterval: 400
})


import VueFriendlyIframe from 'vue-friendly-iframe'

Vue.component('vue-friendly-iframe', VueFriendlyIframe)

// import vueSmoothScroll from 'vue2-smooth-scroll'
// Vue.use(vueSmoothScroll)

import VScrollLock from 'v-scroll-lock'

Vue.use(VScrollLock)

// import 'https://assets.juicer.io/embed.js'
// import 'https://assets.juicer.io/embed.css'
import './js/external.js'

Vue.component('svg-icons', require('./views/components/svg-icons').default);
Vue.component('header-view', require('./views/components/header-view').default);
Vue.component('footer-view', require('./views/components/footer-view').default);
Vue.component('help-depot', require('./views/components/help-depot').default);
Vue.component('back-to-top', require('./views/components/back-to-top').default);
Vue.component('viewport-check', require('./views/components/viewport-check').default);


const vm = new Vue({
    el: '#app',
    data: {
        //publicPath		: 'http://dev.bearideas.fr/upf/public/', // process.env.VUE_APP_URL,
        //publicURI		: '/upf/public/', // process.env.VUE_APP_BASE_URI,
        publicPath: 'https://fondation-up.org/', // process.env.VUE_APP_URL,
        publicURI: '/', // process.env.VUE_APP_BASE_URI,
        mobileNav: false
    },

    watch: {
        '$route': 'changeRoute'
    },

    mounted() {

    },

    methods: {

        toggleMobileNav() {
            if (this.mobileNav) {
                this.closeMobileNav();
            } else {
                this.openMobileNav();
            }
        },

        openMobileNav() {
            this.mobileNav = true;
            this.disable_body_scroll();
        },

        closeMobileNav() {
            this.mobileNav = false;
            this.enable_body_scroll();
        },

        freezeVp(e) {
            e.preventDefault();
        },

        disable_body_scroll() {
            /*
            if (window.addEventListener) {
                window.addEventListener('DOMMouseScroll', this.freezeVp, false);
                window.addEventListener("wheel", this.freezeVp);
                window.addEventListener("touchmove", this.freezeVp);
                window.addEventListener("touchmove", this.freezeVp, false);
                document.body.addEventListener("touchmove", this.freezeVp, { passive: false });
            }
            window.onmousewheel = document.onmousewheel = this.freezeVp;

            const mobilenav = document.querySelector("#mobilenav");
            bodyScrollLock.disableBodyScroll(mobilenav);

            document.body.classList.add('no-scroll');
            */
        },

        enable_body_scroll() {
            /*
            if (window.removeEventListener) {
                window.removeEventListener('DOMMouseScroll', this.freezeVp, false);
                window.removeEventListener("wheel", this.freezeVp);
                window.removeEventListener("touchmove", this.freezeVp);
                window.removeEventListener("touchmove", this.freezeVp, false);
                document.body.removeEventListener("touchmove", this.freezeVp, false);
            }
            window.onmousewheel = document.onmousewheel = null;
            bodyScrollLock.clearAllBodyScrollLocks();

            document.body.classList.remove('no-scroll');
            */
        },

        changeRoute() {
            this.closeMobileNav();
        }
    },
    router,
    render: h => h(require('./App.vue').default),
});
