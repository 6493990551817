<template>
  <div id="back-top" :class="{ visible : visible }" v-on:click.prevent="scrollToTop()"><a href="#"></a></div>
</template>

<script>
export default {
  name: "BackToTop",
  data() {
    return {
      visible: false
    }
  },

  mounted() {
    window.addEventListener('scroll', () => {

      if (window.pageYOffset > window.innerHeight)
        this.visible = true;
      else
        this.visible = false;

    });
  },
  methods: {
    scrollToTop() {
      $('html, body').animate({scrollTop: 0}, 500);
    }

  }
}
</script>
<style scoped lang="scss">
#back-top {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 11;
  width: 55px;
  height: 55px;
  overflow: hidden;
}

#back-top a {
  margin-top: 200px;
  -webkit-transition: all 500ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
  -moz-transition: all 500ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
  -ms-transition: all 500ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
  -o-transition: all 500ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
  transition: all 500ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
  outline: none;
  background-color: transparent;
  display: block;
  text-indent: -999em;
  width: 55px;
  height: 55px;
  background: url('../../img/picto/back-top.png') no-repeat 0 0;
}

#back-top a:hover {
  background-position: 0 -55px;
}

#back-top.visible a {
  margin-top: 0;
}
</style>






