<template>
  <div id="publications">
    <div class="header clearfix">
      <h1><span>Nos </span> publications</h1>
    </div>

    <div class="intro grid-noGutter mb100">
      <div class="col-12_sm-12">
        <h3>Toute l’année, retrouvez l’essentiel des supports et publications réalisées par la Fondation d’entreprise Up
          ou qu’elle juge utile de partager avec vous !</h3>
      </div>
    </div>

    <div id="documents" class="mb100">
      <div class="list grid-center-equalHeight">
        <div class="col-4_md-6_sm-12" v-for="(item, index) in publications">
          <div class="item">
            <div class="txt">
              <h5 class="orange">{{ item.titre }}</h5>
              <p v-html="item.txt"></p>
            </div>
            <div class="infos grid-noGutter-spaceBetween">
              <div><strong>Date </strong>{{ item.date }}</div>
              <div><strong>Format </strong>{{ item.type }}</div>
              <div><a :href="item.link" class="download" target="_blank">
                <svg>
                  <use xlink:href="#download"></use>
                </svg>
              </a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'publications',

  data() {
    return {
      publications: null
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      await axios.get(this.$root.publicURI + 'api/getPublications', {})
          .then(res => {
            this.publications = res.data;
          })
          .catch(function (error) {
            alert(error);
          });
    }
  }
}
</script>


<style scoped lang="scss">
#publications div.header {
  margin: 100px auto 30px;
}

#publications div.header h3 {
  position: relative;
  z-index: 2;
}

#publications div.intro {
  max-width: 1200px;
  margin: 0 auto;
}

#publications div.intro h2 {
  position: relative;
  top: -60px;
  margin: 0;
}

#publications div.intro p {
  margin-bottom: 40px;
}

#documents {
  max-width: 1160px;
  margin: 0 auto;
}

#documents div.list > div {
  margin-bottom: 40px;
}

#documents div.item {
  position: relative;
  padding-bottom: 100px;
}

#documents div.infos {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #000;
}

#documents div.infos strong {
  display: block;
  text-transform: uppercase;
}

#documents a.download {
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 1px solid #f59100;
  border-radius: 50%;
  text-align: center;
  padding: 7px;
}

#documents a.download svg {
  width: 18px;
  height: 18px;
  fill: #f59100;
}

#documents a.download:hover {
  border-color: #4F5D5B;
}

#documents a.download:hover svg {
  fill: #4F5D5B;
}

@media (max-width: 64em) {
  #publications div.header {
    margin-top: 50px;
  }
  #publications div.intro {
    margin-bottom: 50px !important;
  }
  #publications div.intro h2 {
    top: auto;
  }
}
</style>